import { captureException } from "@sentry/react"
import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Typography,
} from "@suraasa/placebo-ui"
import { useInfiniteQuery } from "@tanstack/react-query"
import api from "api"
import queries from "api/resources/orders/queries"
import { OrderItemType, OrderStatus } from "api/resources/orders/types"
import Monitor from "assets/Fallback/monitor.svg"
import TagFallback from "assets/Fallback/tag.svg"
import CertificationPlaceholder from "assets/Placeholder/certificate_placeholder.svg"
import CoursePlaceholder from "assets/Placeholder/course_placeholder.svg"
import clsx from "clsx"
import Fallback from "components/Fallback"
import TalkToMentorBanner from "components/TalkToMentorBanner"
import TopHeadingBar from "components/TopHeadingBar"
import { format } from "date-fns"
import { useEffect } from "react"
import { Link } from "react-router-dom"
import routes from "routes"
import useFormatPrice from "utils/hooks/useFormatPrice"
import usePaginationScroll from "utils/hooks/usePaginationScroll"

import { getOrderItemAction, getStatusTag } from "./utils/helpers"

const OrderItemCard = ({
  itemName,
  itemType,
  image,
  itemsAllocated,
  itemSlug,
  orderStatus,
}: {
  itemName: string
  itemType: OrderItemType
  image: string | null
  itemsAllocated: boolean
  itemSlug: string | null
  orderStatus: OrderStatus
}) => {
  const FallbackImage =
    itemType === OrderItemType.COURSE
      ? CoursePlaceholder
      : CertificationPlaceholder

  return (
    <div className="flex flex-col items-start  sm:flex-row">
      <div className="h-[53px] w-10 overflow-hidden rounded-md">
        <img
          src={image ?? FallbackImage}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = FallbackImage
          }}
          alt="thumbnail"
        />
      </div>
      <div className="sm:ms-1.5">
        {itemType !== OrderItemType.SELLABLE_FEATURE && (
          <Typography variant="preTitle" className="mt-1 text-onSurface-400">
            {itemType.split("_").join(" ").toUpperCase()}
          </Typography>
        )}
        <div className="flex flex-col items-start gap-1 sm:flex-row sm:items-center">
          <Typography variant="strong" className="mt-1.5">
            {itemName}
          </Typography>

          {getOrderItemAction({
            itemsAllocated,
            itemSlug,
            orderStatus,
            itemType,
          })}
        </div>
      </div>
    </div>
  )
}

const Orders = () => {
  const formatPrice = useFormatPrice()

  const {
    isLoading,
    hasNextPage,
    fetchNextPage,
    data,
    isError,
    isSuccess,
    isFetching,
  } = useInfiniteQuery({
    queryKey: queries.list({}).queryKey,
    queryFn: x =>
      api.orders.listOrders({
        params: {
          page: x.pageParam || 1,
        },
      }),

    getNextPageParam: lastPage => {
      return lastPage.nextPage ?? undefined
    },
  })

  const { ref } = usePaginationScroll({
    loading: isLoading,
    hasNextPage: hasNextPage,
    actionFunc: fetchNextPage,
  })

  useEffect(() => {
    if (data) {
      const exception = data.pages.some(orders => {
        orders.data.some(order =>
          order.items.some(product => product.productItems.length > 1)
        )
      })
      if (exception) {
        captureException(new Error("More than 1 Product Items exist"))
      }
    }
  }, [data])

  return (
    <>
      <TopHeadingBar
        heading="Orders and Payments"
        subheading="See your orders and payment history"
      />
      {isError && (
        <Fallback
          data={{
            image: Monitor,
            title: "Something went wrong.",
            description: "Please contact care@suraasa.com",
          }}
          className="my-6 sm:my-20 md:my-8"
          hasError={false}
        />
      )}
      {isLoading && (
        <div className="flex w-full items-center justify-center pt-10">
          <CircularProgress />
        </div>
      )}
      {isSuccess && (
        <Container className="mb-6">
          {data && (
            <>
              {data.pages[0].total > 0 ? (
                data.pages.map(orders =>
                  orders.data.map(order => {
                    const statusTag = getStatusTag(
                      order.status,
                      order.refundStatus
                    )

                    return (
                      <div
                        ref={ref}
                        key={order.id}
                        className="mt-2 rounded-2xl border border-surface-200 bg-surface-500"
                      >
                        <div className="p-2">
                          {order.items.map((item, idx) => (
                            <div
                              key={idx}
                              className={clsx({
                                ["mb-2"]: idx < order.items.length - 1,
                              })}
                            >
                              <OrderItemCard
                                orderStatus={order.status}
                                itemSlug={item.productItems[0].slug}
                                itemsAllocated={order.itemsAllocated}
                                itemName={item.itemName}
                                itemType={item.productItems[0].itemType}
                                image={item.productItems[0].image}
                              />
                            </div>
                          ))}
                        </div>
                        <Divider weight="light" color="onSurface.200" />
                        <div className="flex flex-col p-2 sm:hidden">
                          <div className="flex flex-wrap items-start justify-between">
                            <span>
                              <Typography
                                variant="strongSmallBody"
                                className="mb-0.5 me-1"
                              >
                                #{order.serialNumber}
                              </Typography>
                              <Typography
                                variant="smallBody"
                                color="onSurface.500"
                              >
                                Ordered on{" "}
                                {format(
                                  new Date(order.createdAt),
                                  "dd MMM yyyy"
                                )}
                              </Typography>
                            </span>
                            {statusTag}
                          </div>
                          <Link
                            to={routes.orderDetailsPage.replace(
                              ":id",
                              order.id
                            )}
                          >
                            <Button className="mb-2 mt-3 w-full">
                              View Details
                            </Button>
                          </Link>

                          {order.canRetry && (
                            <Link
                              to={{
                                pathname: routes.retryPayment,
                                search: `?orderPaymentPlanId=${order.paymentPlans[0].id}&itemName=${order.items[0].itemName}&itemId=${order.items[0].id}&amount=${order.finalAmount}&currencySymbol=${order.currency.symbol}`,
                              }}
                            >
                              <Button className="w-full">Retry Payment</Button>
                            </Link>
                          )}
                        </div>
                        <div className="hidden items-center justify-between p-2 sm:flex">
                          <div className="flex flex-col">
                            <span className="flex items-center">
                              <Typography
                                variant="strongSmallBody"
                                className="me-1"
                              >
                                #{order.serialNumber}
                              </Typography>
                            </span>
                            <Typography
                              variant="smallBody"
                              className="mt-0.75 text-onSurface-500"
                            >
                              Ordered on{" "}
                              {format(new Date(order.createdAt), "dd MMM yyyy")}
                            </Typography>
                          </div>
                          <div className="flex items-center">
                            {statusTag}
                            <Typography variant="strong" className="me-2 ms-2">
                              {order.currency.symbol}
                              {formatPrice(order.finalAmount)}
                            </Typography>
                            <div className="flex items-center gap-1">
                              <Link
                                to={routes.orderDetailsPage.replace(
                                  ":id",
                                  order.id
                                )}
                              >
                                <Button size="sm">View Details</Button>
                              </Link>
                              {order.canRetry && (
                                <Link
                                  to={{
                                    pathname: routes.retryPayment,
                                    search: `?orderPaymentPlanId=${order.paymentPlans[0].id}&itemName=${order.items[0].itemName}&itemId=${order.items[0].id}&amount=${order.finalAmount}&currencySymbol=${order.currency.symbol}`,
                                  }}
                                >
                                  <Button size="sm">Retry Payment</Button>
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                )
              ) : (
                <>
                  <Fallback
                    data={{
                      image: TagFallback,
                      title: "Looks like you haven't ordered anything yet!",
                      description:
                        "Head to the store and check out the courses we offer to start your upskilling journey today!",
                    }}
                    className="my-6 sm:my-20 md:my-8"
                    hasError={false}
                  />
                  <TalkToMentorBanner />
                </>
              )}
            </>
          )}
          {isFetching && (
            <div className="flex w-full items-center justify-center pt-10">
              <CircularProgress />
            </div>
          )}
        </Container>
      )}
    </>
  )
}

export default Orders

import { actionAlert, Typography } from "@suraasa/placebo-ui"
import { Button, theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { QuestionStatus } from "api/resources/assessments/types"
import SuraasaLogo from "assets/SuraasaLogo.svg"
import clsx from "clsx"
import FullPageLoading from "components/FullPageLoading"
// import { ASSESSMENT_ROOT_DIV_ID } from "./constants"
import DarkOverlayLoading from "features/ITO/Assessments/Attempt/DarkOverlayLoading"
import DurationTimer from "features/ITO/Assessments/Attempt/DurationTimer"
import InternetConnectionBanner from "features/ITO/Assessments/Attempt/InternetConnectionBanner"
import SubmitConfirmationDialog from "features/ITO/Assessments/Attempt/SubmitConfirmationDialog"
import TimeUpDialog from "features/ITO/Assessments/Attempt/TimeUpDialog"
import { closeWindow } from "features/ITO/Assessments/helpers"
// import { GA } from "shared/utils/googleAnalytics"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import useHideGlobalBottomNavigation from "utils/hooks/useGlobalHideBottomNavigation"
import useHideTopNavigation from "utils/hooks/useHideTopNavigation"

import { getQuestionStatus } from "../helpers"
import Navbar from "../Navbar"
import AssessmentFooter from "./AssessmentFooter"
import styles from "./Attempt.module.css"
import Question from "./Question"
import QuestionPanel from "./QuestionPanel"
import { useAssessment } from "./useAssessment"

const DEFAULT_QUESTION_NUMBER = 1

const AttemptAssessment = () => {
  useHideTopNavigation()
  useHideGlobalBottomNavigation()

  const { assessmentId } = useParams() as { assessmentId: string }

  const {
    endTime,
    questionBank,
    markAnswer,
    submit,
    isSubmitting,
    toggleMarkForReview,
    startingBeforeTime,
    attempt,
  } = useAssessment({
    onInvalidAttempt: reason => {
      setInvalidAttemptReason(reason)
      closeWindow(5000)
    },
    assessmentId,
  })

  const [questionNumber, setQuestionNumber] = useState(DEFAULT_QUESTION_NUMBER)

  const currentQuestion = questionBank.find(
    q => q.questionNumber === questionNumber
  )

  const [invalidAttemptReason, setInvalidAttemptReason] = useState("")

  useEffect(() => {
    const handleUnload = () => {
      if (window.opener) {
        window.opener.postMessage({ assessmentId }, window.location.origin)
      }
    }

    window.addEventListener("unload", handleUnload)
    return () => {
      window.removeEventListener("unload", handleUnload)
    }
  }, [assessmentId])

  const onTimeEnd = async () => {
    const submitted = await submit({ autoSubmitReason: "time_up" })
    if (submitted) setAutoSubmitDialogOpen(true)
  }

  const submitAssessment = async () => {
    const submitted = await submit({ autoSubmitReason: "" })

    if (submitted) {
      await actionAlert.success({
        title: "Submitted Successfully!",
        duration: 5000,
      })
      closeWindow()
    }
  }

  const unattemptedQuestionsCount = questionBank.filter(
    ques => getQuestionStatus(ques) === QuestionStatus.UNATTEMPTED
  ).length
  const markedForReviewQuestionCount = questionBank.filter(
    ques => getQuestionStatus(ques) === QuestionStatus.MARKED_FOR_REVIEW
  ).length

  const [submitDialogOpen, setSubmitDialogOpen] = useState(false)
  const [autoSubmitDialogOpen, setAutoSubmitDialogOpen] = useState(false)

  const isMdDown = useMediaQuery(theme.breakpoints.down("md"))

  // useEffect(() => {
  //   trigger()
  // }, [questionBank, trigger])

  if (startingBeforeTime) {
    if (attempt && attempt.assessmentSchedule?.startTime) {
      return (
        <div className="flex h-screen flex-col items-center justify-center">
          <img src={SuraasaLogo} alt="logo" width={150} height={150} />
          <span
            className="mt-2 flex items-center"
            style={{ ...theme.typography.largeBody }}
          >
            <Typography className="me-0.5" variant="largeBody">
              Assessment will start in
            </Typography>
            <DurationTimer
              onEnd={() => window.location.reload()}
              toDate={attempt.assessmentSchedule.startTime}
            />
          </span>
        </div>
      )
    }
  }

  if (!currentQuestion || !assessmentId || invalidAttemptReason)
    return <FullPageLoading message={invalidAttemptReason} />

  const scrollIntoView = () => {
    try {
      const element = document.querySelector(".scroll-to-hook")
      if (element) {
        element.scrollIntoView({ behavior: "smooth" })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleQuestionChange = (number: number) => {
    scrollIntoView()
    setQuestionNumber(number)
  }

  const onPrevious = () => {
    if (questionNumber === 1) return
    setQuestionNumber(questionNumber - 1)
    scrollIntoView()
  }

  const onNext = () => {
    if (questionNumber === questionBank.length) return
    setQuestionNumber(questionNumber + 1)
    scrollIntoView()
  }

  return (
    <DarkOverlayLoading
      enabled={isSubmitting}
      message={isSubmitting ? "Submitting" : ""}
    >
      <>
        <span
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className="scroll-to-hook"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />

        <InternetConnectionBanner />

        <SubmitConfirmationDialog
          unattemptedQuestionsCount={unattemptedQuestionsCount}
          markedForReviewQuestionCount={markedForReviewQuestionCount}
          // This prop is not applicable in this case
          unreadQuestionsCount={0}
          open={submitDialogOpen}
          handleClose={() => {
            setSubmitDialogOpen(false)
          }}
          onSubmit={() => {
            setSubmitDialogOpen(false)
            submitAssessment()
          }}
        />

        <TimeUpDialog
          open={autoSubmitDialogOpen}
          handleClose={() => {
            window.close()
          }}
        />

        {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
        <div className={clsx(styles.mainBody, "noselect")}>
          <Navbar
            gutterBottom={false}
            slotEnd={
              <div className="grid grid-flow-col items-center gap-2">
                {endTime && (
                  <div className="grid items-end">
                    <div className={clsx(styles.timerContainer)}>
                      <DurationTimer toDate={endTime} onEnd={onTimeEnd} />
                    </div>
                    <Typography
                      variant="smallBody"
                      className="text-onSurface-500"
                    >
                      time left
                    </Typography>
                  </div>
                )}
                <Button size="sm" onClick={() => setSubmitDialogOpen(true)}>
                  Submit
                </Button>
              </div>
            }
          />

          <div className="h-full">
            <div className="grid h-full gap-1">
              <div
                className={`${styles.content} row-span-1 h-full grid-cols-5`}
              >
                <div
                  className={clsx("col-span-5 ", {
                    "lg:col-span-4": true,
                  })}
                >
                  <div className={clsx(styles.test, "grid")}>
                    <Question
                      toggleMarkForReview={toggleMarkForReview}
                      question={currentQuestion}
                      handleMarkAnswer={markAnswer}
                    />
                  </div>
                </div>

                {!isMdDown && (
                  <div className="col-span-1">
                    <QuestionPanel
                      onQuestionChange={handleQuestionChange}
                      questions={questionBank}
                      currentQuestion={currentQuestion}
                    />
                  </div>
                )}
              </div>
              <div
                className={clsx(
                  "grid grid-cols-5 gap-7",
                  styles.footerContainer
                )}
              >
                <div
                  className={clsx("col-span-5", {
                    "lg:col-span-4 lg:pl-3": true,
                  })}
                >
                  <AssessmentFooter
                    onQuestionChange={handleQuestionChange}
                    onNext={onNext}
                    onPrevious={onPrevious}
                    questions={questionBank}
                    currentQuestion={currentQuestion}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </DarkOverlayLoading>
  )
}

export default AttemptAssessment

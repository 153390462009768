import { CircularProgress } from "@suraasa/placebo-ui-legacy"
import ErrorPage from "components/ErrorPage"
import { useEnrollments } from "utils/hooks/useEnrollments"

const url = "https://assets.suraasa.com/lms/Learner's%20Handbook.pdf"

const LearnerHandbook = () => {
  const {
    pgctl: { isPGCTLUser },
  } = useEnrollments()

  if (isPGCTLUser === undefined) {
    return (
      <div className="flex items-center justify-center p-5">
        <CircularProgress />
      </div>
    )
  }

  if (!isPGCTLUser) {
    return <ErrorPage errorStatus={404} />
  }

  return (
    <div className="mx-auto">
      <iframe
        src={`https://docs.google.com/gview?url=${encodeURIComponent(
          url
        )}&embedded=true`}
        className="h-screen w-full"
        title="Suraasa Learner Handbook"
      >
        Please refresh this page
      </iframe>
    </div>
  )
}

export default LearnerHandbook

import { IconButton } from "@suraasa/placebo-ui"
import placeHolderImg from "assets/Placeholder/course_placeholder.svg"
import TruncatedText from "components/TruncatedText"
import { Trash } from "iconoir-react"
import {
  FileIcon,
  FileSpreadsheet,
  FileText,
  ImageIcon,
  VideoIcon,
} from "lucide-react"
import { useMemo } from "react"

interface FilePreviewProps {
  file: File | string // File object or URL string
  name?: string
  width?: number
  height?: number
  handleRemove?: () => void
  handleView?: () => void
}

export default function FilePreview({
  file,
  name,
  width = 150,
  height = 150,
  handleRemove,
  handleView,
}: FilePreviewProps) {
  const previewSrc = useMemo(() => {
    if (file instanceof File) return URL.createObjectURL(file)
    return file
  }, [file])

  const Component = handleView ? "button" : "a"
  const props = handleView
    ? { onClick: handleView }
    : {
        target: "_blank",
        href: previewSrc,
      }

  return (
    <Component
      className="relative overflow-hidden rounded-lg border border-gray-200"
      style={{ width, height }}
      {...props}
    >
      <DocumentPreview
        src={previewSrc}
        alt="File preview"
        width={width}
        height={height}
        name={name || previewSrc}
      />
      {handleRemove && (
        <div className="absolute right-0.5 top-0.5">
          <IconButton
            color="white"
            variant="filled"
            asChild={Boolean(handleView)}
            className="shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]"
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              handleRemove()
            }}
          >
            <Trash className="!size-3.5 cursor-pointer p-0.5 text-red-500" />
          </IconButton>
        </div>
      )}
    </Component>
  )
}

export const FilePreviewList = ({ files }: { files: FilePreviewProps[] }) => {
  return (
    <div className="flex flex-wrap gap-2">
      {files.map((file, idx) => (
        <FilePreview key={idx} {...file} />
      ))}
    </div>
  )
}

function DocumentPreview({
  src,
  alt,
  width,
  height,
  name,
}: {
  src: string | null
  alt: string
  width: number
  height: number
  name?: string
}) {
  const type = getType(name)

  if (src && type.type === "image") {
    return (
      <img
        src={src}
        alt={alt}
        width={width}
        height={height}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null // prevents looping
          currentTarget.src = placeHolderImg
        }}
        className="size-full object-cover"
      />
    )
  }

  return (
    <div className="flex size-full flex-col items-center justify-center bg-gray-100 p-2">
      <type.icon className="mb-1 size-8 text-gray-400" />
      <TruncatedText
        maxLength={30}
        hideActionButton={true}
        className="break-all text-center leading-none [&>p]:text-xs"
      >
        {name}
      </TruncatedText>
    </div>
  )
}

function getType(name: string | undefined) {
  const extension = name?.split(".").pop()?.toLowerCase()
  switch (extension) {
    case "pdf":
    case "doc":
    case "docx":
    case "txt":
      return { type: "file", icon: FileText, extension }
    case "xls":
    case "xlsx":
      return { type: "sheet", icon: FileSpreadsheet, extension }
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return { type: "image", icon: ImageIcon, extension }
    case "mov":
    case "mp4":
    case "avi":
      return { type: "video", icon: VideoIcon, extension }
    default:
      return { type: "unknown", icon: FileIcon, extension }
  }
}

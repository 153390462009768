import { cn } from "@suraasa/placebo-ui"
import React from "react"

type Props = {
  className?: string
}

const SimpleCard: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
}) => {
  return (
    <div
      className={cn(
        "rounded-2xl border-2 border-onSurface-200 bg-white p-2",
        className
      )}
    >
      {children}
    </div>
  )
}

export default SimpleCard

import api from "api"
import {
  AssignmentAttachment,
  SubmissionAttachment,
} from "api/resources/assignments/types"
import FilePreview from "features/Profile/components/Academics/FilePreview"
import { useState } from "react"
import { downloadFile } from "utils/helpers"

const getFileURL = async (id: number) => {
  try {
    return await api.assignments.getAttachment({
      urlParams: {
        attachmentId: id,
      },
    })
  } catch {
    return null
  }
}

export type AttachmentType = AssignmentAttachment | SubmissionAttachment | File

type Props = {
  file: AttachmentType
  onDelete?: (file: AttachmentType) => Promise<void>
}

const Attachment = ({ file, onDelete }: Props) => {
  const name = file.name

  const [loading, setLoading] = useState(false)

  const handleClick = async () => {
    if (loading) return

    if (file instanceof File) {
      window.open(URL.createObjectURL(file), "_blank")
      return
    }

    if ("url" in file) {
      window.open(file.url, "_blank")
      return
    }

    setLoading(true)
    const data = await getFileURL(file.id)
    if (data?.url) {
      downloadFile(name, data.url)
    }
    setLoading(false)
  }

  const previewSrc = (() => {
    if (file instanceof File) {
      return URL.createObjectURL(file)
    }
    if ("url" in file) {
      return file.url
    }

    return ""
  })()

  return (
    <FilePreview
      width={200}
      file={previewSrc}
      name={name}
      handleRemove={onDelete ? () => onDelete(file) : undefined}
      handleView={handleClick}
    />
  )
}

export default Attachment

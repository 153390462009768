import { CircularProgress } from "@suraasa/placebo-ui"
import { useLayoutEffect } from "react"
import { redirectToLogout } from "utils/helpers"

export const Logout = () => {
  useLayoutEffect(() => {
    redirectToLogout()
  }, [])

  return (
    <div className="grid h-screen w-screen place-items-center">
      <CircularProgress size="lg" />
    </div>
  )
}

import { useQuery } from "@tanstack/react-query"
import api from "api"
import ResumeCourseCard from "features/Library/ResumeCourseCard"

const ResumeLearning = () => {
  const { data: lastAccessedItem, isError } = useQuery({
    queryFn: () => api.learningItems.getLastAccessedItem(),
  })

  if (isError || !lastAccessedItem) return null

  return (
    <div className="mb-4">
      <ResumeCourseCard
        item={lastAccessedItem}
        progress={lastAccessedItem.progress}
      />
    </div>
  )
}

export default ResumeLearning

import { Divider } from "@suraasa/placebo-ui"
import { Certification } from "api/resources/profile/types"
import { useContext } from "react"

import ProfileContext from "../../../context"
import CertificationItem from "./CertificationItem"

const Certifications = ({
  onEdit,
}: {
  onEdit: (id: Certification["id"]) => void
}) => {
  const {
    academics: {
      certifications: { data },
    },
    isPublic,
  } = useContext(ProfileContext)

  return (
    <>
      {data.map((certification, index) => (
        <div className="ml-2 pt-2" key={certification.id}>
          <CertificationItem
            certification={certification}
            onEdit={() => onEdit(certification.id)}
            isPublic={isPublic}
          />
          {index < data.length - 1 && (
            <Divider className="bg-onSurface-200" weight="light" />
          )}
        </div>
      ))}
    </>
  )
}

export default Certifications

import {
  Button,
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
  Typography,
} from "@suraasa/placebo-ui"
import AsyncBuilder from "components/AsyncBuilder"
import React, { useEffect, useState } from "react"

type Props = {
  children: React.ReactNode
  title: string
  actionLabel?: string
  onRemove: () => Promise<void>
}

type TriggerProps = {
  /**
   * Must be an element that accepts `ref` and `onClick` props.
   */
  trigger: React.ReactNode
  isOpen?: never
  setIsOpen?: never
}

type ControlledProps = {
  trigger?: never
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}
const RemoveDialog = ({
  children,
  title,
  actionLabel = "Remove",
  onRemove,
  trigger,
  setIsOpen,
  ...props
}: Props & (TriggerProps | ControlledProps)) => {
  const [open, setOpen] = useState(props.isOpen || false)

  useEffect(() => {
    if (props.isOpen !== undefined) {
      setOpen(props.isOpen)
    }
  }, [props.isOpen])

  useEffect(() => {
    if (setIsOpen && open !== props.isOpen) {
      setIsOpen(open)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const handleClose = () => {
    setOpen(false)
    if (setIsOpen) {
      setIsOpen(false)
    }
  }

  const handleRemove = async () => {
    await onRemove()
    handleClose()
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        closeWhenInteractOutside
        className="max-w-[min(95%,24rem)]"
      >
        <DialogBody className="p-2.5">
          <DialogTitle className="mb-1">
            <Typography variant="title3" className="leading-tight">
              {title}
            </Typography>
          </DialogTitle>

          <DialogDescription>
            <>{children}</>
          </DialogDescription>

          <div className="mt-2.5 flex justify-end gap-1">
            <DialogClose>
              <Button
                variant="outlined"
                color="black"
                size="sm"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </DialogClose>
            <AsyncBuilder
              handler={handleRemove}
              render={props => (
                <Button color="critical" size="sm" {...props}>
                  {actionLabel}
                </Button>
              )}
            />
          </div>
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}

export default RemoveDialog

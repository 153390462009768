import { CircularProgress } from "@suraasa/placebo-ui"
import { LearningItem } from "api/resources/learningItems/types"
import Activity from "features/Activity"

import LearningModuleContainer from "../LearningModuleContainer"
import Article from "./Article"
import Assessment from "./Assessment"
import Discussions from "./Discussions"
import RecordedClass from "./RecordedClass"
import Video from "./Video"

export interface CourseItemProps {
  loading: boolean
  item?: LearningItem
}

const CourseItem = ({ loading, item }: CourseItemProps) => {
  if (loading || !item) {
    return (
      <div className="mt-5">
        <LearningModuleContainer>
          <div className="flex size-full items-center justify-center p-12">
            <CircularProgress />
          </div>
        </LearningModuleContainer>
      </div>
    )
  }

  const contentType = item.learningContentType

  return (
    <>
      {/* <DeletedDiscussionSheet open={open} toggle={toggle} /> */}
      {/* <ReplyToDiscussion open={open} toggle={toggle} /> */}
      <LearningModuleContainer
        removePadding={contentType === "activity"}
        disabled={contentType === "assessment"}
      >
        {contentType === "discussion" && <Discussions item={item} />}
        {contentType === "article" && <Article item={item} />}
        {contentType === "assessment" && (
          <Assessment item={item} hasOwnContainer />
        )}
        {contentType === "video" && <Video item={item} />}
        {contentType === "pre_recorded_class" && <RecordedClass item={item} />}
        {contentType === "activity" && <Activity item={item} />}
      </LearningModuleContainer>
    </>
  )
}

export default CourseItem

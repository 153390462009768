import "glider-js/glider.min.css"

import {
  CircularProgress,
  Dialog,
  DialogBody,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Sheet,
  SheetBody,
  SheetContent,
  SheetHeader,
  SheetTitle,
  Typography,
} from "@suraasa/placebo-ui"
import { Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { Review, ReviewRubric } from "api/resources/assignments/types"
import clsx from "clsx"
import TagWithAdornment from "features/AssignmentResult/TagWithAdornment"
import { ArrowDownCircle, ArrowUpCircle } from "iconoir-react"
import { useState } from "react"
import Glider from "react-glider"
import { useTheme } from "react-jss"

import CriteriaWiseScore from "../CriteriaWiseScore"
import styles from "./RubricsPopup.module.css"

type Props = Review & { rubrics: ReviewRubric[] }

const BeforeIqaReview = ({
  iqaReviewExists,
  review,
}: {
  iqaReviewExists: boolean
  review: Props
}) => {
  if (!review) return null

  return (
    <div className="flex h-full flex-col justify-between gap-2">
      <div>
        <div className="mb-2 flex">
          <TagWithAdornment
            label={
              review.marks != null ? `Score: ${review.marks.toFixed(2)}` : ""
            }
          />
        </div>
        <Typography variant="title4" className="mb-1">
          Professor’s Remarks
        </Typography>
        <Typography>{review.comments}</Typography>
      </div>

      {review.rubrics.length > 0 ? (
        <div className="mt-2">
          {review.rubrics.map((rubric, index) => (
            <CriteriaWiseScore {...rubric} key={index} />
          ))}
        </div>
      ) : (
        <div className="mt-4 rounded-xl border border-warning-300 bg-warning-50 p-3">
          <Typography variant="largeBody">
            Rubrics for this submission are not available.
          </Typography>
        </div>
      )}
    </div>
  )
}

const AfterIqaReview = ({
  iqaReview,
  review,
}: {
  iqaReview: Props
  review: Props
}) => {
  if (!iqaReview || !review) return null

  if (!iqaReview.marks || !review.marks) return null

  const getAdornment = () => {
    if (!iqaReview.marks || !review.marks) return undefined

    if (iqaReview.marks > review.marks)
      return (
        <ArrowUpCircle className="rounded-full bg-primary-500 text-success-50" />
      )
    if (iqaReview.marks < review.marks)
      return (
        <ArrowDownCircle className="rounded-full bg-primary-500 text-critical-50" />
      )

    return undefined
  }

  return (
    <div className="flex h-full flex-col justify-between">
      <div>
        <Typography variant="title3" className="mb-2">
          Review after Quality Assurance
        </Typography>
        <div className="mb-2 flex">
          <TagWithAdornment
            label={
              iqaReview.marks != null
                ? `Score: ${iqaReview.marks.toFixed(2)}`
                : ""
            }
            endAdornment={getAdornment()}
          />
        </div>
        <Typography variant="title4" className="mb-1">
          Professor’s Remarks
        </Typography>
        <Typography>{iqaReview.comments}</Typography>
      </div>

      {iqaReview.rubrics.length > 0 ? (
        <div>
          {iqaReview.rubrics.map((rubric, index) => (
            <CriteriaWiseScore {...rubric} key={index} />
          ))}
        </div>
      ) : (
        <div className="mt-4 rounded-xl border border-warning-300 bg-warning-50 p-3">
          <Typography variant="largeBody">
            Rubrics for this submission are not available.
          </Typography>
        </div>
      )}
    </div>
  )
}

const RubricsPopup = ({
  review,
  iqaReview,
  open,
  onClose,
  mode = "sheet",
}: {
  open: boolean
  onClose: () => void
  review: Review
  iqaReview?: Review
  mode?: "sheet" | "dialog"
}) => {
  const [activeView, setActiveView] = useState<"beforeIQA" | "afterIQA">(
    "afterIQA"
  )
  const theme = useTheme<Theme>()
  const md = useMediaQuery(theme.breakpoints.only("md"))
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"))

  const rubrics = useQuery({
    enabled: open && !!review.uuid,
    queryFn: () =>
      api.assignments.getReviewRubrics({
        urlParams: {
          reviewId: review.uuid,
        },
      }),
    queryKey: queries.assignments.reviewRubrics(review.uuid).queryKey,
  })

  const iqaRubrics = useQuery({
    enabled: open && !!iqaReview?.uuid,
    queryFn: () =>
      api.assignments.getIQAReviewRubrics({
        urlParams: {
          reviewId: iqaReview?.uuid || "",
        },
      }),
    queryKey: queries.assignments.reviewRubrics(iqaReview?.uuid || "").queryKey,
  })

  const getContent = () => {
    if (!rubrics.isSuccess)
      return (
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      )

    const iqaReviewExists = !!iqaReview

    return isMdDown ? (
      <>
        <Glider
          onSlideVisible={event => {
            if (event.detail.slide === 0) setActiveView("beforeIQA")
            else setActiveView("afterIQA")
          }}
          id="glider"
          hasArrows
          draggable={iqaReviewExists}
          scrollLock={true}
          duration={0.25}
          slidesToShow={1.1}
          slidesToScroll={1}
          arrows={{
            prev: "#buttonPrev",
            next: "#buttonNext",
          }}
          scrollToSlide={iqaReviewExists ? 2 : 1}
        >
          <div className={clsx({ "me-3": iqaReview })}>
            <BeforeIqaReview
              iqaReviewExists={iqaReviewExists}
              review={{ ...review, rubrics: rubrics.data }}
            />
          </div>
          {iqaReview && (
            <AfterIqaReview
              iqaReview={{ ...iqaReview, rubrics: iqaRubrics.data || [] }}
              review={{ ...review, rubrics: rubrics.data }}
            />
          )}
        </Glider>
        {/* Bottom Toggle */}
        {iqaReview && md && (
          <div
            className={clsx(
              styles.toggle,
              "absolute bottom-6 z-0 w-[360px] rounded-[35px] py-2"
            )}
            style={{ left: "calc(50% - 180px)" }}
          >
            {/* Highlight toggle bar  */}
            <div
              className={clsx(
                styles.highlightBar,
                "absolute flex h-[45px] w-[172px] items-center justify-center rounded-[35px] transition-[left] duration-100 ease-linear",
                {
                  "ms-[1px] left-0.75": activeView === "beforeIQA",
                  [styles.toggleLeftPos]: activeView === "afterIQA",
                }
              )}
              style={{
                bottom: "calc(50% - 22px)",
              }}
            >
              {activeView === "beforeIQA" ? (
                <Typography variant="strong" className={styles.whiteText}>
                  Before IQA
                </Typography>
              ) : (
                <Typography variant="strong" className={styles.whiteText}>
                  After IQA
                </Typography>
              )}
            </div>

            <div className="flex items-center justify-around">
              <button id="buttonPrev">
                <Typography variant="strong">Before IQA</Typography>
              </button>
              <button id="buttonNext">
                <Typography variant="strong">After IQA</Typography>
              </button>
            </div>
          </div>
        )}
      </>
    ) : (
      <div
        className={clsx({
          "grid sm:grid-cols-2 sm:grid-flow-col sm:gap-[60px] sm:p-2":
            iqaReview,
        })}
      >
        <BeforeIqaReview
          iqaReviewExists={iqaReviewExists}
          review={{ ...review, rubrics: rubrics.data }}
        />
        {iqaReview && (
          <AfterIqaReview
            iqaReview={{ ...iqaReview, rubrics: iqaRubrics.data || [] }}
            review={{ ...review, rubrics: rubrics.data }}
          />
        )}
      </div>
    )
  }

  if (mode === "dialog")
    return (
      <Dialog onOpenChange={onClose} open={open}>
        <DialogContent className={clsx({ "max-w-full": Boolean(iqaReview) })}>
          <DialogHeader>
            <DialogTitle>
              <Typography variant="title3">
                {iqaReview ? "Previous Review" : "Rubrics"}
              </Typography>
            </DialogTitle>
          </DialogHeader>
          <DialogBody className="p-3">{getContent()}</DialogBody>
        </DialogContent>
      </Dialog>
    )

  return (
    <Sheet open={open} onOpenChange={onClose}>
      <SheetContent
        side="right"
        height={100}
        className={clsx("w-full !max-w-3xl rounded-tr-none", {
          "!w-full !max-w-full": Boolean(iqaReview),
        })}
      >
        <SheetHeader>
          <SheetTitle>
            <Typography variant="title3">
              {iqaReview ? "Previous Review" : "Rubrics"}
            </Typography>
          </SheetTitle>
        </SheetHeader>
        <SheetBody>{getContent()}</SheetBody>
      </SheetContent>
    </Sheet>
  )
}

export default RubricsPopup

import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTrigger,
  Typography,
} from "@suraasa/placebo-ui"
import { IconButton } from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import Monitor from "assets/Fallback/monitor.svg"
import Fallback from "components/Fallback"
import Submission from "features/AssignmentResult/Submission"
import { useCopyDisable } from "features/ITO/Assessments/Proctoring/hooks/useCopyDisable"
import { InfoCircle, Xmark } from "iconoir-react"
import { getAuthInfo } from "utils/helpers"

import SubmissionPreview from "../../AssignmentResult/Submission/SubmissionPreview"
import VerificationProcess from "../VerificationProcess"

const EvidenceDetails = ({
  firstName,
  isSelf,
  submissionId,
}: {
  firstName: string
  isSelf: boolean
  submissionId: string
}) => {
  useCopyDisable()

  const {
    data: submissionData,
    isLoading,
    isError,
  } = useQuery({
    queryFn: () =>
      api.assignments.retrieveSubmission({
        urlParams: { submissionId: submissionId },
      }),
    queryKey: queries.assignments.submissionsByUUID(submissionId).queryKey,
  })

  const isLoggedIn = !!getAuthInfo()
  const isViewingOwnSubmission = isSelf

  const isSelfSubmission =
    submissionData?.user.uuid === getAuthInfo()?.user.uuid

  if (!isViewingOwnSubmission && isLoggedIn) {
    return (
      <div className="mt-5 flex h-full flex-col">
        <Fallback
          data={{ image: Monitor, title: "Skill Evidence not available" }}
        />
      </div>
    )
  }

  if (isLoading || isError) {
    return (
      <div className="flex h-full flex-col">
        <Fallback
          isLoading={isLoading}
          hasError={isError}
          data={{ image: Monitor, title: "Skill Evidence not available" }}
        />
      </div>
    )
  }

  return (
    <>
      <Typography className="mb-2 text-onSurface-800">
        Following content represents {firstName}’s original work on{" "}
        <strong>{submissionData?.assignment.title}</strong> which has undergone
        multiple levels of quality assurance and plagiarism checks by Suraasa.
      </Typography>

      <Sheet>
        <SheetTrigger asChild>
          <button className="mb-3 flex w-full items-center rounded-xl border border-highlight-100 bg-highlight-50 px-2 py-1.5">
            <InfoCircle
              className="me-2 hidden shrink-0 text-highlight-500 sm:block"
              width={20}
              height={20}
            />
            <Typography
              className="text-end text-highlight-900"
              variant="strongSmallBody"
            >
              Learn how Suraasa verifies every single skill evidence
              (assignment)
            </Typography>
          </button>
        </SheetTrigger>
        <SheetContent
          closeWhenInteractOutside
          side="right"
          className="w-full max-w-full overflow-y-auto sm:max-w-[40%]"
          height={90}
        >
          <SheetClose className="absolute right-1 top-1">
            <IconButton color="black">
              <Xmark />
            </IconButton>
          </SheetClose>

          <VerificationProcess />
        </SheetContent>
      </Sheet>

      <Typography className="mb-2 text-onSurface-800" variant="title3">
        Submission
      </Typography>

      <div className="relative select-none">
        {isSelf && isSelfSubmission ? (
          <Submission
            attachments={submissionData.attachments}
            references={submissionData.references}
            citations={submissionData.citations}
            content={submissionData.submission}
          />
        ) : (
          <SubmissionPreview
            content={`<p style="font-size: 1em; font-weight: bold; margin-bottom: 24px;">Assignment Submission</p>
          <p style="font-size: 1.5em; font-weight: bold;">Submitted By: ${firstName}</p>
          <p style="font-size: 2em; font-weight: bold;">${submissionData?.assignment.title}</p>
          <p style="font-size: 1em; margin-top: 32px;">${submissionData?.assignment.title} refers to...</p>`}
          />
        )}
      </div>
    </>
  )
}

export default EvidenceDetails

import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
  InputLabel,
  Typography,
} from "@suraasa/placebo-ui"
import { Evidence, EvidenceType } from "api/resources/profile/types"
import FileUpload from "components/FileUpload"
// import FileInput from "components/shared/FileInput"
import { Plus } from "iconoir-react"
import React, { useState } from "react"
import { UseArray } from "utils/hooks/useArray"

import { FilePreviewList } from "./FilePreview"
import UploadEvidenceUrlDialog from "./UploadEvidenceUrlDialog"

type Props = {
  limit?: number
  inputLabel?: string
  buttonLabel: string
  newEvidences: UseArray<File | string>
  evidenceFiles: UseArray<Evidence>
  handleEvidenceFilesToBeDeleted: UseArray<Evidence["id"]>["push"]
  maxSize?: number
}

const UploadEvidenceSection = ({
  inputLabel,
  buttonLabel,
  limit,
  newEvidences,
  evidenceFiles,
  handleEvidenceFilesToBeDeleted,
  maxSize = 5,
}: Props) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null)

  const [openEvidenceUrlDialog, toggleEvidenceUrlDialog] = useState(false)

  return (
    <div>
      <UploadEvidenceUrlDialog
        open={openEvidenceUrlDialog}
        toggle={() => toggleEvidenceUrlDialog(false)}
        onAdd={url => newEvidences.push(url)}
      />
      {inputLabel && <InputLabel label={inputLabel} />}
      {/* <FileInput
        accept="image/jpg,image/jpeg,image/png,application/pdf,application/doc,application/docx"
        name="evidence-files"
        ref={fileInputRef}
        onChange={files => newEvidences.push(files[0])}
      /> */}
      {/* <FileUpload inputComponent={() => {}} /> */}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <FileUpload
            maxSize={maxSize}
            onFileUpload={file => newEvidences.push(file)}
            allowedExtensions={[
              ".jpg",
              ".png",
              ".jpeg",
              ".doc",
              ".pdf",
              ".docx",
            ]}
            inputComponent={({ onClick, errors }) => {
              return (
                <div>
                  <Button
                    disabled={Boolean(
                      limit &&
                        [...evidenceFiles.array, ...newEvidences.array]
                          .length >= limit
                    )}
                    onClick={onClick}
                    size="sm"
                    startAdornment={<Plus />}
                    type="button"
                    variant="outlined"
                  >
                    {buttonLabel}
                  </Button>
                  {errors.map((error, i) => (
                    <div className="mb-2 mt-1.5 pl-2" key={i}>
                      <Typography
                        className="text-critical-500"
                        variant="smallBody"
                      >
                        {error}
                      </Typography>
                    </div>
                  ))}
                </div>
              )
            }}
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuGroup>
            <DropdownMenuItem onClick={() => toggleEvidenceUrlDialog(true)}>
              Link URL
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => fileInputRef.current?.click()}>
              Upload Document
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
      {[...evidenceFiles.array, ...newEvidences.array].length === 0 && (
        <ul className="mb-2 mt-1.5 pl-2">
          <Typography className="text-onSurface-500" variant="smallBody">
            <li>Should not exceed 5MB</li>
          </Typography>
          <Typography className="text-onSurface-500" variant="smallBody">
            <li>Accepted Formats - PDF, DOC, DOCX, JPEG, PNG</li>
          </Typography>
        </ul>
      )}
      <div className="mb-2 mt-4 flex flex-wrap items-center gap-3">
        {evidenceFiles.array.length > 0 && (
          <FilePreviewList
            files={evidenceFiles.array.map((file, index) => ({
              name: file.evidenceType === EvidenceType.URL ? "" : file.fileName,
              file:
                file.evidenceType === EvidenceType.URL ? file.url : file.file,
              handleRemove: () => {
                evidenceFiles.remove(index)
                handleEvidenceFilesToBeDeleted(evidenceFiles.array[index].id)
              },
              height: 120,
              width: 160,
            }))}
          />
        )}
        {newEvidences.array.length > 0 && (
          <FilePreviewList
            files={newEvidences.array.map((file, index) => ({
              file: file,
              handleRemove: () => {
                newEvidences.remove(index)
              },
              height: 120,
              width: 160,
            }))}
          />
        )}
      </div>
    </div>
  )
}

export default UploadEvidenceSection

export const getItemTerm = (slug: string) => {
  switch (slug) {
    case "learning-theories-and-teaching-strategies":
      return "Term 1"
    case "child-development-learning-styles-and-learning-taxonomies":
      return "Term 1"
    case "lesson-planning":
      return "Term 2"
    case "management-of-class-dynamics":
      return "Term 2"
    case "using-technology-for-teaching-and-learning":
      return "Term 2"
    case "questioning-skills-and-techniques":
      return "Term 3"
    case "assessment-and-evaluation-techniques":
      return "Term 3"
    case "providing-feedback":
      return "Term 3"
    case "ethics-and-values":
      return "Term 4"
    case "reflective-teaching":
      return "Term 4"
  }

  return ""
}

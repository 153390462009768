import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { OTPResponse } from "api/resources/settings/types"
import { PhoneNumber } from "api/resources/users/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import ResendOTPButton from "components/ResendOTPButton"
import { useEffect, useState } from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-hook"
import { Controller, useForm } from "react-hook-form"
import OTPInput from "react-otp-input"
import { handleErrors } from "utils/helpers"
import { CaptchaType } from "utils/hooks/useGRecaptcha"

const VerifyPhoneNumberDialog = ({
  otpData,
  close,
  open,
  onVerify,
  phoneNumber,
}: {
  otpData: OTPResponse
  close: () => void
  onVerify: (token: string) => void
  onBack: () => void
  phoneNumber: PhoneNumber | null
  open: boolean
}) => {
  const [resendAt, setResendAt] = useState("")

  const { executeGoogleReCaptcha } = useGoogleReCaptcha(
    import.meta.env.VITE_INVISIBLE_RECAPTCHA_KEY,
    {
      enterprise: true,
    }
  )

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<{ otp: string }>({
    defaultValues: {
      otp: "",
    },
  })

  useEffect(() => {
    if (!open) reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const onSubmit = handleSubmit(async ({ otp }) => {
    try {
      const res = await api.settings.verifyOtp({
        data: {
          token: otpData.token,
          otp,
        },
      })
      onVerify(res.token)
      reset()
    } catch (err) {
      if (err instanceof APIError) {
        handleErrors(err, { setter: setError })
      }
    }
  })

  const resendOtp = async () => {
    const captcha = {
      value: await executeGoogleReCaptcha("auth"),
      type: CaptchaType.invisible,
    }

    if (!captcha) return toast.error("Please verify the captcha")

    try {
      const res = await api.settings.sendOTP({
        data: {
          token: otpData.token,
          captcha,
        },
      })
      setResendAt(res.resendAt)
      toast.info("We've sent you a new OTP")
    } catch (e) {
      if (e instanceof APIError) {
        handleErrors(e)
      } else console.error(e)
    }
  }

  return (
    <Dialog open={open} onOpenChange={() => close()}>
      <DialogContent className="max-w-[400px]">
        <form onSubmit={onSubmit}>
          <DialogHeader>
            <DialogTitle>Verify Your Phone Number</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <div className="p-1">
              <Typography className="mb-2">
                OTP sent to{" "}
                <b>
                  {phoneNumber?.code} {phoneNumber?.number}
                </b>
              </Typography>

              <Controller
                control={control}
                name="otp"
                render={({ field }) => (
                  <OTPInput
                    numInputs={6}
                    {...field}
                    containerStyle="justify-between"
                    inputStyle={clsx(
                      "h-[50px] !w-5 !rounded-[4px] border-2 border-onSurface-500 text-title2 !ring-interactive-400 focus-within:!ring-2 focus-within:!ring-offset-1 focus:outline-onSurface-800",
                      {
                        "!border-critical-500": Boolean(errors.otp),
                      }
                    )}
                    inputType="number"
                    renderInput={props => <input {...props} />}
                    shouldAutoFocus
                    onChange={otp => {
                      field.onChange(otp)
                      if (otp.length === 6) {
                        onSubmit()
                      }
                    }}
                  />
                )}
                rules={{
                  required: { value: true, message: "Required" },
                }}
              />
              {Boolean(errors.otp) && (
                <Typography
                  className="mt-1 text-critical-500"
                  variant="smallBody"
                >
                  {errors.otp?.message}
                </Typography>
              )}

              <div className="mt-3 flex flex-wrap items-center">
                <Typography className="me-1 shrink-0">
                  {/* eslint-disable-next-line @cspell/spellchecker */}
                  Didn&apos;t receive OTP?
                </Typography>
                <ResendOTPButton
                  resendAt={resendAt || otpData.resendAt}
                  text="Send Again"
                  onClick={resendOtp}
                />
              </div>
            </div>
          </DialogBody>

          <DialogFooter>
            <Button size="sm" type="submit">
              Verify
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default VerifyPhoneNumberDialog

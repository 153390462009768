import { Typography } from "@suraasa/placebo-ui"
import { AISparkleFilled } from "assets/icons/system/AISparkle"
import clsx from "clsx"
import React from "react"
import { Link } from "react-router-dom"

import styles from "./Card.module.css"

const Card = ({
  title,
  to,
  gradient,
  description,
}: {
  title: string
  to: string
  gradient: string
  description: string
}) => {
  return (
    <Link
      to={to}
      className={clsx(
        styles.parentContainer,
        "group rounded-2xl border border-solid border-surface-300"
      )}
      style={
        {
          "--backgroundColor": gradient,
        } as React.CSSProperties
      }
    >
      <div className="flex h-[190px] w-full flex-col justify-between rounded-2xl p-2">
        <div className="flex items-center justify-between">
          <div>
            <AISparkleFilled fill={gradient} className={styles.svgColor} />
          </div>
        </div>
        <div className="w-[210px] lg:w-[250px]">
          <Typography variant="title3">{title}</Typography>
          <Typography
            variant={"smallBody"}
            className="mt-1 text-onSurface-500 group-hover:text-inherit"
          >
            {description}
          </Typography>
        </div>
      </div>
    </Link>
  )
}

export default Card

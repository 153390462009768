import {
  _BackendDeliveryMode,
  DeliveryMode,
} from "api/resources/learningItems/types"
import { context } from "global/Context/context"
import { useContext } from "react"
import { learningItemSlugs } from "utils/constants"

export const mapBackendDeliveryMode = (
  backendDeliveryMode: _BackendDeliveryMode
) => {
  switch (backendDeliveryMode) {
    case _BackendDeliveryMode.INDEPENDENT:
      return DeliveryMode.STANDARD
    case _BackendDeliveryMode.INSTRUCTOR_LED:
      return DeliveryMode.EXECUTIVE
    case _BackendDeliveryMode.GUIDED_SELF_PACED:
      return DeliveryMode.PROFESSIONAL
    case _BackendDeliveryMode.LIVE_INSTRUCTOR_LED:
      return DeliveryMode.LIVE_INSTRUCTOR_LED
    case _BackendDeliveryMode.SELF_PACED:
      return DeliveryMode.SELF_PACED
  }
}

export const useEnrollments = () => {
  const { enrollments } = useContext(context)

  const pgctl = enrollments?.data?.find(
    enrollment => enrollment.slug === learningItemSlugs.pgctl
  )

  const pgctlDeliveryMode = pgctl?.deliveryMode
    ? mapBackendDeliveryMode(pgctl.deliveryMode)
    : null

  const isNewDeliveryMode = pgctlDeliveryMode
    ? [
        DeliveryMode.STANDARD,
        DeliveryMode.PROFESSIONAL,
        DeliveryMode.EXECUTIVE,
      ].includes(pgctlDeliveryMode)
    : false

  const showTermBreakdownInPGCTL = isNewDeliveryMode

  return {
    enrollments: enrollments.data,
    ...enrollments,
    pgctl: {
      isPGCTLUser: pgctl,
      deliveryMode: pgctlDeliveryMode,
      showTermBreakdown: showTermBreakdownInPGCTL,
      isNewDeliveryMode,
    },
  }
}

import FullPageLoading from "components/FullPageLoading"
import PgCTLEnrollmentAgreement from "features/PgCTLEnrollmentAgreement"
import SignUpOnboarding from "features/SignupOnboarding"
import { context } from "global/Context/context"
import { useFeatureToggle } from "global/FeatureToggleProvider"
import { useContext, useLayoutEffect, useState } from "react"
import { Outlet, useSearchParams } from "react-router-dom"
import {
  BROWSER_STORAGE_KEYS,
  IS_DEV_ENVIRONMENT,
  IS_LOCALHOST,
} from "utils/constants"
import { redirectToLogin } from "utils/helpers"
import { isShadow } from "utils/shadow"
import { UserPreferenceManager } from "utils/userPreferenceManager"

import { processAuthCode } from "./processAuthCode"

const Authentication = () => {
  const { isAuthenticated, setAuthInfo } = useContext(context)
  const featureToggles = useFeatureToggle()

  const [userPreferencesLoading, setUserPreferencesLoading] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const authCode = searchParams.get("code")

  const temp_skipSignupOnboarding = searchParams.get("form_so") === "0"

  useLayoutEffect(() => {
    const getPreferences = async () => {
      setUserPreferencesLoading(true)
      await UserPreferenceManager.init()
      setUserPreferencesLoading(false)
    }

    getPreferences()
  }, [isAuthenticated])

  useLayoutEffect(() => {
    if (authCode) {
      processAuthCode(authCode).then(auth => {
        if (auth) {
          setAuthInfo(auth)
        }
        searchParams.delete("code")
        setSearchParams(searchParams)
      })
      return
    }

    if (!isAuthenticated) {
      redirectToLogin()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, authCode])

  const signupOnboardingCompleted =
    UserPreferenceManager.get(
      BROWSER_STORAGE_KEYS.signupOnboardingCompleted
    ) === "true"
  const forceSignupOnboarding =
    UserPreferenceManager.get(
      BROWSER_STORAGE_KEYS.signupOnboardingPromptMode
    ) !== "optional"

  if (
    !temp_skipSignupOnboarding &&
    featureToggles.platformOnboarding.isEnabled &&
    isAuthenticated &&
    !isShadow &&
    !userPreferencesLoading &&
    forceSignupOnboarding &&
    !signupOnboardingCompleted &&
    !IS_DEV_ENVIRONMENT &&
    !IS_LOCALHOST
  ) {
    return <SignUpOnboarding />
  }

  return (
    <>
      {(!isAuthenticated || userPreferencesLoading) && <FullPageLoading />}

      {isAuthenticated && !userPreferencesLoading && (
        <PgCTLEnrollmentAgreement>
          <Outlet />
        </PgCTLEnrollmentAgreement>
      )}
    </>
  )
}

export default Authentication

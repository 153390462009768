import { Elements } from "@stripe/react-stripe-js"
import type { StripeElementsOptions } from "@stripe/stripe-js"
import { loadStripe } from "@stripe/stripe-js/pure"
import {
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
} from "@suraasa/placebo-ui"
import api from "api"
import { paymentMethod } from "api/resources/payment/types"
import { APIError } from "api/utils"
import LoadingOverlay from "components/LoadingOverlay"
import { context } from "global/Context/context"
import { useContext, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import routes from "routes"
import { handleErrors } from "utils/helpers"

import { openRazorpayCheckoutForm } from "../helpers"
import useScript from "../hooks/useScript"
import Polling from "../Polling"
import StripeCheckoutForm from "../StripeCheckoutForm"

const RetryPaymentPage = () => {
  const { authInfo } = useContext(context)

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const itemId = parseInt(searchParams.get("itemId") || "0")
  const itemName = searchParams.get("itemName")
  const amount = parseFloat(searchParams.get("amount") || "0")
  const currencySymbol = searchParams.get("currencySymbol")

  const orderPaymentPlanId = searchParams.get("orderPaymentPlanId")
  const [clientSecret, setClientSecret] = useState("")

  const [intermediateLoading, setIntermediateLoading] = useState(false)
  const [polling, setPolling] = useState(false)
  const [stripeFormOpen, setStripeFormOpen] = useState(false)
  const [juspayPaymentURL, setJuspayPaymentURL] = useState<string | null>(null)

  const [paymentPlanId, setPaymentPlanId] = useState("")

  const options: StripeElementsOptions = {
    clientSecret,
  }

  const [stripePromise, setStripePromise] = useState<any>()

  useEffect(() => {
    setStripePromise(loadStripe(import.meta.env.VITE_STRIPE_PAYMENT_KEY))
  }, [])

  const rzpScriptStatus = useScript(
    "https://checkout.razorpay.com/v1/checkout.js"
  )

  const successCallback = ({ isFree } = { isFree: false }) => {
    let redirectUrl = routes.orders

    if (isFree) {
      redirectUrl = routes.learning
      navigate({
        pathname: redirectUrl,
        search: window.location.search,
      })
      return
    }
    const searchParams = new URLSearchParams(window.location.search)

    try {
      if (itemId) searchParams.append("item_id", `${itemId}`)
    } catch (e) {
      console.error("> Error while generating redirect url to orders page", e)
    }
    setPolling(true)
  }

  useEffect(() => {
    setTimeout(() => {
      if (orderPaymentPlanId) {
        fetchPaymentMethod(orderPaymentPlanId)
      }
    }, 2500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderPaymentPlanId])

  const fetchPaymentMethod = async (planId: string, fallback = false) => {
    try {
      const res = await api.payment.retryPayment({
        params: {
          callback_url: `${window.location.origin}${routes.orders}`,
          fallback,
        },
        urlParams: {
          orderPaymentPlanId: planId,
        },
      })

      try {
        // If order is free then direct redirect to orders page
        if (res.paymentGatewayOrder?.isFreeOrder) {
          successCallback({ isFree: true })
          return
        }

        setPaymentPlanId(res.orderPaymentPlanId)
        if (res.paymentMethodId === paymentMethod.JUSPAY) {
          const { paymentLink } = res.paymentMethodDetails
          if (paymentLink) {
            setJuspayPaymentURL(paymentLink)
          }
        }

        // Payment gateway specific code
        if (res.paymentMethodId === paymentMethod.RAZORPAY) {
          if (rzpScriptStatus !== "ready") {
            fetchPaymentMethod(planId, true)
            return
          }

          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const { user } = authInfo!

          try {
            openRazorpayCheckoutForm(
              res.paymentMethodDetails.orderId,
              res.paymentMethodDetails.amount,
              res.id,
              {
                fullName: `${user.firstName} ${user.lastName}`,
                email: user.email,
              },
              () => {
                successCallback()
              }
            )
          } catch (e) {
            console.error(e)
            console.warn("Using fallback payment system")
            fetchPaymentMethod(planId, true)
            return
          }
        }

        if (res.paymentMethodId === paymentMethod.RAZORPAY_PAYMENT_LINK) {
          const { paymentLinkUrl } = res.paymentMethodDetails

          if (paymentLinkUrl) {
            window.location.href = paymentLinkUrl
          }
        }

        if (
          res.paymentMethodId === paymentMethod.STRIPE &&
          res.paymentMethodDetails.clientSecret
        ) {
          sessionStorage.setItem(
            "stripeClientSecret",
            res.paymentMethodDetails.clientSecret
          )
          setClientSecret(res.paymentMethodDetails.clientSecret)
          setStripeFormOpen(true)
        }
        setIntermediateLoading(false)
      } catch (e) {
        console.error(e)
        if (e instanceof APIError) handleErrors(e)
      }
    } catch (e) {
      console.error(e)
      if (e instanceof APIError) {
        handleErrors(e)
      }
    }
  }

  if (!itemId || !itemName || !amount || !currencySymbol) {
    return (
      <p className="grid h-screen place-items-center">
        Unable to process your payment please try again.
      </p>
    )
  }

  if (juspayPaymentURL) {
    return (
      <Dialog open={Boolean(juspayPaymentURL)}>
        <DialogContent className="flex !max-h-full !max-w-full items-center justify-center">
          <iframe
            allow="payment *;"
            className="h-screen w-full"
            src={juspayPaymentURL}
            title="Juspay"
          />
        </DialogContent>
      </Dialog>
    )
  }

  if (polling) {
    return <Polling paymentPlanId={paymentPlanId} itemId={itemId} />
  }

  return (
    <>
      {intermediateLoading && <LoadingOverlay />}
      <Container>
        <>
          {!intermediateLoading && (
            <div className="grid h-screen place-items-center">
              <CircularProgress />
            </div>
          )}
          {clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <StripeCheckoutForm
                itemId={itemId}
                itemName={itemName}
                amount={amount}
                currency={{ symbol: currencySymbol }}
                open={stripeFormOpen}
                handleClose={() => setStripeFormOpen(false)}
              />
            </Elements>
          )}
        </>
      </Container>
    </>
  )
}

export default RetryPaymentPage

import {
  Sheet,
  SheetBody,
  SheetContent,
  SheetHeader,
  Typography,
} from "@suraasa/placebo-ui"
import { Button, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { AssignmentList } from "api/resources/assignments/types"
import HandHoldingPen from "assets/Fallback/hand-holding-pen.svg"
import clsx from "clsx"
import Fallback from "components/Fallback"
import DOMPurify from "dompurify"
import { buildAssignmentTitle } from "features/Assignments/utils"
import { useTheme } from "react-jss"
import { Link, useParams } from "react-router-dom"
import routes from "routes"
import { pluralize } from "utils/helpers"

import { hasNotStartedAnyAssignmentYet } from "./utils"

interface Props {
  assignmentCount: number
  onClick: () => void
}

export const ViewAllSubtasksCard = ({ assignmentCount, onClick }: Props) => {
  return (
    <div className="mb-1 flex h-full flex-wrap items-start justify-between rounded-2xl border border-solid border-interactive-200 bg-interactive-50 p-2 sm:flex-nowrap sm:items-center">
      <div className="flex flex-col">
        <Typography className="mb-1 sm:mb-0.5" variant="strong">
          This task has {pluralize("subtask", assignmentCount)}.
        </Typography>
        <Typography variant="smallBody" className="text-onSurface-800">
          Make sure to read all the subtasks before you start with the
          assignment.
        </Typography>
      </div>

      <Button
        className="mt-1 sm:ml-2 sm:mt-0"
        onClick={onClick}
        label="View All Subtasks"
        variant="text"
      />
    </div>
  )
}

export const ViewAllSubtasksSheet = ({
  open,
  handleClose,
  courseId,
  groupName,
  selectedAssignmentId,
  assignments,
}: {
  open: boolean
  handleClose: () => void
  courseId: number
  groupName: string
  selectedAssignmentId?: number | null
  assignments?: AssignmentList[] | null
}) => {
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  const params = {
    fields: "instructions",
    group_name: groupName,
  }
  const { data, isLoading, isError } = useQuery({
    queryKey: queries.assignments.list("parts", courseId, params).queryKey,
    queryFn: () =>
      api.assignments.list({
        urlParams: {
          learningItemType: "course",
          learningItemId: courseId,
        },
        params: params,
      }),
    select: data =>
      data.sort((a, b) => a.shortTitle.localeCompare(b.shortTitle)),
  })

  const buttonLabel = (() => {
    if (data) {
      const selectedAssignment = data.find(
        assignment => assignment.id === selectedAssignmentId
      )
      return selectedAssignment?.shortTitle ?? "Assignment"
    }
    return "Assignment"
  })()

  const safeHtml = (instructions?: string | null) => {
    return DOMPurify.sanitize(instructions ?? "")
  }

  return (
    <Sheet onOpenChange={handleClose} open={open}>
      <SheetContent
        closeWhenInteractOutside
        height={95}
        side={isXs ? "bottom" : "right"}
        className="min-w-[min(700px,100vw)]"
      >
        <SheetHeader></SheetHeader>
        <SheetBody>
          {isError || isLoading ? (
            <Fallback
              data={{
                image: HandHoldingPen,
                title: "Something went wrong!",
                description: "Please contact care@suraasa.com",
              }}
              className="my-9 sm:my-20 md:my-18"
              hasError={isError}
              isLoading={isLoading}
            />
          ) : (
            <div>
              <Typography variant="title2" className="mb-2 text-onSurface-800">
                {groupName}
              </Typography>
              {data.map((item, index) => (
                <div
                  key={index}
                  className={clsx(
                    "mb-1 flex flex-col rounded-2xl border border-solid p-2",
                    selectedAssignmentId === item.id
                      ? "border-interactive-200 bg-interactive-50"
                      : "border-surface-200"
                  )}
                >
                  <Typography
                    variant="strong"
                    className="mb-1 text-onSurface-800"
                  >
                    {buildAssignmentTitle({
                      title: item.title,
                      shortTitle: item.shortTitle,
                    })}
                  </Typography>
                  <Typography
                    variant="smallBody"
                    className="text-onSurface-800"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: safeHtml(item.instructions),
                      }}
                    />
                  </Typography>
                </div>
              ))}
              <AssignmentSheetActionButton
                assignments={assignments}
                selectedAssignmentId={selectedAssignmentId}
                buttonLabel={buttonLabel}
              />
            </div>
          )}
        </SheetBody>
      </SheetContent>
    </Sheet>
  )
}

const AssignmentSheetActionButton = ({
  assignments,
  selectedAssignmentId,
  buttonLabel,
}: {
  assignments: AssignmentList[] | null | undefined
  selectedAssignmentId: number | null | undefined
  buttonLabel: string
}) => {
  const { slug, parentSlug } = useParams() as {
    slug: string
    parentSlug: string
  }
  if (!assignments || !selectedAssignmentId) {
    return null
  }

  const path = routes.assignment
    .replace(":slug", slug)
    .replace(":learningItemType", "course")
    .replace(":assignmentId", selectedAssignmentId.toString())

  const url = new URL(path, window.location.origin)

  if (hasNotStartedAnyAssignmentYet(assignments)) {
    return (
      <Button
        className="mt-1"
        component={Link}
        to={url.pathname + url.search}
        state={{ slug, parentSlug }}
        label={`Start ${buttonLabel}`}
        variant="filled"
        fullWidth
      />
    )
  }

  return null
}

import { Typography } from "@suraasa/placebo-ui"
import { CircularProgress, Container } from "@suraasa/placebo-ui"

type Props = {
  logo?: string
  message?: string
}

export default function FullPageLoading({ message = "" }: Props) {
  return (
    <Container className="flex h-screen flex-col justify-center">
      <div className="flex justify-center">
        <div className="text-center">
          <CircularProgress size="lg" />
          {message && (
            <Typography variant="largeBody" className="mt-1 text-center">
              {message}
            </Typography>
          )}
        </div>
      </div>
    </Container>
  )
}

import { useSearchParams } from "react-router-dom"
import useHideGlobalBottomNavigation from "utils/hooks/useGlobalHideBottomNavigation"
import useHideTopNavigation from "utils/hooks/useHideTopNavigation"

const EmbeddedGoogleForm = () => {
  useHideGlobalBottomNavigation()
  useHideTopNavigation()

  const [searchParams] = useSearchParams()

  const url = searchParams.get("url")

  if (!url?.startsWith("https://docs.google.com/forms")) return null

  return (
    <div className="relative">
      <div className="absolute left-0 top-1 h-6 w-full">
        <img
          alt="suraasa logo"
          src="https://assets.suraasa.com/logos/v2/full-dark.webp"
          className="mx-auto mt-0"
          width={120}
        />
      </div>
      <iframe
        id="embedded-google-form"
        title="form"
        src={url}
        width="100%"
        className="min-h-screen pt-6"
      >
        Loading…
      </iframe>
    </div>
  )
}

export default EmbeddedGoogleForm

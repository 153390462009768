import { Typography } from "@suraasa/placebo-ui"
import { Container, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import LinkReportsBanner from "features/ITO/LinkReportsBanner"
import { useOlympiadPrices } from "features/ITO/utils/hooks/useOlympiadPrices"
import { context } from "global/Context/context"
import { useContext } from "react"
import { useTheme } from "react-jss"

import ContactUs from "./components/ContactUs"
import FAQs from "./components/FAQs"
import { itoFAQs } from "./components/FAQs/data"
import ReferTeachersCard from "./components/ReferTeachersCard"
import Resources from "./components/Resources"
import ShowcaseDedication from "./components/ShowcaseDedication"
import StayConnected from "./components/StayConnected"
import Testimonials from "./components/Testimonials"
import Timeline from "./components/Timeline"

const spacing = "mb-4 sm:mb-8"

const BeforeRegistrationPhase = () => {
  const theme = useTheme<Theme>()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const { itoParticipationDetails } = useContext(context)
  const { getPrice } = useOlympiadPrices({ edition: "2023" })

  const referralCode =
    itoParticipationDetails.itoRegistrationDetails?.referralCode

  const { data: timeLineData } = useQuery({
    queryKey: queries.itoRegistrations.timeLineDate().queryKey,
    queryFn: () =>
      api.ito.registrations.getDates({
        params: { tag: "dashboard" },
      }),
  })

  return (
    <div>
      <LinkReportsBanner />
      {referralCode && (
        <div className="bg-surface-100 py-3 sm:py-5">
          <Container>
            <div className="grid grid-cols-2 gap-3 sm:gap-5">
              <div className="col-span-2 flex flex-col items-center justify-center md:col-span-1">
                <Typography variant="title2">
                  Maximise Your Growth from ITO 2023
                </Typography>
                <Typography variant="subtitle2" className="text-onSurface-500">
                  Message from Rishabh Khanna, Founder of Suraasa (Organiser of
                  Olympiad)
                </Typography>
              </div>
              <div className="col-span-2 md:col-span-1">
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                  src="https://assets.suraasa.com/ito/2023/video/grow-with-ito.mp4"
                  controls
                  controlsList="nodownload"
                  poster="https://assets.suraasa.com/ito/2023/video/thumbnails/grow-with-ito.webp"
                  className="rounded-xl"
                />
              </div>
            </div>
          </Container>
        </div>
      )}
      <div className="mt-4">
        {referralCode && (
          <Container fullWidth={isXsDown} className={spacing}>
            <ReferTeachersCard referralCode={referralCode} />
          </Container>
        )}

        {Boolean(timeLineData?.length) && (
          <Container className={spacing}>
            <Timeline data={timeLineData || []} />
          </Container>
        )}

        {referralCode && (
          <Container fullWidth={isXsDown} className={spacing}>
            <ShowcaseDedication referralCode={referralCode} />
          </Container>
        )}

        <Container fullWidth={isXsDown} className={spacing}>
          <StayConnected />
        </Container>

        <Container className={spacing}>
          <Resources />
        </Container>

        <Container className={spacing}>
          <Testimonials />
        </Container>

        <Container className={spacing}>
          <FAQs data={itoFAQs(getPrice)} />
        </Container>

        <ContactUs />
      </div>
    </div>
  )
}

export default BeforeRegistrationPhase

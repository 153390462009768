import {
  Button,
  Checkbox,
  CircularProgress,
  DialogFooter,
  Divider,
  Sheet,
  SheetBody,
  SheetContent,
  SheetHeader,
  SheetTitle,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import FullPageLoading from "components/FullPageLoading"
import { context } from "global/Context/context"
import { PropsWithChildren, useContext, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import {
  BROWSER_STORAGE_KEYS,
  IS_DEV_ENVIRONMENT,
  IS_LOCALHOST,
} from "utils/constants"
import { buildUserName } from "utils/helpers"
import { useEnrollments } from "utils/hooks/useEnrollments"
import { isShadow } from "utils/shadow"
import { trackingService } from "utils/tracking"
import { UserPreferenceManager } from "utils/userPreferenceManager"

import { getAgreements, getPgCTLProgramName } from "./utils"

const tempDismissKey = "temp_pgctlEnrollmentAgreementDismissed"

type Form = {
  learnerAgreement: boolean
  termsOfUse: boolean
  privacyPolicy: boolean
}

const PgCTLEnrollmentAgreement = (props: PropsWithChildren) => {
  const hasDismissedTemporarily =
    sessionStorage.getItem(tempDismissKey) === "true"
  const { profile } = useContext(context)

  const pgctlEnrollmentAgreementAccepted =
    UserPreferenceManager.get(
      BROWSER_STORAGE_KEYS.pgctlEnrollmentAgreementAccepted
    ) === "true"

  const form = useForm<Form>()

  const [open, setOpen] = useState(false)

  const {
    isLoading,
    pgctl: { deliveryMode, isNewDeliveryMode },
  } = useEnrollments()

  const programName = deliveryMode
    ? getPgCTLProgramName(deliveryMode)
    : { shortName: "", modeDisplay: "" }

  const userName = buildUserName(profile.user)

  useEffect(() => {
    if (hasDismissedTemporarily || pgctlEnrollmentAgreementAccepted) return

    if (isNewDeliveryMode) setOpen(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryMode, pgctlEnrollmentAgreementAccepted])

  const handleClose = () => {
    setOpen(false)
    sessionStorage.setItem(tempDismissKey, "true")
  }

  const onSubmit = form.handleSubmit(values => {
    if (
      !values.learnerAgreement ||
      !values.termsOfUse ||
      !values.privacyPolicy
    ) {
      toast.error("Please agree to all agreements")
      return
    }

    toast.success(
      `You've agreed to the ${programName.modeDisplay} Enrollment Agreement`
    )
    setOpen(false)

    trackingService.trackEvent("ENROLLMENT_AGREEMENT_COMPLETED", {
      productName: programName.modeDisplay,
    })
    UserPreferenceManager.set(
      BROWSER_STORAGE_KEYS.pgctlEnrollmentAgreementAccepted,
      "true"
    )
  })

  if (isLoading) return <FullPageLoading />

  if (!open) return props.children

  const allowDismiss = isShadow || IS_LOCALHOST || IS_DEV_ENVIRONMENT

  const agreements = deliveryMode ? getAgreements(deliveryMode) : []

  return (
    <Sheet open={open} onOpenChange={allowDismiss ? handleClose : undefined}>
      <SheetContent height={95} className="p-2">
        {allowDismiss && (
          <SheetHeader className="pb-0">
            <SheetTitle />
          </SheetHeader>
        )}
        <SheetBody>
          <img
            className="mb-2"
            src="https://assets.suraasa.com/logos/v2/full-dark.webp"
            alt="logo"
            width={150}
          />
          <Typography variant="title1" className="mb-2">
            Enrollment Agreement
          </Typography>

          <Typography className="mb-3">
            I, {userName}, hereby acknowledge my enrollment in Milestone 1 of
            the Professional Graduate Certificate in Teaching & Learning (PgCTL)
            program. I confirm that I have reviewed and understood the program
            details, including its milestones.
            <br />
            <strong className="mt-1 block">Milestone 1 (Enrolled)</strong>
            {programName.modeDisplay} learning pathway.
            <br />
            <strong className="mt-1 block">Milestone 2 (Not Enrolled)</strong>
            Communication and language program.
            <br />
            <strong className="mt-1 block">
              Milestone 3<sup className="text-sm">*</sup> (Not Enrolled)
            </strong>
            Placement program.
          </Typography>

          <div className="mb-3">
            <Typography className="mb-0.5" variant="strong">
              <sup className="text-sm">*</sup>Eligibility Requirements for
              Milestone 3:
            </Typography>

            <ul className="ml-3 list-disc text-muted">
              <li>
                A learner must achieve at least 80% or 8.0 GPA in Milestone 1
                and should have completed Milestone 2.
              </li>
              <li>
                A learner must have a minimum of Bachelors in Education or an
                equivalent teaching qualification, a subject qualification and a
                standardized English language proficiency certificate (IELTS
                6.5, TOEFL 79-93, PTE 58.5).
              </li>
              <li>
                If the learner wishes to apply for Milestone 3 specifically for
                jobs abroad, the language proficiency certificate score should
                be IELTS 7, TOEFL 94-101, PTE 66.3.
              </li>
            </ul>
          </div>

          <Divider className="mb-3" />

          <div className="flex flex-col gap-2">
            {agreements.map(agreement => (
              <Controller
                key={agreement.name}
                control={form.control}
                name={agreement.name}
                rules={{
                  required: "Required",
                }}
                render={({ field, fieldState: { error } }) => (
                  <Checkbox
                    checked={field.value}
                    ref={field.ref}
                    onCheckedChange={field.onChange}
                    containerClass="flex gap-1.5"
                    className="mt-0.5"
                    label={
                      <div>
                        <Typography
                          variant="title3"
                          className={clsx({
                            "text-critical-500": error?.message,
                          })}
                        >
                          {agreement.label}
                        </Typography>
                        <Typography className="text-muted">
                          I have read and agree to{" "}
                          <a
                            href={agreement.url}
                            target="_blank"
                            className="text-interactive-500 hover:underline"
                            rel="noreferrer"
                          >
                            {agreement.label}
                          </a>
                        </Typography>

                        {error?.message && (
                          <Typography
                            className="text-critical-500"
                            variant="smallBody"
                          >
                            {error.message}
                          </Typography>
                        )}
                      </div>
                    }
                  />
                )}
              />
            ))}
          </div>
        </SheetBody>

        <DialogFooter className="border-none">
          <Button className="w-full" onClick={onSubmit}>
            I Agree
          </Button>
        </DialogFooter>
      </SheetContent>
    </Sheet>
  )
}

export default PgCTLEnrollmentAgreement

import { useParams } from "react-router-dom"

import { useQualificationDetails } from ".."
import Modules from "./Modules"
import NextUp from "./NextUp"

const QualificationOverview = () => {
  const { slug } = useParams() as { slug: string }
  const { qualification } = useQualificationDetails()

  return (
    <div className="flex flex-col gap-3">
      <NextUp
        learningItemId={qualification.id}
        learningItemType={qualification.type}
      />
      <Modules slug={slug} />
    </div>
  )
}

export default QualificationOverview

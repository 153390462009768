import { Typography } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import Fallback from "components/Fallback"
import { useCourseDetails } from "features/LearningItems/Course"
import React, { useState } from "react"

import ClassDetailsCard from "./LiveClassCard"
import VideoPlayerDialog from "./VideoPlayerDialog"

type Props = {
  heading?: React.ReactNode
  hideIfNoData?: boolean
}

const Classes = (props: Props) => {
  const [recordingURL, setRecordingURL] = useState<string | null>(null)

  const { course } = useCourseDetails()

  const classes = useQuery({
    enabled: !!course.id,
    queryKey: queries.learningItems.listClasses(course.type, course.id)
      .queryKey,
    queryFn: () =>
      api.learningItems.classes.list({
        urlParams: {
          learningItemType: course.type,
          learningItemId: course.id,
        },
      }),
  })
  const onViewRecording = (recordingUrl: string) => {
    setRecordingURL(recordingUrl)
  }
  const onClose = () => {
    setRecordingURL(null)
  }

  if (props.hideIfNoData && classes.data && classes.data.length === 0)
    return null

  return (
    <div className="relative">
      {props.heading || (
        <Typography variant="title3" className="mb-2">
          Classes
        </Typography>
      )}

      <VideoPlayerDialog recordingURL={recordingURL} onClose={onClose} />

      {(classes.isLoading || classes.isError) && (
        <Fallback isLoading={classes.isLoading} hasError={classes.isError} />
      )}

      {classes.isSuccess &&
        (classes.data.length > 0 ? (
          classes.data.map((item, index) => (
            <React.Fragment key={item.id}>
              <ClassDetailsCard data={item} onViewRecording={onViewRecording} />
              {index !== classes.data.length - 1 && <div className="h-2" />}
            </React.Fragment>
          ))
        ) : (
          <Fallback
            className="mt-5"
            hasError={false}
            data={{ title: "No classes to show" }}
          />
        ))}
    </div>
  )
}

export default Classes

import { DeliveryMode } from "api/resources/learningItems/types"

const getAgreementURL = (deliveryMode: DeliveryMode) => {
  switch (deliveryMode) {
    case DeliveryMode.STANDARD:
      return "https://www.suraasa.com/enrollment-agreement/pgctl-starter"
    case DeliveryMode.PROFESSIONAL:
      return "https://www.suraasa.com/enrollment-agreement/pgctl-professional"
    case DeliveryMode.EXECUTIVE:
      return "https://www.suraasa.com/enrollment-agreement/pgctl-executive"
  }

  throw new Error("Invalid program mode")
}

export const getAgreements = (deliveryMode: DeliveryMode) =>
  [
    {
      label: "Learner's Agreement",
      name: "learnerAgreement",
      url: getAgreementURL(deliveryMode),
    },
    {
      label: "Terms of Use",
      name: "termsOfUse",
      url: "https://www.suraasa.com/terms-of-use",
    },
    {
      label: "Privacy Policy",
      name: "privacyPolicy",
      url: "https://www.suraasa.com/privacy-policy",
    },
  ] as const

export const getPgCTLProgramName = (deliveryMode: DeliveryMode) => {
  switch (deliveryMode) {
    case DeliveryMode.EXECUTIVE:
      return { shortName: "PgCTL Executive", modeDisplay: "Executive" }
    case DeliveryMode.PROFESSIONAL:
      return { shortName: "PgCTL Professional", modeDisplay: "Professional" }
    case DeliveryMode.STANDARD:
      return { shortName: "PgCTL Starter", modeDisplay: "Starter" }
    case DeliveryMode.LIVE_INSTRUCTOR_LED:
      return {
        shortName: "PgCTL",
        modeDisplay: "Live Instructor Led",
      }
    case DeliveryMode.SELF_PACED:
      return { shortName: "PgCTL", modeDisplay: "Self Paced" }
  }
}

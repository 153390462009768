import {
  Sheet,
  SheetBody,
  SheetContent,
  Tag,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import { Button, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { useMutation, useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { Attempt, AttemptStatus } from "api/resources/assessments/types"
import Monitor from "assets/Fallback/monitor.svg"
import clsx from "clsx"
import Fallback from "components/Fallback"
import SimpleCard from "components/SimpleCard"
import { format } from "date-fns"
import CheckRestrictions from "features/AccessManagement/CheckRestrictions"
import {
  reloadOnAssessmentClose,
  startAssessment,
} from "features/Assessments/helpers"
import AttemptCard from "features/LearningItems/Qualification/Assessments/AttemptCard"
import { getTagStyle } from "features/LearningItems/Qualification/Assessments/helpers"
import ReattemptCount from "features/ReattemptCount"
import { Lock, Xmark } from "iconoir-react"
import { useState } from "react"
import { useTheme } from "react-jss"
import { useEnrollments } from "utils/hooks/useEnrollments"

import { useCourseDetails } from ".."

type Props = {
  hideIfNoData?: boolean
}

// Only show assessment time if assessment is due in next 7 days
const isAssessmentDueInNext7Days = (endTime: string) => {
  const sevenDays = 7 * 24 * 60 * 60 * 1000
  const currentTime = new Date().getTime()
  const endTimeInMs = new Date(endTime).getTime()

  return endTimeInMs - currentTime <= sevenDays
}

const CourseAssessments = (props: Props) => {
  const [selectedAssessmentId, setSelectedAssessmentId] = useState<
    null | string
  >(null)
  const [openAttemptsSheet, setOpenAttemptsSheet] = useState(false)
  const [attemptsData, setAttemptsData] = useState<Attempt[]>([])

  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  const { course } = useCourseDetails()
  const {
    pgctl: { isNewDeliveryMode },
  } = useEnrollments()

  const schedules = useQuery({
    enabled: Boolean(course.id),
    queryFn: () =>
      api.assessments.listAssessmentsSchedules({
        urlParams: {
          itemId: course.id,
          itemType: "course",
        },
      }),
    queryKey: queries.assessments.assessmentList(course.id).queryKey,
  })

  const listAttempts = useMutation({
    mutationFn: (assessmentId: string) =>
      api.assessments.listAttempts({
        urlParams: { assessmentId },
      }),
    onSuccess: data => {
      handleAction(data)
    },
    onError: () => {
      toast.error("Unable to view assessment.")
      setSelectedAssessmentId(null)
    },
  })

  /**
   * if user has already passed or failed the test then it will show the result
   * else the user will be navigated to the instructions page of assessment
   */
  const handleAction = (attemptsData: Attempt[]) => {
    setAttemptsData(attemptsData)
    /**
     * if attempt is Not started & attempt is not missed or attempt is in progress
     * then only we start assessment otherwise we open the sidebar
     */
    if (attemptsData.length === 1) {
      if (
        (attemptsData[0].status === AttemptStatus.NOT_STARTED &&
          attemptsData[0].assessmentSchedule?.endTime &&
          new Date(attemptsData[0].assessmentSchedule?.endTime) > new Date()) ||
        attemptsData[0].status === AttemptStatus.IN_PROGRESS
      ) {
        startAssessment({
          assessmentId: attemptsData[0].assessment.uuid,
          onClose: reloadOnAssessmentClose,
        })
        return
      }
    }
    setOpenAttemptsSheet(true)
  }

  if (props.hideIfNoData && (!schedules || schedules.data?.length === 0))
    return null

  if (schedules.isLoading || schedules.isError) {
    return (
      <div>
        <Typography variant="title3" className="mb-2">
          Assessments
        </Typography>
        <Fallback
          data={{
            title: "Something went wrong",
            description: "Please contact care@suraasa.com",
            image: Monitor,
          }}
          className="pt-4"
          hasError={schedules.isError}
          isLoading={schedules.isLoading}
        />
      </div>
    )
  }

  const currentAssessment = schedules.data.find(
    schedule => schedule.uuid === selectedAssessmentId
  )

  const hasLimitedReAttempts = schedules.data.find(
    schedule => schedule.reattemptType === "on_request"
  )

  return (
    <>
      <div>
        <Typography variant="title3" className="mb-1">
          Assessments
        </Typography>

        <Fallback
          isEmpty={schedules.isSuccess && schedules.data.length === 0}
          emptyMessage="No assessments available"
          hasError={schedules.isError}
          isLoading={schedules.isLoading}
        />

        {!isNewDeliveryMode && (
          <ReattemptCount
            userReattemptCounter={hasLimitedReAttempts?.userReattemptCounter}
            className="mb-1"
          />
        )}

        <div className="flex flex-col gap-1">
          {schedules.data.map(schedule => {
            const isLocked =
              schedule.assessmentSchedule === null ||
              schedule.status === "locked"

            const tagStyle = getTagStyle({
              status: schedule.assessmentSchedule?.attempt?.status,
              endTime: schedule.assessmentSchedule?.endTime,
              result: schedule.assessmentSchedule?.attempt?.result,
            })
            const assessmentId = schedule.uuid

            return (
              <SimpleCard
                key={schedule.id}
                className="flex flex-wrap items-center justify-between gap-1.5 border"
              >
                <div>
                  <div className="flex flex-wrap items-center gap-1 sm:-mt-0.5">
                    <Typography variant="strong">{schedule.title}</Typography>
                    {tagStyle && (
                      <Tag
                        size="sm"
                        className="shrink-0 border-2"
                        color={tagStyle?.color}
                      >
                        {tagStyle?.label}
                      </Tag>
                    )}
                  </div>
                  <Typography variant="strongSmallBody" className="text-muted">
                    {schedule.learningItem.name}
                  </Typography>
                  {schedule.assessmentSchedule?.startTime &&
                  schedule.assessmentSchedule?.endTime &&
                  isAssessmentDueInNext7Days(
                    schedule.assessmentSchedule.endTime
                  ) ? (
                    <Typography variant="smallBody" className="mt-1 text-muted">
                      {[
                        schedule.assessmentSchedule.startTime,
                        schedule.assessmentSchedule.endTime,
                      ]
                        .map(date => format(new Date(date), "dd LLL yyyy, p"))
                        .join(" - ")}
                    </Typography>
                  ) : null}
                </div>

                <CheckRestrictions
                  accessStatus={schedule.accessStatus}
                  render={({ isRestricted }) => {
                    if (isRestricted || isLocked) return <Lock />
                    return (
                      <span className={clsx({ "self-end": isXs })}>
                        <Button
                          variant="text"
                          label="View"
                          loading={
                            listAttempts.isLoading &&
                            selectedAssessmentId === assessmentId
                          }
                          onClick={() => {
                            setSelectedAssessmentId(assessmentId)
                            listAttempts.mutate(assessmentId)
                          }}
                        />
                      </span>
                    )
                  }}
                />
              </SimpleCard>
            )
          })}
        </div>

        <Sheet
          open={openAttemptsSheet}
          onOpenChange={() => setOpenAttemptsSheet(false)}
        >
          <SheetContent side="right" height={100}>
            <SheetBody>
              <Button
                variant="text"
                color="black"
                startAdornment={<Xmark />}
                onClick={() => {
                  setOpenAttemptsSheet(false)
                  setSelectedAssessmentId(null)
                }}
                className="mb-3"
              >
                Close
              </Button>
              {currentAssessment && (
                <Typography variant="title3" className="mb-2">
                  {currentAssessment.title}
                </Typography>
              )}
              <div className="flex flex-col">
                {attemptsData.length > 0 ? (
                  attemptsData.map((attempt, index) => (
                    <AttemptCard
                      key={index}
                      attempt={attempt}
                      attemptNumber={attemptsData.length - index}
                    />
                  ))
                ) : (
                  <Typography
                    variant="body"
                    className="mt-2 rounded-lg border border-critical-500 bg-critical-50 p-1"
                  >
                    No attempts available.
                    <br />
                    Please contact care@suraasa.com.
                  </Typography>
                )}
              </div>
            </SheetBody>
          </SheetContent>
        </Sheet>
      </div>
    </>
  )
}

export default CourseAssessments

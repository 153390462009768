import { ReattemptRequestStatusEnum } from "../assignments/types"
import { AccessStatus, UserPlannerItemStatus } from "../learningItems/types"

export type AttemptResponse = {
  questions: AssessmentQuestion[]
  assessment: {
    title: string
    assessmentType: AssessmentType
    duration: string
    instructions: string | null
  }
  total: number
  startTime: string
  endTime: string | null
}

export type UserResponse = (number | null)[]

export type Question = {
  id: string
  questionType:
    | QuestionType.FILL_IN_THE_BLANKS
    | QuestionType.MULTIPLE_CORRECT
    | QuestionType.SINGLE_CORRECT
  options: Record<string, string>
  question: string
  status: QuestionStatus
  questionNumber: number
  response: UserResponse | null
  tempAssessmentPerformanceId: string
}

export type CaseStudyQuestion = {
  id: string
  questionType: QuestionType.CASE_STUDY
  text: string
  questionNumber: number
  // TODO: Case study ke subquestions mein question number nahi hota
  questions: Question[]
}

export type AssessmentQuestion = Question | CaseStudyQuestion

type AssessmentSchedule = {
  duration: string | null
  startTime: string | null
  endTime: string | null
}

export type Attempt = {
  uuid: string
  score: number | null
  status: AttemptStatus
  result: AttemptResult | null
  assessmentSchedule: AssessmentSchedule | null
  assessment: {
    uuid: string
    title: string
    assessmentType: AssessmentType
  }
  language: string
}

export enum QuestionType {
  CASE_STUDY = 1,
  MULTIPLE_CORRECT = 2,
  FILL_IN_THE_BLANKS = 3,
  SINGLE_CORRECT = 4,
}

export enum AttemptStatus {
  NOT_STARTED = "not_started",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  MISSED = "missed",
}

export enum QuestionStatus {
  UNATTEMPTED = "unattempted",
  ANSWERED = "answered",
  MARKED_FOR_REVIEW = "marked_for_review",
}

enum AssessmentType {
  PRE_COURSE = 1,
  MILESTONE = 2,
  POST_COURSE = 3,
  MOCK = 4,
  LIVE = 5,
}

export enum AssessmentTypeString {
  PRE_COURSE = "pre_course",
  MILESTONE = "milestone",
  POST_COURSE = "post_course",
  MOCK = "mock",
  LIVE = "live",
}

export enum AttemptResult {
  PASS = "pass",
  FAIL = "fail",
}

export type CaseStudyAnswer = {
  id: string
  questionType: QuestionType.CASE_STUDY
  questionNumber: number
  text: string
  questions: Answer[]
}

export type Answer = Omit<Question, "tempAssessmentPerformanceId"> & {
  correctAnswer: number[]
  explanation: string
  isCorrect: boolean
  ordered: boolean
}

export type QuestionSolution = Answer | CaseStudyAnswer

export type AssessmentSolutions = {
  solutions: QuestionSolution[]
  totalQuestions: number
  assessmentName: string
  assessmentType: AssessmentTypeString
  assessmentId: number
  courseId: string | null
  score: number
  isLatest?: boolean
  attemptStatus?: AttemptStatus
  reattemptType?: ReattemptType
  userReattemptCounter?: UserReattemptCounterType | null
  userPlannerItemId?: number
  reattemptRequest?: ReattemptRequestStatusEnum | null
  assessmentPassingPercentage: number
}

export type AutoSubmitted = {
  autoSubmitted: boolean
}

export type ShowAnswers = {
  showAnswers: boolean
}

export type TopicScore = {
  topicWiseScores: {
    topic: string
    score: number
  }[]
  assessmentId: number
  courseId: string
  coursePk: number
}

// export type AssessmentListItem = {
//   id: number
//   attempt: Attempt
//   uuid: string
//   title: string
//   assessmentType: AssessmentType
//   contentType: string
//   learningItem: {
//     id: number
//     uuid: string
//     name: string
//     image: string
//     slug: string
//     itemType: string
//     itemLevel: string | null
//     duration: number
//   }
// }

export enum ReattemptType {
  AUTOMATIC = "automatic",
  ON_REQUEST = "on_request",
}
export type UserReattemptCounterType = {
  reattemptsAllowed: number
  reattemptsConsumed: number
  activeReattemptsRequests: number
}

export type AssessmentListItem = {
  id: number
  uuid: string
  title: string
  assessmentType: AssessmentType
  assessmentSchedule: {
    id: number
    attempt: Attempt | null
    startTime: string
    endTime: string
    duration: string | null
  } | null
  accessStatus: AccessStatus
  contentType: "assessment_schedule"
  learningItem: {
    name: string
    slug: string
  }
  reattemptType?: ReattemptType
  userReattemptCounter?: UserReattemptCounterType | null
  reattemptRequest?: ReattemptRequestStatusEnum | null
  status: UserPlannerItemStatus
}

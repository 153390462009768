import { IconButton, Typography } from "@suraasa/placebo-ui"
import { Reference } from "api/resources/references/types"
import { Edit } from "iconoir-react"
import { useState } from "react"
import { trackElementOnPH } from "utils/tracking"

import ReferenceEditDialog from "./EditDialog"
import { useScrollToReference } from "./useScrollToReference"
import { getRelationshipLabel, ReferenceStatusDisplay } from "./Utils"

const ReferenceItem = ({
  data,
  isPublic,
}: {
  data: Reference
  isPublic?: boolean
}) => {
  const hasSubmitted = data?.userQuestionnaire?.submittedAt
  const [openDialog, toggle] = useState(false)
  const relationshipLabel = getRelationshipLabel(data?.relationship)

  const { elementId } = useScrollToReference(data.id)

  return (
    <>
      {openDialog && (
        <ReferenceEditDialog
          data={data}
          open={openDialog}
          handleClose={() => toggle(false)}
        />
      )}
      <div className="group relative" key={data.id} id={elementId}>
        {!isPublic && !hasSubmitted && (
          <IconButton
            className="absolute right-0 transition-[0.2s] md:right-[-30px] md:opacity-0 group-hover:md:right-0 group-hover:md:opacity-100"
            color="black"
            onClick={() => toggle(true)}
            {...trackElementOnPH({
              name: "Edit Reference",
              id: "edit-reference-request-button",
            })}
          >
            <Edit className="!size-3" />
          </IconButton>
        )}
        <div>
          <Typography className="mb-1.5" variant="strong">
            {data.referrer.name}
          </Typography>
          <Typography className="mb-0.5 text-onSurface-600" variant="smallBody">
            {data.referrer.position}, {relationshipLabel}
            <br />
            <span className="text-smallBody">{data.organisationName}</span>
          </Typography>
        </div>
        <div className="mt-2">
          <ReferenceStatusDisplay data={data} />
        </div>
      </div>
    </>
  )
}

export default ReferenceItem

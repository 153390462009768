import { Button, Tag, Tooltip, Typography } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import queries from "api/resources/learningItems/queries"
import {
  LearningItemType,
  ScheduleType,
} from "api/resources/learningItems/types"
import SimpleCard from "components/SimpleCard"
import CheckRestrictions from "features/AccessManagement/CheckRestrictions"
import {
  reloadOnAssessmentClose,
  startAssessment,
} from "features/Assessments/helpers"
import { formatDate, formatDurationPrecise } from "utils/helpers"

import LiveCalendarIcon from "./CalendarIcon"

const timeFormat = "do MMMM yyyy, h:mm a"

const getAssessmentDescription = (props: {
  title: string
  duration: string | null
  time: string
}) => {
  return [
    props.title.toLowerCase().includes("assessment") ? null : "Assessment",
    formatDate(props.time, timeFormat),
    props.duration ? formatDurationPrecise(props.duration) : null,
  ]
    .filter(Boolean)
    .join(" • ")
}

const getSessionDescription = (props: {
  professorName: string
  time: string
}) => {
  return [
    formatDate(props.time, timeFormat),
    props.professorName ? `Conducted by ${props.professorName}` : null,
  ]
    .filter(Boolean)
    .join(" • ")
}

const NextUp = ({
  learningItemType,
  learningItemId,
}: {
  learningItemType: LearningItemType
  learningItemId: number
}) => {
  const { data, isSuccess } = useQuery({
    queryFn: () =>
      api.learningItems.getUpcomingPlannerItems({
        urlParams: { learningItemType, learningItemId },
      }),
    queryKey: queries.upcomingPlannerItem(learningItemType, learningItemId)
      .queryKey,
  })

  if (!isSuccess) return null

  if (data.length === 0) return null

  const assessment = data.find(
    upNext => upNext.scheduleType === ScheduleType.ASSESSMENT
  )

  const session = data.find(
    upNext => upNext.scheduleType === ScheduleType.CLASS
  )

  return (
    <div>
      <Typography variant="title3" className="mb-1">
        Next Up
      </Typography>

      <div className="flex flex-col gap-1">
        {session && session.scheduleType === ScheduleType.CLASS && (
          <CheckRestrictions
            accessStatus={session.accessStatus}
            render={({ isRestricted }) => {
              const canJoin = session.videoMeeting?.joiningUrl

              return (
                <SimpleCard className="flex flex-wrap items-start justify-between gap-2 border-highlight-200 bg-highlight-50 ">
                  <div className="flex flex-col gap-1.5">
                    <LiveCalendarIcon date={session.startTime} />

                    <div>
                      <Typography className="text-title4 text-highlight-700">
                        Live Class: {session.classId.name}
                      </Typography>
                      <Typography className=" text-muted" variant="smallBody">
                        {getSessionDescription({
                          professorName: session.professor.fullName,
                          time: session.startTime,
                        })}
                      </Typography>
                      {session.lectures.length > 0 && (
                        <div className="mt-1">
                          <Typography className="mb-0.5 text-strongSmallBody">
                            Topics Covered:
                          </Typography>
                          <div className="flex flex-wrap gap-1">
                            {session.lectures.map(lecture => (
                              <Tag
                                key={lecture.uuid}
                                color="onSurface"
                                className="border-onSurface-300"
                              >
                                {lecture.name}
                              </Tag>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-full sm:w-fit">
                    <Tooltip
                      enabled={isRestricted || !canJoin}
                      content={
                        isRestricted
                          ? "Access Restricted"
                          : !canJoin
                          ? `Starts at ${formatDate(
                              session.startTime,
                              timeFormat
                            )}`
                          : ""
                      }
                    >
                      <Button
                        className="w-full"
                        size="sm"
                        disabled={isRestricted || !canJoin}
                        onClick={() => {
                          if (session.videoMeeting?.joiningUrl)
                            window.open(
                              session.videoMeeting.joiningUrl,
                              "_blank"
                            )
                        }}
                      >
                        Join Class
                      </Button>
                    </Tooltip>
                  </div>
                </SimpleCard>
              )
            }}
          />
        )}

        {assessment && assessment.scheduleType === ScheduleType.ASSESSMENT && (
          <CheckRestrictions
            accessStatus={assessment.accessStatus}
            render={({ isRestricted }) => {
              const timeHasStarted = new Date(assessment.startTime) < new Date()

              return (
                <SimpleCard className="flex flex-wrap items-start justify-between gap-1 border-highlight-200 bg-highlight-50 ">
                  <div className="flex flex-col gap-1.5">
                    <LiveCalendarIcon date={assessment.startTime} />

                    <div>
                      <Typography className="text-title4 text-highlight-700">
                        {assessment.assessment.title}
                      </Typography>
                      <Typography className=" text-muted" variant="smallBody">
                        {getAssessmentDescription({
                          title: assessment.assessment.title,
                          duration: assessment.duration,
                          time: assessment.startTime,
                        })}
                      </Typography>
                      <Typography
                        className="mt-1 text-muted"
                        variant="smallBody"
                      >
                        This final assessment counts toward your grade.
                      </Typography>
                    </div>
                  </div>
                  <div className="w-full sm:w-fit">
                    <Tooltip
                      enabled={isRestricted || !timeHasStarted}
                      content={
                        isRestricted
                          ? "Access Restricted"
                          : !timeHasStarted
                          ? `Starts at ${formatDate(
                              assessment.startTime,
                              timeFormat
                            )}`
                          : ""
                      }
                    >
                      <Button
                        className="w-full"
                        size="sm"
                        disabled={isRestricted || !timeHasStarted}
                        onClick={() => {
                          startAssessment({
                            assessmentId: assessment.assessment.uuid,
                            onClose: reloadOnAssessmentClose,
                          })
                        }}
                      >
                        Attempt Assessment
                      </Button>
                    </Tooltip>
                  </div>
                </SimpleCard>
              )
            }}
          />
        )}
      </div>
    </div>
  )
}

export default NextUp

import { AuthData } from "api/resources/users/types"
import { importantSearchParams, Platforms, USER_TYPE } from "utils/constants"
import { getAuthInfo, getOtherPlatformAuthInfo } from "utils/helpers"

export const getCurrentPlatform = (): Platforms => {
  const search = new URLSearchParams(window.location.search)
  const schoolAuth = getOtherPlatformAuthInfo(Platforms.school)
  const centreAuth = getOtherPlatformAuthInfo(Platforms.centerAdmin)

  const isSchool =
    search.get(importantSearchParams.platformOverride) === Platforms.school &&
    Boolean(schoolAuth)

  if (isSchool) return Platforms.school

  const isCentre =
    search.get(importantSearchParams.platformOverride) ===
      Platforms.centerAdmin && Boolean(centreAuth)

  if (isCentre) return Platforms.centerAdmin

  return USER_TYPE
}

export const getCurrentToken = (): AuthData | null => {
  const platform = getCurrentPlatform()

  switch (platform) {
    case Platforms.school: {
      return getOtherPlatformAuthInfo(platform)
    }
    case Platforms.centerAdmin: {
      return getOtherPlatformAuthInfo(platform)
    }
    case Platforms.student: {
      return getAuthInfo()
    }
  }

  throw new Error("platform not handled")
}

import { Typography } from "@suraasa/placebo-ui"
import { UseQueryResult } from "@tanstack/react-query"
import { LearningCertificate } from "api/resources/profile/types"
import NoCertificates from "assets/Fallback/no-certificates.svg"
import clsx from "clsx"
import Fallback from "components/Fallback"
import FullPageLoading from "components/FullPageLoading"
import ShareSheet from "components/ShareSheet"
import { useState } from "react"
import { learningItemSlugs } from "utils/constants"
import { useEnrollments } from "utils/hooks/useEnrollments"

import MyLearningCard from "../Cards/MyLearningCard"
import styles from "./LearningCertificates.module.css"
type Props = {
  certificates: UseQueryResult<LearningCertificate[]>
}

const isCompleted = (
  certificates: LearningCertificate[] | undefined,
  slug: string
) => {
  if (!certificates) return false
  return certificates.find(x => x.item.slug === slug)
}

const LearningCertificates = (props: Props) => {
  const { enrollments } = useEnrollments()
  const [certificateUrl, setCertificateUrl] = useState<string>("")

  if (props.certificates.isError) {
    return (
      <Fallback
        data={{
          image: NoCertificates,
          title: "Something went wrong!",
          description: "Please try again later.",
        }}
        className="fixed left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 px-2 text-center sm:w-fit sm:px-0"
        hasError={true}
      />
    )
  }

  if (!props.certificates.data || props.certificates.isLoading) {
    return <FullPageLoading />
  }

  const courses = enrollments
    ?.filter(item => item.type === "course")
    /**
     * We don't want to show the courses for individual M.Ed. courses
     */
    .filter(item => {
      const isMedCourse = Boolean(
        item.parents.find(parent => parent.slug === learningItemSlugs.med)
      )
      return !isMedCourse
    })
    .sort((a, b) =>
      isCompleted(props.certificates.data, a.slug) &&
      !isCompleted(props.certificates.data, b.slug)
        ? -1
        : !isCompleted(props.certificates.data, a.slug) &&
          isCompleted(props.certificates.data, b.slug)
        ? 1
        : a.name.localeCompare(b.name)
    )

  // We currently don't have certificates for 'certifications (like mpds)'
  // const certificates = enrollments?.filter(
  //   item => item.type === "certification"
  // )

  /**
   * We don't want to show the certificates for M.Ed. Qualification
   */
  const qualifications = enrollments
    ?.filter(item => item.type === "qualification")
    .filter(item => item.slug !== learningItemSlugs.med)

  if (enrollments?.length === 0) {
    return (
      <Fallback
        data={{
          image: NoCertificates,
          title: "Looks like you don’t have any certificates yet!",
          description: "Take part in courses by Suraasa to earn certificates",
        }}
        className="fixed left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 px-2 text-center sm:w-fit sm:px-0"
        hasError={false}
      />
    )
  }

  return (
    <>
      {qualifications && qualifications.length > 0 && (
        <>
          <Typography variant="title2" className="mb-2">
            Qualifications
          </Typography>
          <div
            className={clsx(
              "mb-4 grid gap-1 sm:gap-x-3 sm:gap-y-2 md:gap-x-2",
              styles.gridContainer
            )}
          >
            {qualifications?.map((item, index) => (
              <MyLearningCard
                completedEnrollment={props.certificates.data?.find(
                  x =>
                    x.item.slug === item.slug &&
                    x.item.itemType === "qualification"
                )}
                key={index}
                inCompletedEnrollment={item}
                setCertificateUrl={url => setCertificateUrl(url)}
              />
            ))}
          </div>
        </>
      )}
      {/* {certificates && certificates.length > 0 && (
        <>
          <Typography variant="title2" className="mb-2">
            Certifications
          </Typography>
          <div
            className={clsx(
              "grid md:gap-x-2 sm:gap-x-3 sm:gap-y-2 gap-1 mb-4",
              styles.gridContainer
            )}
          >
            {certificates?.map((item, index) => (
              <MyLearningCard
                completedEnrollment={props.certificates.find(
                  x =>
                    x.item.slug === item.slug &&
                    x.item.itemType === "certification"
                )}
                key={index}
                inCompletedEnrollment={item}
                setCertificateUrl={url => setCertificateUrl(url)}
              />
            ))}
          </div>
        </>
      )} */}
      {courses && courses.length > 0 && (
        <>
          <Typography variant="title2" className="mb-2">
            Courses
          </Typography>
          <div
            className={clsx(
              "mb-4 grid gap-1 sm:gap-x-3 sm:gap-y-2 md:gap-x-2",
              styles.gridContainer
            )}
          >
            {courses?.map((item, index) => (
              <MyLearningCard
                completedEnrollment={props.certificates.data?.find(
                  x => x.item.slug === item.slug && x.item.itemType === "course"
                )}
                key={index}
                inCompletedEnrollment={item}
                setCertificateUrl={url => setCertificateUrl(url)}
              />
            ))}
          </div>
        </>
      )}
      <ShareSheet
        data={{
          url: certificateUrl,
          text: `Checkout my certificate on Suraasa: ${certificateUrl}`,
        }}
        open={!!certificateUrl}
        handleClose={() => {
          setCertificateUrl("")
        }}
      />
    </>
  )
}

export default LearningCertificates

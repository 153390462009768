// import { captureException } from "@sentry/react"
import { APIResponse, APIResponseOld } from "api/types"
import { AxiosError, AxiosResponse, AxiosTransformer } from "axios"
import camelCase from "lodash/camelCase"
import snakeCase from "lodash/snakeCase"
import { SERVICE_PREFIX } from "utils/constants"
import { isUUIDString } from "utils/helpers"

type Options = {
  keepOriginalResponse?: boolean
  paginatedResponse?: boolean
}

const formatSuccessResponse = <T>(
  res: AxiosResponse,
  options?: Options
): APIResponse<T> => {
  if (options?.keepOriginalResponse) {
    return { ...res.data.data, __data: res }
  }
  if (options?.paginatedResponse) {
    return { ...res.data, __data: res }
  }
  return res.data.data
}

/**
 * @deprecated
 */
export const formatSuccessResponseOld = <T>(
  res: AxiosResponse,
  options?: Options
): APIResponseOld<T> => {
  if (options?.keepOriginalResponse) {
    return { isSuccessful: true, data: res.data.data, __data: res }
  }
  if (options?.paginatedResponse) {
    return { data: res.data, __data: res, isSuccessful: true }
  }
  return { isSuccessful: true, data: res.data.data }
}

const isAxiosError = (error: unknown): error is AxiosError => {
  if (typeof error === "object" && error !== null) {
    if ("isAxiosError" in error) {
      // @ts-expect-error Disabling is safe here. There is a manual check for the property on the line above.
      return error.isAxiosError
    }
  }
  return false
}

export class APIError extends Error {
  /**
   * True if the server responds with status code 500
   */
  is500: boolean
  /**
   * True if the server responds with status code 404 and content-length = 0
   */
  isEmpty404: boolean
  /**
   * True if either of the following occurs:
   * - There is a network failure on the client
   * - The server is unresponsive
   * - The request timed out
   */
  isNetworkFailure: boolean
  /**
   * Response status code
   */
  statusCode?: number
  /**
   * Contains the original error returned by axios.
   * Is useful when the consumer would like to access
   * other properties of the response which is very rare
   */
  __errors: AxiosError
  /**
   * Object containing a generic error message and 'key specific' error
   * There will always be one or the other. Not both together
   */
  errors: {
    /**
     * A generic error message describing the error (new structure)
     */
    message?: string
    /**
     * A generic error message describing the error (old structure)
     */
    detail?: string
    /**
     * These string keys contain the name of the key where the error has occurred
     */
    fieldErrors?: {
      [key: string]: string[] | string
    }
  }

  constructor(error: AxiosError) {
    super(`API Failed with status code: ${error.response?.status}`)
    this.name = "APIError"

    this.__errors = error

    this.is500 = error.response?.status === 500
    this.isNetworkFailure = error.response === undefined
    this.isEmpty404 =
      error.response?.status === 404 &&
      error.response?.headers["content-length"] === "0"
    this.statusCode = error.response?.status

    let detail = undefined

    let message = undefined
    let fieldErrors = undefined

    if (!this.is500 && !this.isNetworkFailure && !this.isEmpty404) {
      detail = error.response?.data.errors?.detail ?? null
      if (!detail && error.response) {
        message = error.response.data.errors?.message ?? null
        if (message) delete error.response.data.errors.message
        fieldErrors = error.response.data.errors ?? null
      }
    }

    this.errors = {
      fieldErrors,
      message,
      detail,
    }
  }
}

/**
 *
 * @param error This argument is `unknown` because it could be any JavaScript error.
 * We cannot be certain that it will be an `AxiosError` every time.
 */
const formatErrorResponse = (error: unknown) => {
  if (!isAxiosError(error)) {
    throw error
  }

  throw new APIError(error)
}

/**
 * @deprecated
 */
export const formatErrorResponseOld = (error: unknown) => {
  if (!isAxiosError(error)) {
    throw error
  }

  return new APIError(error)
}

/**
 * Converts object keys to a specific format specified by the transformer function recursively
 */
const transformKeys = (obj: any, transformer: (arg: string) => string): any => {
  if (Array.isArray(obj)) {
    return obj.map(v => transformKeys(v, transformer))
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce((result, key) => {
      return {
        ...result,
        /**
         * There are cases when object keys are UUIDs
         * In our previous setup they were getting converted into
         * camelCase, which is definitely not wanted, therefore this check
         * Example for this --> AllAssignments API call returns such obj
         */
        [isUUIDString(key) ? key : transformer(key)]: transformKeys(
          obj[key],
          transformer
        ),
      }
    }, {})
  }
  return obj
}

/**
 * Override axios's default implementation of serializing query params
 * we allow the following:
 * ?status=0&status=1
 */
function paramsSerializer(params = {}): string {
  const arr: string[] = []
  for (const [key, value] of Object.entries(params)) {
    if (value === null) continue

    if (Array.isArray(value)) {
      value.forEach(v => arr.push(`${key}=${v}`))
    } else {
      arr.push(`${key}=${value}`)
    }
  }
  return arr.join("&")
}

/**
 * __noTransform is used to bypass snakeCase conversion. Reason for doing this is:
 * newPassword1 gets converted to new_password_1
 * But, the backend expects new_password1
 * However, the proper snake case implementation is new_password_1
 *
 * This work-around will be removed when backend has fixed their APIs
 */
const transformRequest: AxiosTransformer = data => {
  if (data?.__noTransform) {
    delete data["__noTransform"]
    return data
  }

  return transformKeys(data, snakeCase)
}

const transformResponse: AxiosTransformer = data => {
  return transformKeys(data, camelCase)
}

export const getServiceURL = (base: SERVICE_PREFIX) => (url: string) =>
  base + url

/**
 * By default, this function DOES NOT log for `statusCode = 401`
 */
const logAPIError = (errors: APIError | AxiosError, exceptions = [401]) => {
  let statusCode
  let originalError
  if (isAxiosError(errors)) {
    statusCode = errors.response?.status
    originalError = errors
  } else {
    statusCode = errors.statusCode
    originalError = errors.__errors
  }

  if (statusCode && exceptions.includes(statusCode)) return

  const { config } = originalError

  // @ts-expect-error we can delete this key now
  delete errors.__errors

  const fullURL = originalError.request.responseURL
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const shortURL = config.url

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const data = {
    response: errors,
    rawResponse: originalError.request.response,
    request: {
      url: fullURL,
      method: config.method,
      headers: config.headers,
      data:
        config.data instanceof FormData
          ? Object.fromEntries(config.data)
          : config.data,
    },
  }
  // Debugging purpose
  // console.log(JSON.stringify(data, null, 2))
  // captureException(
  //   new Error(`API Error (${statusCode}) at: ${shortURL}`),
  //   scope => {
  //     scope.setExtras({
  //       request: JSON.stringify(data.request, null, 2),
  //       response: JSON.stringify(data.response, null, 2),
  //       rawResponse: data.rawResponse,
  //     })
  //     return scope
  //   }
  // )
}

export {
  formatErrorResponse,
  formatSuccessResponse,
  logAPIError,
  paramsSerializer,
  transformKeys,
  transformRequest,
  transformResponse,
}

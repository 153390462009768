import {
  CircularProgress,
  Sheet,
  SheetBody,
  SheetContent,
  SheetHeader,
  SheetTitle,
  Typography,
} from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"

import ProfessorReviewCard from "../ProfessorReviewCard"
import Submission from "../Submission"

type Props = {
  open: boolean
  onClose: () => void
  title: string
  submissionId: null | string
}

const ViewPreviousSubmissionDialog = ({
  open,
  onClose,
  title,
  submissionId,
}: Props) => {
  const submission = useQuery({
    enabled: !!submissionId,
    queryFn: () =>
      api.assignments.retrieveSubmission({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        urlParams: { submissionId: submissionId! },
        params: { fields: "iqa_review" },
      }),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryKey: queries.assignments.submissionsByUUID(submissionId!).queryKey,
  })

  return (
    <Sheet open={open} onOpenChange={onClose}>
      <SheetContent
        height={80}
        closeWhenInteractOutside
        className="select-text"
      >
        <SheetHeader>
          <SheetTitle>
            <Typography variant="title3">{title}</Typography>
          </SheetTitle>
        </SheetHeader>
        <SheetBody>
          <div>
            {submission.isSuccess && (
              <div className="mb-3">
                <ProfessorReviewCard
                  useDialogForRubrics
                  hideAction
                  status={submission.data.status}
                  result={submission.data.result}
                  review={submission.data.review}
                  iqaReview={submission.data.iqaReview}
                />
              </div>
            )}

            <Typography variant="title3" className="mb-2">
              Your Submission
            </Typography>
            {submission.isSuccess && (
              <Submission
                content={submission.data.submission}
                references={submission.data.references}
                citations={submission.data.citations}
                attachments={submission.data.attachments}
              />
            )}
            {submission.isLoading && (
              <div className="flex justify-center p-3">
                <CircularProgress />
              </div>
            )}
            {submission.isError && (
              <Submission
                content={"Some error occurred while loading submission"}
                attachments={[]}
              />
            )}
          </div>
        </SheetBody>
      </SheetContent>
    </Sheet>
  )
}

export default ViewPreviousSubmissionDialog

import { Typography } from "@suraasa/placebo-ui"
import { Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { ReattemptType } from "api/resources/assessments/types"
import { AssignmentList } from "api/resources/assignments/types"
import HandHoldingPen from "assets/Fallback/hand-holding-pen.svg"
import clsx from "clsx"
import Fallback from "components/Fallback"
import ItemList from "components/ItemList"
import ItemRow from "components/ItemRow"
import TruncatedText from "components/TruncatedText"
import CheckRestrictions, {
  getRestriction,
} from "features/AccessManagement/CheckRestrictions"
import {
  buildAssignmentTitle,
  getActionConfig,
} from "features/Assignments/utils"
import ReattemptCount from "features/ReattemptCount"
import { Lock } from "iconoir-react"
import groupBy from "lodash/groupBy"
import { useMemo } from "react"
import { useTheme } from "react-jss"
import { useParams } from "react-router-dom"
import { PropsWithClassName } from "utils/types"

import { useCourseDetails } from ".."
import styles from "./Assignments.module.css"
import GroupedAssignmentCard from "./GroupedAssignments"
import TutorialVideo from "./TutorialVideo"
import { getButtonAction, getDueDate } from "./utils"

const groupAssignmentsByName = (assignmentList: AssignmentList[]) => {
  const groupByName = groupBy(
    assignmentList,
    item => item.groupName ?? item.shortTitle
  )
  const allGroups = Object.entries(groupByName).map(([groupName, items]) => ({
    groupName,
    assignments: items || [],
  }))
  return allGroups
}

const CourseAssignments = ({ className }: PropsWithClassName) => {
  // const [openReattemptDialog, setOpenReattemptDialog] = useState<{
  //   title: string
  //   userReattemptCounter?: UserReattemptCounterType | null
  // } | null>(null)
  const { course } = useCourseDetails()

  const { data, isLoading, isError } = useQuery({
    queryKey: queries.assignments.list("course", course.id).queryKey,
    queryFn: () =>
      api.assignments.list({
        urlParams: {
          learningItemType: "course",
          learningItemId: course.id,
        },
      }),
  })

  const groupedAssignments = useMemo(
    () => groupAssignmentsByName(data || []),
    [data]
  )

  const theme = useTheme<Theme>()

  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const isDebugMode = localStorage.getItem("assignmentListDebug") === "true"

  const { slug, parentSlug } = useParams() as {
    slug: string
    parentSlug: string
  }

  const isRestrictedInAnyWay = data?.some(x => getRestriction(x))
  const hasGroupedAssignments = data?.some(item => item.groupName)

  const reAttemptRequestsConfig = data?.find(
    item => item.reattemptType === ReattemptType.ON_REQUEST
  )

  return (
    <>
      <div className={className}>
        <Typography className="mb-2" variant="title3">
          Skill Evidences (Assignments)
        </Typography>

        {!isLoading &&
          !isError &&
          !isRestrictedInAnyWay &&
          !hasGroupedAssignments && (
            <TutorialVideo courseSlug={course.slug} className="mb-2" />
          )}
        {(isError || isLoading) && (
          <Fallback
            data={{
              image: HandHoldingPen,
              title: "Something went wrong!",
              description: "Please contact care@suraasa.com",
            }}
            className="my-9 sm:my-20 md:my-18"
            hasError={isError}
            isLoading={isLoading}
          />
        )}

        <ReattemptCount
          userReattemptCounter={reAttemptRequestsConfig?.userReattemptCounter}
          className="mb-2"
        />

        {hasGroupedAssignments &&
          groupedAssignments.map((item, index) => (
            <div key={index}>
              <GroupedAssignmentCard
                assignments={item.assignments}
                courseId={course.id}
                groupName={item.groupName}
              />
            </div>
          ))}

        {!hasGroupedAssignments && (data || []).length > 0 && (
          <ItemList>
            {(data || []).map(assignment => {
              const config = getActionConfig(assignment)
              const isLocked = assignment.dueDate === null

              return (
                <ItemRow
                  key={assignment.uuid}
                  className={clsx("relative", {
                    "bg-[linear-gradient(270deg,#FBF0F0_0%,rgba(251,240,240,0)_39.36%)]":
                      config.isOverdue,
                    [styles.draft]: config.isDraft,
                  })}
                  infoSlot={
                    <div className="grow">
                      {isDebugMode && (
                        <pre>{JSON.stringify(assignment, null, 2)}</pre>
                      )}
                      {config.isOverdue && (
                        <div
                          className={clsx(
                            "absolute left-0 top-0 h-full w-0.5 bg-critical-500"
                          )}
                        />
                      )}
                      {config.isDraft && (
                        <Typography
                          variant="smallBody"
                          style={{ fontSize: 12 }}
                          className="mb-0.5 text-onSurface-500"
                        >
                          Continue Writing
                        </Typography>
                      )}
                      <TruncatedText
                        maxLength={60}
                        variant="strong"
                        className="mb-0.5"
                        hideActionButton
                      >
                        {buildAssignmentTitle(assignment)}
                      </TruncatedText>

                      <div className="flex items-center gap-1">
                        {config.tag}
                        {getDueDate(assignment)}
                      </div>
                    </div>
                  }
                  actionSlot={
                    <CheckRestrictions
                      accessStatus={assignment.accessStatus}
                      render={({ isRestricted }) => {
                        if (isLocked || isRestricted) return <Lock />

                        return (
                          <span className={clsx({ "self-end": isXs })}>
                            {getButtonAction(assignment, {
                              slug,
                              parentSlug,
                              ...config,
                            })}
                          </span>
                        )
                      }}
                    />
                  }
                />
              )
            })}
          </ItemList>
        )}

        {groupedAssignments.length === 0 && data?.length === 0 && (
          <Fallback
            data={{
              image: HandHoldingPen,
              title: "No skill evidences (assignments) found!",
              description:
                "Make progress in your courses to unlock skill evidences (assignments)!",
            }}
            className="my-9 sm:my-20 md:my-18"
          />
        )}
      </div>
    </>
  )
}

export default CourseAssignments

import * as Sentry from "@sentry/react"
import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import {
  Notification,
  NotificationActionName,
  NotificationResponse,
} from "api/resources/notifications/types"
import { PaginatedAPIResponse } from "api/types"
import { useNavigate } from "react-router-dom"
import routes from "routes"
import { getPlatformURL } from "utils/helpers"

import { TabNames } from "./useNotifications"

export const useNotificationAction = (notification: Notification) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const updateState = (
    state: InfiniteData<PaginatedAPIResponse<NotificationResponse>> | undefined
  ) => {
    if (state) {
      return {
        ...state,
        pages: state.pages.map(page => ({
          ...page,
          data: {
            ...page.data,
            notifications: page.data.notifications.map(x =>
              x.id === notification.id
                ? { ...x, dateOpened: new Date().toISOString() }
                : x
            ),
          },
        })),
      }
    }
    return state
  }

  const { mutate } = useMutation({
    mutationFn: () =>
      api.notifications.markAsOpened({
        params: {
          id: notification.id,
        },
      }),
    onSuccess: () => {
      queryClient.setQueryData(
        queries.notifications.notificationList(TabNames.ALL).queryKey,
        updateState
      )
      queryClient.setQueryData(
        queries.notifications.notificationList(TabNames.LEARN).queryKey,
        updateState
      )
      queryClient.setQueryData(
        queries.notifications.notificationList(TabNames.JOBS).queryKey,
        updateState
      )
    },
  })

  const navigationAction = (
    route: string,
    options?: {
      requiredKeys?: string[]
      data?: any
      isExternalNavigation?: boolean
    }
  ) => {
    mutate()

    const { action } = notification

    if (!action) {
      return null
    }
    if (!("name" in action)) return null
    if (!action.name) return null

    if (options?.requiredKeys) {
      for (const key of options.requiredKeys) {
        if (!options?.data[key]) {
          const message = `NotificationError: Malformed notification data for type ${action.name}. ("${key}" not found)`
          console.error(message, options.data)
          Sentry.captureException(new Error(message), scope => {
            scope.setExtras({
              notification: JSON.stringify(notification),
            })
            return scope
          })
          return
        }
      }
    }

    if (options?.isExternalNavigation) {
      window.location.href = route
      return
    }

    navigate(route)
  }

  const hasAction =
    notification.action &&
    "name" in notification.action &&
    Boolean(notification.action.name)

  const handleClick = () => {
    const { action } = notification
    if (!action) {
      return null
    }
    if (!("name" in action)) return null
    if (!action.name) return null

    switch (action.name) {
      case NotificationActionName.OPEN_ASSIGNMENTS_LIST: {
        if (action.data.certificationSlug) {
          return navigationAction(
            `/certification/${action.data.certificationSlug}/course/${action.data.courseSlug}/assignments`,
            {
              requiredKeys: ["certificationSlug", "courseSlug"],
              data: action.data,
            }
          )
        }
        if (action.data.qualificationSlug) {
          return navigationAction(
            `/qualification/${action.data.qualificationSlug}/course/${action.data.courseSlug}/assignments`,
            {
              requiredKeys: ["qualificationSlug", "courseSlug"],
              data: action.data,
            }
          )
        }
        return navigationAction(
          `/course/${action.data.courseSlug}/assignments`,
          {
            requiredKeys: ["courseSlug"],
            data: action.data,
          }
        )
      }
      case NotificationActionName.OPEN_JOB: {
        const route = action.data.jobPostSlug
          ? getPlatformURL(
              "jobs",
              `/school/${action.data.schoolSlug}?jobSlug=${action.data.jobPostSlug}`
            )
          : getPlatformURL("jobs", `/home?tab=Invitations`)

        return navigationAction(route, {
          requiredKeys: action.data.jobPostSlug
            ? ["schoolSlug", "jobPostSlug"]
            : [],
          data: action.data,
          isExternalNavigation: true,
        })
      }
      case NotificationActionName.OPEN_LEARNING_ITEM_OVERVIEW:
        return navigationAction(
          `/${action.data.learningItemType.toLowerCase()}/${action.data.slug}`,
          { requiredKeys: ["learningItemType", "slug"], data: action.data }
        )
      case NotificationActionName.LEGACY_REDIRECT_TO_DISCUSSION_DETAIL:
      case NotificationActionName.LEGACY_REDIRECT_TO_DISCUSSIONS_DETAIL:
      case NotificationActionName.OPEN_DISCUSSION:
        return navigationAction(
          `/discussions/${action.data.userDiscussionId}`,
          { requiredKeys: ["userDiscussionId"], data: action.data }
        )
      case NotificationActionName.LEGACY_REDIRECT_TO_ASSESSMENT_DETAIL:
      case NotificationActionName.OPEN_ASSESSMENT: {
        return navigationAction(
          routes.attemptAssessment.replace(
            ":assessmentId",
            action.data.assessmentUuid
          ),
          { requiredKeys: ["assessmentUuid"], data: action.data }
        )
      }
      case NotificationActionName.OPEN_SUBMISSION:
      case NotificationActionName.LEGACY_REDIRECT_TO_ASSIGNMENT_PAGE_DETAIL:
      case NotificationActionName.OPEN_ASSIGNMENT: {
        const courseSlug =
          "courseSlugs" in action.data
            ? action.data.courseSlugs?.[0]
            : "courseSlug" in action.data
            ? action.data.courseSlug
            : null

        if (!courseSlug) {
          throw new Error("Course slug not found in notification data")
        }

        const submissionId =
          "submissionUuid" in action.data
            ? action.data.submissionUuid
            : "submissionId" in action.data
            ? action.data.submissionId
            : null

        if (submissionId) {
          return navigationAction(
            routes.submission
              .replace(":slug", courseSlug)
              .replace(":learningItemType", "course")
              .replace(":assignmentId", action.data.assignmentId.toString())
              .replace(":submissionId", submissionId),
            {
              requiredKeys: ["assignmentId"],
              data: action.data,
            }
          )
        }

        return navigationAction(
          routes.assignment
            .replace(":slug", courseSlug)
            .replace(":learningItemType", "course")
            .replace(":assignmentId", action.data.assignmentId.toString()),
          {
            requiredKeys: ["assignmentId"],
            data: action.data,
          }
        )
      }
      case NotificationActionName.OPEN_FULL_CALENDAR:
      case NotificationActionName.LEGACY_REDIRECT_TO_CENTRE_SCHEDULE_DETAIL: {
        return navigationAction(routes.mySchedule)
      }
      case NotificationActionName.OPEN_CERTIFICATES_LIST:
      case NotificationActionName.LEGACY_REDIRECT_TO_CERTIFICATION_CERTIFICATE_DETAIL:
      case NotificationActionName.LEGACY_REDIRECT_TO_QUALIFICATION_CERTIFICATE_DETAIL:
      case NotificationActionName.LEGACY_REDIRECT_TO_COURSE_CERTIFICATE_DETAIL: {
        return navigationAction(routes.certificates)
      }

      case NotificationActionName.LEGACY_TRANSACTION_FAILED:
      case NotificationActionName.LEGACY_TRANSACTION_SUCCESSFUL:
      case NotificationActionName.OPEN_ORDER_DETAILS:
      case NotificationActionName.LEGACY_REDIRECT_TO_ORDER_DETAIL: {
        return navigationAction(
          routes.orderDetailsPage.replace(":id", action.data.orderId),
          { requiredKeys: ["orderId"], data: action.data }
        )
      }

      case NotificationActionName.LEGACY_REDIRECT_TO_HOMEPAGE:
      case NotificationActionName.OPEN_HOME_TAB: {
        return navigationAction(routes.home)
      }

      case NotificationActionName.LEGACY_ITEMS_ALLOCATED:
      case NotificationActionName.OPEN_LEARNING_TAB:
      case NotificationActionName.LEGACY_REDIRECT_TO_MY_LIBRARY: {
        return navigationAction(routes.learning)
      }

      default: {
        // @ts-expect-error DO NOT REMOVE this ts-expect-error directive
        // If this line gives an error, that means that there is an action that is not catered in the switch case. You should cater it.
        const message = `NotificationError: Action type not handled for type ${action.name}`
        console.error(message, action)
        Sentry.captureException(new Error(message), scope => {
          scope.setExtras({
            notification: JSON.stringify(notification),
          })
          return scope
        })
      }
    }
  }

  return { handleNotificationClick: handleClick, hasAction }
}

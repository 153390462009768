import { Typography } from "@suraasa/placebo-ui"
import {
  Container,
  Divider,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import { useQueries } from "@tanstack/react-query"
import api from "api/index"
import { queries } from "api/queries"
import {
  ITOCertificate,
  LearningCertificate,
} from "api/resources/profile/types"
import clsx from "clsx"
import TabBar from "components/TabBar"
import useTabs, { Tabs as TabsType } from "components/TabBar/hooks/useTabs"
import { useEffect, useState } from "react"

import LearningCertificates from "./LearningCertificates"
import Masterclasses from "./Masterclasses"
import styles from "./MyCertificates.module.css"
import OtherCertificates from "./OtherCertificates"

enum CertificateTabs {
  myLearning = "My Learning",
  masterclasses = "Masterclasses",
  others = "Others",
}

const initialTabs: TabsType = [
  {
    name: CertificateTabs.myLearning,
    content: () => null,
    count: 0,
  },
  {
    name: CertificateTabs.masterclasses,
    content: () => null,
    count: 0,
  },
  {
    name: CertificateTabs.others,
    content: () => null,
    count: 0,
  },
]

const MyCertificates = () => {
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const [certificates, masterclassCertificates, itoCertificates] = useQueries({
    queries: [
      {
        queryKey: queries.profile.learningCertificatesList().queryKey,
        queryFn: () =>
          api.profile.credentials.list({
            params: {
              types: ["course", "certification", "qualification"],
            },
          }),
        select: (data: any) => data as LearningCertificate[],
      },
      {
        queryKey: queries.profile.masterclassCertificatesList().queryKey,
        queryFn: () =>
          api.profile.credentials.listMasterclassCertificates({
            urlParams: {
              username: "",
            },
          }),
      },
      {
        queryKey: queries.profile.itoCertificatesList().queryKey,
        queryFn: () =>
          api.profile.credentials.list({
            params: {
              types: "olympiad_award",
            },
          }),
        select: (data: any) => data as ITOCertificate[],
      },
    ],
  })

  const [tabs, setTabs] = useState(initialTabs)

  const [activeTab, setActiveTab] = useTabs({ tabs, initialTab: tabs[0].name })

  const updateTabsDetails = (data: any[], tabName: string) => {
    setTabs(tabs =>
      tabs.map(tab => {
        if (tab.name === tabName) {
          if (data.length > 0)
            tab.count = data.length > 0 ? data.length : tab.count
          tab.notification = data.length > 0
          return tab
        }

        return tab
      })
    )
  }

  useEffect(() => {
    if (certificates.data)
      updateTabsDetails(certificates.data, CertificateTabs.myLearning)

    if (masterclassCertificates.data)
      updateTabsDetails(
        masterclassCertificates.data,
        CertificateTabs.masterclasses
      )

    if (itoCertificates.data)
      updateTabsDetails(itoCertificates.data, CertificateTabs.others)
  }, [certificates.data, itoCertificates.data, masterclassCertificates.data])

  return (
    <div>
      <Container className="bg-white">
        <div className="my-2">
          <Typography variant="title2">My Certificates</Typography>
        </div>
      </Container>
      <Container
        className={clsx("bg-white", { [styles.certificatesTopBar]: xsDown })}
        fullWidth={xsDown}
      >
        <TabBar
          className="pt-1"
          activeTab={activeTab}
          tabs={tabs}
          onChange={setActiveTab}
        />
      </Container>
      <Divider className={clsx(styles.divider, "mb-5")} />
      <Container>
        {activeTab.name === CertificateTabs.myLearning && (
          <LearningCertificates certificates={certificates} />
        )}
        {activeTab.name === CertificateTabs.masterclasses && (
          <Masterclasses masterclassesList={masterclassCertificates} />
        )}
        {activeTab.name === CertificateTabs.others && (
          <OtherCertificates othersList={itoCertificates} />
        )}
      </Container>
    </div>
  )
}

export default MyCertificates

import { Typography } from "@suraasa/placebo-ui"
import {
  Accordion,
  AccordionDetails,
  AccordionHeader,
} from "@suraasa/placebo-ui-legacy"
import { UseQueryResult } from "@tanstack/react-query"
import { LearningItem } from "api/resources/learningItems/types"
import { APIResponse } from "api/types"
import HandHoldingPen from "assets/Fallback/hand-holding-pen.svg"
import clsx from "clsx"
import Fallback from "components/Fallback"
import { NavArrowDown } from "iconoir-react"
import groupBy from "lodash/groupBy"

import MilestoneItem from "./MilestoneItem"

type Props = {
  plannerId: number
  className?: string
  query: UseQueryResult<APIResponse<LearningItem[]>, unknown>
}

const Curriculum = ({ className, plannerId, query }: Props) => {
  const { data, isError, isLoading } = query

  if (isError || isLoading || data.length === 0) {
    let title = "Something is not right"
    if (data?.length === 0) title = "Your course curriculum is not defined yet."
    if (isError) title = "Something went wrong"

    return (
      <div className={clsx(className)}>
        <Typography variant="title3" className="mb-1">
          Learning
        </Typography>
        <Fallback
          data={{
            image: HandHoldingPen,
            title: title,
            description: "Please contact care@suraasa.com",
          }}
          hasError={isError}
          isLoading={isLoading}
          className="mb-3 mt-9 sm:mt-20 md:mt-18"
        />
      </div>
    )
  }

  const milestones = groupBy(data, "milestone.id")

  const totalMilestones = Object.keys(milestones).length

  return (
    <div className={clsx(className)}>
      <Typography variant="title3" className="mb-1">
        Learning
      </Typography>
      {Object.entries(milestones).map(([milestoneId, milestone], index) => {
        const isLast = index === totalMilestones - 1

        return (
          <div
            key={milestoneId}
            className={clsx(
              "border-2 border-surface-200 bg-white px-0 py-2 sm:px-1",
              {
                "rounded-t-2xl": index === 0,
                "border-b-0": totalMilestones > 1 && !isLast,
                "rounded-b-2xl": isLast,
              }
            )}
          >
            <Accordion>
              <AccordionHeader icon={<NavArrowDown color="black" />}>
                <Typography className="text-end" variant="strong">
                  Milestone {index + 1}
                </Typography>
              </AccordionHeader>
              <AccordionDetails className="mt-2">
                {milestone.map((item, index) => (
                  <div
                    key={index}
                    className={clsx({
                      "mb-2": index < milestone.length - 1,
                    })}
                  >
                    <MilestoneItem item={item} plannerId={plannerId} />
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          </div>
        )
      })}
    </div>
  )
}

export default Curriculum

import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui"
import SuraasaLogo from "assets/logos/mini.webp"
// Assets
import PoweredByStripe from "assets/logos/powered-by-stripe.svg"
import { Xmark } from "iconoir-react"
import React, { useEffect, useState } from "react"
import routes from "routes"
import useFormatPrice from "utils/hooks/useFormatPrice"

type Props = {
  open: boolean
  handleClose: () => void
  itemName: string
  promocode?: string
  itemId: number
  amount: number
  currency: { symbol: string; code?: string } | null
}

export default function StripeCheckoutForm({
  open,
  handleClose,
  itemName,
  promocode,
  itemId,
  amount,
  currency,
}: Props) {
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const getSearchParams = () => {
    const params = new URLSearchParams()

    params.append("paymentGateway", "Stripe")
    params.append("itemName", itemName)
    if (promocode) params.append("promocode", promocode)
    if (currency?.code) params.append("currency", currency.code)
    params.append("itemId", itemId.toString())
    params.append("amount", amount.toString())

    return params.toString()
  }

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    )

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (!paymentIntent) return
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!")
          break
        case "processing":
          setMessage("Your payment is processing.")
          break
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.")
          break
        default:
          setMessage("Something went wrong.")
          break
      }
    })
  }, [stripe])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}${
          routes.orders
        }/?${getSearchParams()}&${window.location.search.replace("?", "")}`,
      },
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (
      (error.type === "card_error" || error.type === "validation_error") &&
      error.message
    ) {
      setMessage(error.message)
    } else {
      setMessage("An unexpected error occurred.")
    }

    setIsLoading(false)
  }

  const formatPrice = useFormatPrice()

  const priceStr = `${currency?.symbol}${formatPrice(amount)}`

  return (
    <Dialog open={open}>
      <DialogContent className="rounded-xl">
        <div className="relative flex min-h-16 items-center rounded-t-xl bg-primary-500 p-3 text-white">
          <div className="mr-3 flex min-h-10 min-w-10 items-center justify-center rounded-lg bg-white">
            <img src={SuraasaLogo} alt="Suraasa" className="size-5" />
          </div>
          <div>
            <Typography variant="largeBody">Suraasa</Typography>

            <Typography variant="body">{itemName}</Typography>

            <Typography className="mt-1" variant="title3">
              {priceStr}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className="absolute right-0 top-0 m-1"
              color="white"
            >
              <Xmark className="!size-3" />
            </IconButton>
          </div>
        </div>
        <img
          src={PoweredByStripe}
          alt="Powered by Stripe"
          height={120}
          className="h-8"
        />
        <form onSubmit={handleSubmit}>
          {/* Show any error or success messages */}
          <div className="my-2">
            {message && <Typography variant="body">{message}</Typography>}
          </div>
          <PaymentElement />
          <div className="mt-3">
            <Button
              className="min-h-[50px] w-full rounded-b-xl rounded-t-none shadow-none"
              color="primary"
              size="sm"
              type="submit"
              variant="filled"
              disabled={isLoading || !stripe || !elements}
              loading={isLoading}
            >
              Pay {priceStr}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

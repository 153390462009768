import { Typography } from "@suraasa/placebo-ui"
import {
  Button,
  Container,
  Theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { RestrictionType } from "api/resources/learningItems/types"
import Monitor from "assets/Fallback/monitor.svg"
import CertificationFallback from "assets/Placeholder/certificate_placeholder.svg"
import CourseFallback from "assets/Placeholder/course_placeholder.svg"
import clsx from "clsx"
import Fallback from "components/Fallback"
import FullPageLoading from "components/FullPageLoading"
import TalkToMentorBanner from "components/TalkToMentorBanner"
import UpcomingTasks from "components/UpcomingTasks"
import { getRestrictionIcon } from "features/AccessManagement/CheckRestrictions"
import ScheduleBar from "features/Home/ScheduleBar"
import { context } from "global/Context/context"
import { Message } from "iconoir-react"
import capitalize from "lodash/capitalize"
import { usePostHog } from "posthog-js/react"
import { useContext, useState } from "react"
import { useTheme } from "react-jss"
import { Link } from "react-router-dom"
import { CSSTransition } from "react-transition-group"
import { generateModuleAccessRequestLink } from "utils/helpers"

import ItemList from "../../components/ItemList"
import ItemRow from "../../components/ItemRow"
import TopHeadingBar from "../../components/TopHeadingBar"
import CourseInfoAndProgress from "../Library/CourseInfoAndProgress"
import ExploreCoursesOnStoreBar from "./ExploreCoursesOnStoreBar"
import ResumeCourseCard from "./ResumeCourseCard"
// import ResumeCourseCard from "./ResumeCourseCard"

const Library = () => {
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))
  const { learningRestrictions } = useContext(context)

  const [openUpcomingTasks, setOpenUpcomingTasks] = useState(false)

  const { data, isLoading, isSuccess, isError } = useQuery({
    queryFn: () => api.learningItems.getPlannerList(),
    queryKey: queries.learningItems.listPlanners().queryKey,
  })

  const { data: lastAccessedItem, isSuccess: isLastItemSuccess } = useQuery({
    queryFn: () => api.learningItems.getLastAccessedItem(),
  })

  const shouldShowLastAccessedItem = data && data.length > 1 && lastAccessedItem

  const posthog = usePostHog()

  return (
    <div className="relative">
      {isSmDown ? (
        <Container>
          <ScheduleBar />
        </Container>
      ) : (
        <>
          <CSSTransition
            in={!openUpcomingTasks}
            timeout={300}
            appear
            mountOnEnter
            classNames={{
              exitActive: "translate-x-8",
              exitDone: "translate-x-8",
              enterActive: "translate-x-0",
              enterDone: "translate-x-0",
            }}
          >
            <Button
              color="black"
              onClick={() => setOpenUpcomingTasks(true)}
              className={clsx(
                "fixed right-[-60px] top-20 z-20 !hidden -rotate-90 duration-300 ease-linear md:!inline-block",
                {
                  "delay-300": !openUpcomingTasks,
                }
              )}
            >
              Show Schedule
            </Button>
          </CSSTransition>
          <UpcomingTasks
            slideIn={openUpcomingTasks}
            onClose={() => setOpenUpcomingTasks(false)}
          />
        </>
      )}
      {!isSmDown && (
        <TopHeadingBar
          heading="Learning"
          endSlot={
            <Button
              component={Link}
              to="/discussions"
              startAdornment={<Message />}
              variant="text"
            >
              Discussions
            </Button>
          }
        />
      )}
      <Container className="h-full">
        {isLoading && <FullPageLoading />}
        {isSuccess && (
          <>
            {isLastItemSuccess && shouldShowLastAccessedItem && (
              <ResumeCourseCard
                className="mt-3"
                item={lastAccessedItem}
                progress={lastAccessedItem.progress}
              />
            )}
            {data.length > 0 ? (
              <>
                <Typography variant="title2" className="my-2 sm:mt-3">
                  My Learning
                </Typography>
                <ItemList className="mb-4 overflow-hidden !py-0 sm:mb-[45px] md:mb-6">
                  {data
                    .filter(item => {
                      const restrictionType = learningRestrictions?.find(
                        i => i.learningItem.slug === item.slug
                      )?.restrictionType

                      if (shouldShowLastAccessedItem && !restrictionType)
                        return (
                          item.learningItemId !==
                          lastAccessedItem.learningItemId
                        )
                      return true
                    })
                    .map(item => {
                      const restrictionType = learningRestrictions?.find(
                        i => i.learningItem.slug === item.slug
                      )?.restrictionType

                      const currentItemNumber = item.progress
                        ? item.progress.completedUserPlannerItems
                        : 0
                      const totalUserPlannerItems = item.progress
                        ? item.progress.totalUserPlannerItems
                        : 0

                      const fallbackImage =
                        item.learningItemType === "qualification" ||
                        item.learningItemType === "certification"
                          ? CertificationFallback
                          : CourseFallback

                      return (
                        <ItemRow
                          key={item.uuid}
                          component={
                            restrictionType !== RestrictionType.REVOKED
                              ? Link
                              : undefined
                          }
                          onClick={() => {
                            console.log("library_item_clicked")
                            posthog.capture("library_item_clicked", {
                              slug: item.slug,
                            })
                          }}
                          to={
                            restrictionType !== RestrictionType.REVOKED
                              ? `/${item.learningItemType}/${item.slug}/overview/`
                              : undefined
                          }
                          disabled={!!restrictionType}
                          infoSlot={
                            <div className="flex grow items-center">
                              <div className="relative me-2 shrink-0 overflow-hidden rounded-xl">
                                {restrictionType && (
                                  <div className="absolute left-0 top-0 z-10 flex size-full items-center justify-center bg-onSurface-800/50 text-white ">
                                    <div className="flex size-5 items-center justify-center rounded-lg bg-onSurface-900/80 ">
                                      {getRestrictionIcon(restrictionType)}
                                    </div>
                                  </div>
                                )}
                                <img
                                  alt="course"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null // prevents looping
                                    currentTarget.src = fallbackImage
                                  }}
                                  src={item.image || fallbackImage}
                                  className="h-[86px] border border-surface-100 object-cover"
                                  width={130}
                                />
                              </div>
                              <CourseInfoAndProgress
                                courseCode={item.courseCode}
                                barColor={restrictionType ? "black" : undefined}
                                showCount={item.learningItemType === "course"}
                                currentItemNumber={currentItemNumber}
                                numberOfItems={totalUserPlannerItems}
                                title={item.name}
                              />
                            </div>
                          }
                          actionSlot={() => {
                            if (isXs) return null
                            if (restrictionType === RestrictionType.REVOKED) {
                              return (
                                <Button
                                  component={Link}
                                  to={generateModuleAccessRequestLink({
                                    modules: [item.name],
                                    type: restrictionType,
                                  })}
                                  variant="text"
                                >
                                  Contact Us
                                </Button>
                              )
                            }
                            return (
                              <Button variant="text">
                                View {capitalize(item.learningItemType)}
                              </Button>
                            )
                          }}
                        />
                      )
                    })}
                </ItemList>
              </>
            ) : (
              <>
                <Container
                  className="pb-10"
                  fullWidth={isSmDown && data.length === 0}
                >
                  <Fallback
                    data={{
                      title: "No active courses!",
                      description:
                        "Looks like you don’t have any active courses. Head to the store and check out our courses to get started on your upskilling Journey!",
                      image: Monitor,
                    }}
                    className="my-6 px-2 sm:my-20 sm:px-0 md:my-8"
                    hasError={false}
                  >
                    <TalkToMentorBanner />
                  </Fallback>
                </Container>
              </>
            )}
          </>
        )}
        {isError && (
          <Fallback
            data={{
              title: "Something went wrong!",
              description: "Please contact care@suraasa.com",
              image: Monitor,
            }}
            className="my-6 px-2 sm:my-20 sm:px-0 md:my-8"
          />
        )}

        {data && data.length > 0 && <ExploreCoursesOnStoreBar />}
      </Container>
    </div>
  )
}

export default Library

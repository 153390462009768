import {
  Avatar,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Typography,
} from "@suraasa/placebo-ui"
import {
  Container,
  IconButton,
  Theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import AISparkle from "assets/icons/system/AISparkle"
import Bell from "assets/icons/system/Bell"
import BellRing from "assets/icons/system/BellRing"
import BookClosed from "assets/icons/system/BookClosed"
import BookOpenNew from "assets/icons/system/BookOpen"
import HomeLine from "assets/icons/system/HomeLine"
import HomeSmile from "assets/icons/system/HomeSmile"
import JobsSearch from "assets/icons/system/JobsSearch"
import ITOLogoGrey from "assets/logos/ito-mini-grey.svg"
import clsx from "clsx"
import { useExcludeShadow } from "components/Navigation/hooks/useExcludeShadow"
import Link, {
  EXPLORE_MENU_OPENED_EVENT_NAME,
  NavBackground,
} from "components/Navigation/Link"
import { HOME_ONBOARDING_TARGETS } from "features/Home/HomeOnboarding/constants"
import { useNotifications } from "features/Notifications/hooks/useNotifications"
import NotificationsPopup from "features/Notifications/NotificationsPopup"
import { motion } from "framer-motion"
import { context } from "global/Context/context"
import { useFeatureToggle } from "global/FeatureToggleProvider"
import {
  Book,
  Journal,
  MediaVideoList,
  Message,
  MicrophoneSpeaking,
  Shop,
  Suitcase,
  ViewGrid,
} from "iconoir-react"
import { useContext, useEffect, useRef, useState } from "react"
import { useTheme } from "react-jss"
import {
  Link as ReactRouterLink,
  matchPath,
  useLocation,
  useMatch,
} from "react-router-dom"
import routes from "routes"
import { buildUserName, getPlatformURL } from "utils/helpers"
import { useEnrollments } from "utils/hooks/useEnrollments"
import { trackingService } from "utils/tracking"

import { ReactComponent as ItoLogo } from "../assets/OlympiadLogo.svg"
import ProfileMenuButton from "./ProfileMenuButton"
import Sidebar from "./Sidebar"
import WithoutAuth from "./WithoutAuth"

const moreLinks = [
  {
    key: "ito",
    name: "ITO 2022",
    link: routes.ito2022,
    icon: ItoLogo,
  },
  {
    key: "learnerHandbook",
    name: "Learner's Handbook",
    link: routes.learnerHandbook,
    icon: Book,
  },
  {
    name: "Store",
    link: getPlatformURL("suraasa", "/store"),
    icon: Shop,
  },
  {
    name: "Masterclasses",
    link: getPlatformURL("suraasa", "/masterclasses"),
    icon: MediaVideoList,
  },
  {
    name: "Blogs",
    link: getPlatformURL("suraasa", "/blog"),
    icon: Journal,
  },
  {
    name: "Podcasts",
    link: "https://podcasters.spotify.com/pod/show/suraasa",
    icon: MicrophoneSpeaking,
  },
]

type Props = {
  showSignInButton?: boolean
}

const TopNavigation = ({ showSignInButton = true }: Props) => {
  const theme = useTheme<Theme>()
  const mdUp = useMediaQuery(theme.breakpoints.up("md"))
  const location = useLocation()
  const { pageOverview } = useNotifications({
    useSearchParams: false,
    enableNotificationList: false,
  })
  const isAssignmentPage = useMatch(routes.assignment)

  const { data: notificationOverviewData } = pageOverview

  const featureToggle = useFeatureToggle()

  const isAIToolsEnabled = featureToggle.aiTools.isEnabled

  const [openSheet, setOpenSheet] = useState(false)
  const [isMoreTabActive, setMoreTabActive] = useState(false)

  const navContainerRef = useRef<HTMLDivElement>(null)

  const omitShadow = useExcludeShadow()
  const {
    pgctl: { isPGCTLUser },
  } = useEnrollments()

  const { authInfo, itoParticipationDetails, profile } = useContext(context)

  const hasNotification =
    notificationOverviewData && notificationOverviewData.all > 0

  const userName = authInfo ? buildUserName(authInfo.user) : ""

  const matchPathResult = matchPath(
    {
      path: routes.learning + routes.course,
    },
    location.pathname
  )

  const toggleSheet = () => {
    setOpenSheet(!openSheet)
  }

  useEffect(() => {
    const event = new Event(EXPLORE_MENU_OPENED_EVENT_NAME)
    window.dispatchEvent(event)
  }, [isMoreTabActive])

  if (!authInfo) {
    return <WithoutAuth showSignInButton={showSignInButton} />
  }

  if (!mdUp) {
    return (
      <>
        <div
          id="topNavbar"
          className={clsx(
            "top-0 z-navbar flex items-center justify-between bg-white px-2 py-1",
            { sticky: !isAssignmentPage }
          )}
          style={{
            boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
            transition: "top 0.3s",
          }}
        >
          <div
            className={clsx(
              "flex shrink-0 items-center",
              HOME_ONBOARDING_TARGETS.profile
            )}
          >
            <button onClick={toggleSheet}>
              <Avatar
                className={clsx("size-4 !border border-onSurface-200")}
                src={profile.picture}
                name={userName}
              />
            </button>
          </div>
          <div>
            {location.pathname === routes.learning && (
              <IconButton
                variant="plain"
                color="black"
                component={ReactRouterLink}
                to="/discussions"
              >
                <Message />
              </IconButton>
            )}
            {matchPathResult && (
              <IconButton
                variant="plain"
                color="black"
                component={ReactRouterLink}
                to={`/discussions/?course=${matchPathResult.params.slug}`}
              >
                <Message />
              </IconButton>
            )}

            <Sidebar open={openSheet} handleClose={toggleSheet} />
          </div>
        </div>
      </>
    )
  }

  const moreLinksToShow = moreLinks
    // Don't show Ai Tools route if disabled
    .filter(x => (isAIToolsEnabled ? true : x.key !== "aiTools")) // Don't show ITO links to non-participants
    .filter(x =>
      itoParticipationDetails.pastYears.isITO2022Participant
        ? true
        : x.key !== "ito"
    )
    .filter(x => (x.key === "learnerHandbook" ? isPGCTLUser : true))

  return (
    <nav
      id="topNavbar"
      className={clsx(
        "top-0 z-navbar border-b border-surface-200 bg-white py-0.25",
        {
          sticky: !isAssignmentPage,
        }
      )}
      style={{
        boxShadow: omitShadow ? "initial" : "1px 1px 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Container>
        <div className="flex items-center justify-between">
          <ReactRouterLink to={routes.home}>
            <img
              src="https://assets.suraasa.com/logos/v2/mini.webp"
              alt="suraasa logo"
              height={35}
              width={35}
              className="block md:hidden"
            />
            <img
              src="https://assets.suraasa.com/logos/v2/full-dark.webp"
              alt="suraasa logo"
              width={120}
              className="hidden md:block"
            />
          </ReactRouterLink>
          <div className="flex w-full justify-center">
            {mdUp ? (
              <div
                className="relative flex items-center justify-between gap-2"
                ref={navContainerRef}
              >
                <NavBackground containerRef={navContainerRef} />
                <Link
                  href={routes.home}
                  text="Home"
                  icon={<HomeLine />}
                  activeIcon={<HomeSmile />}
                />
                <Link
                  href={routes.learning}
                  text="Learning"
                  icon={<BookClosed />}
                  activeIcon={<BookOpenNew className="mt-0.25" />}
                />
                <DropdownMenu
                  open={isMoreTabActive}
                  onOpenChange={setMoreTabActive}
                >
                  <DropdownMenuTrigger>
                    <Typography
                      variant={"button"}
                      className={clsx(
                        "flex h-3.5 items-center justify-center gap-0.5 rounded-xl px-1 py-0.5 text-onSurface-500 hover:outline hover:outline-primary-100",
                        {
                          "!bg-primary-50 text-primary-600": isMoreTabActive,
                        }
                      )}
                    >
                      <motion.span
                        className="mt-0.5"
                        initial={{ x: -10, opacity: 0 }}
                        animate={{
                          x: isMoreTabActive ? 0 : 10,
                          opacity: isMoreTabActive ? 1 : 0,
                        }}
                        transition={{ duration: 0.3, ease: "easeOut" }}
                      >
                        {isMoreTabActive && (
                          <ViewGrid className="mb-0.5 !size-2.25" />
                        )}
                      </motion.span>
                      Explore
                    </Typography>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuGroup>
                      {moreLinksToShow.map(route => (
                        <ReactRouterLink to={route.link} key={route.name}>
                          <DropdownMenuItem
                            onClick={() => {
                              trackingService.trackEvent(
                                "nav_more_link_clicked",
                                {
                                  name: route.name,
                                }
                              )
                            }}
                            startAdornment={route.icon ? <route.icon /> : null}
                          >
                            <Typography>{route.name}</Typography>
                          </DropdownMenuItem>
                        </ReactRouterLink>
                      ))}
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
                <Link
                  href={routes.aiTools.home}
                  className={HOME_ONBOARDING_TARGETS.aiTools}
                  text="AI Tools"
                  icon={
                    <AISparkle
                      className="h-3"
                      height="20"
                      viewBox="0 0 20 20"
                    />
                  }
                />
                <Link
                  href={getPlatformURL("jobs", "/")}
                  text="Jobs"
                  icon={<Suitcase />}
                  activeIcon={<JobsSearch />}
                  className={HOME_ONBOARDING_TARGETS.jobs}
                />
                {itoParticipationDetails.registrationStatus === "completed" && (
                  <Link
                    href={routes.ito}
                    text="ITO"
                    icon={
                      <img
                        src={ITOLogoGrey}
                        height={22}
                        width={22}
                        alt="ito"
                        className="mb-[2.5px]"
                      />
                    }
                    activeIcon={
                      <img
                        src="https://assets.suraasa.com/ito/logos/ito-original-mini.svg"
                        height={22}
                        width={22}
                        alt="ito"
                        className="mb-[2.5px]"
                      />
                    }
                  />
                )}
              </div>
            ) : (
              <Link
                href="/home"
                text="Home"
                icon={<HomeLine />}
                activeIcon={<HomeSmile />}
              />
            )}
          </div>
          {mdUp && (
            <div className="flex items-center">
              <div className={clsx({ relative: hasNotification })}>
                <Link
                  isMenu
                  arrow={false}
                  isNotification
                  hasNotification={hasNotification}
                  text="Notifications"
                  icon={<Bell />}
                  activeIcon={<BellRing />}
                  menuChildren={<NotificationsPopup />}
                />
                {hasNotification && (
                  <span className="absolute left-2.5 top-0 z-2 inline-flex justify-center rounded-full bg-critical-500 px-0.5 text-xs font-semibold text-common-white-500">
                    {notificationOverviewData.all}
                  </span>
                )}
              </div>

              <ProfileMenuButton
                coverStyle={profile.coverStyle}
                src={profile.picture}
                name={userName}
              />
            </div>
          )}
        </div>
      </Container>
    </nav>
  )
}
export default TopNavigation

import {
  Button,
  CircularProgress,
  Sheet,
  SheetContent,
  SheetHeader,
  Typography,
} from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { APIError } from "api/utils"
import ShareSheet from "components/ShareSheet"
import { ReactComponent as CertificateFrame } from "features/LearningModule/assets/certificate-frame.svg"
import { ReactComponent as CertificateStamp } from "features/LearningModule/assets/certificate-stamp.svg"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { downloadFile, getPlatformURL, handleErrors } from "utils/helpers"
import { useEnrollments } from "utils/hooks/useEnrollments"

interface Props {
  open: boolean
  handleClose: () => void
}

const CourseCompletionSheet = ({ open, handleClose }: Props) => {
  const { slug } = useParams() as { slug: string }
  const { enrollments } = useEnrollments()

  const course = enrollments?.find(item => item.slug === slug)
  const courseId = course?.id ?? null

  const [shareSheet, setShareSheet] = useState(false)

  const { data } = useQuery({
    enabled: open && courseId !== null,
    queryFn: () =>
      api.profile.credentials.getItemCertificate({
        urlParams: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          courseId: courseId!,
        },
      }),
    queryKey: queries.profile.getItemCertificate(courseId).queryKey,
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  return (
    <>
      <Sheet open={open} onOpenChange={handleClose}>
        <SheetContent height={90}>
          <SheetHeader></SheetHeader>
          {data ? (
            <>
              <div className="absolute left-1/2 top-[45%] z-2 -translate-x-1/2 -translate-y-1/2">
                <Typography
                  variant="title1"
                  className="mb-1 mt-9 text-center"
                  style={{ fontFamily: "Nicholas-Bold" }}
                >
                  Congratulations!
                </Typography>
                <Typography
                  variant="largeBody"
                  className="mb-3 text-center sm:mb-5"
                >
                  You have successfully completed {course?.name}
                </Typography>
                <div className="relative flex justify-center gap-1">
                  <CertificateStamp
                    /**
                     * These width and height percentages are calculated from
                     * <CertificateFrame/> min height and width
                     */
                    width="99.69%"
                    height="99.69%"
                    // height="72.11%"
                    className="absolute left-[49.1%] top-[41.5%] z-4 hidden max-h-[332.34px] max-w-[332.95px] opacity-10 md:block"
                  />
                  <CertificateFrame
                    height="100%"
                    className="relative max-h-[334px] min-h-[246.57px] w-[300px] min-w-[184px] max-w-[447px] drop-shadow-[-5px_8px_9px_rgba(0,0,0,0.25)] sm:w-full"
                  />
                  <img
                    alt="course certificate"
                    src={data.highResImage || data.lowResImage}
                    className="absolute left-1/2 top-1/2 z-3 max-h-[264.66px] max-w-[373.13px] -translate-x-1/2 -translate-y-1/2"
                    /**
                     * These width and height percentages are calculated from
                     * <CertificateFrame/> min height and width
                     */
                    width="88.176%"
                    height="83.47 %"
                  />
                </div>
                <div className="absolute z-5 mt-5 flex w-full justify-center gap-1">
                  <Button
                    className="!rounded-full"
                    onClick={() => downloadFile(data.pdfFile, "Certificate")}
                  >
                    Download Certificate
                  </Button>

                  <Button
                    className="!rounded-full"
                    onClick={() => {
                      setShareSheet(true)
                    }}
                  >
                    Share
                  </Button>
                </div>
                <div className="min-h-[100px]"></div>
              </div>
              <svg
                className="absolute bottom-0 z-1"
                width="100%"
                height="410"
                viewBox="0 0 965 410"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="482" cy="964.172" r="964" fill="#F5FAFF" />
              </svg>
            </>
          ) : (
            <div className="mt-[30%] flex items-center justify-center">
              <CircularProgress />
            </div>
          )}
        </SheetContent>
      </Sheet>
      <ShareSheet
        open={shareSheet}
        handleClose={() => setShareSheet(false)}
        data={{
          url: getPlatformURL("suraasa", `/verify/${data?.serialNumber}`),
        }}
      />
    </>
  )
}

export default CourseCompletionSheet

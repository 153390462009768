import "@szhsin/react-menu/dist/index.css"
import "@szhsin/react-menu/dist/transitions/slide.css"

import { Typography } from "@suraasa/placebo-ui"
import { Menu, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { ControlledMenu, MenuButton, useClick } from "@szhsin/react-menu"
import clsx from "clsx"
import { motion } from "framer-motion"
import { context } from "global/Context/context"
import { NavArrowDown } from "iconoir-react"
import {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { NavLink, useLocation, useMatch } from "react-router-dom"
import routes from "routes"

import styles from "./Link.module.css"

type LinkProps = {
  icon: React.ReactNode
  activeIcon?: React.ReactElement
  text?: string
  className?: string
} & (
  | {
      href?: never
      isMenu: true
      arrow?: boolean
      menuChildren: React.ReactNode
      isNotification?: boolean
      hasNotification?: boolean
    }
  | {
      href: string
      isMenu?: false
      menuChildren?: never
      isNotification?: false
      hasNotification?: never
    }
)

interface NavBackgroundProps {
  containerRef: RefObject<HTMLDivElement>
  onUpdatePosition?: (updateFn: () => void) => void
}

export const EXPLORE_MENU_OPENED_EVENT_NAME = "explore-menu-opened"

export const NavBackground = ({ containerRef }: NavBackgroundProps) => {
  const location = useLocation()
  const [activeStyle, setActiveStyle] = useState({ left: 0, width: 0 })
  const navBgRef = useRef<HTMLDivElement>(null)

  const updatePosition = useCallback(() => {
    const container = containerRef.current
    if (!container) return

    const requestId = requestAnimationFrame(() => {
      const activeLink = container.querySelector('[data-active="true"]')
      if (!activeLink) {
        setActiveStyle(p => ({ left: p.left, width: 0 }))
        return
      }

      const { left, width } = activeLink.getBoundingClientRect()
      const containerLeft = container.getBoundingClientRect().left

      setActiveStyle({ left: left - containerLeft, width })
    })

    return requestId
  }, [containerRef])

  useEffect(() => {
    const requestId = updatePosition()
    window.addEventListener("resize", updatePosition)

    return () => {
      window.removeEventListener("resize", updatePosition)

      if (requestId) {
        cancelAnimationFrame(requestId)
      }
    }
  }, [location.pathname, updatePosition])

  useEffect(() => {
    const requestId = updatePosition()
    window.addEventListener(
      EXPLORE_MENU_OPENED_EVENT_NAME,
      updatePosition,
      false
    )

    return () => {
      window.removeEventListener(
        EXPLORE_MENU_OPENED_EVENT_NAME,
        updatePosition,
        false
      )

      if (requestId) {
        cancelAnimationFrame(requestId)
      }
    }
  }, [updatePosition])

  return (
    <div
      ref={navBgRef}
      className="absolute h-3.5 rounded-xl bg-primary-50 transition-all duration-300"
      style={{
        left: `${activeStyle.left}px`,
        width: `${activeStyle.width}px`,
        opacity: activeStyle.width ? 1 : 0,
      }}
    />
  )
}

const Link = ({
  menuChildren,
  icon,
  activeIcon,
  text,
  href,
  className,
  isNotification = false,
  hasNotification = false,
  ...props
}: LinkProps) => {
  const is2XS = useMediaQuery("@media only screen and (max-width: 500px)")
  const notificationRouteMatch = useMatch(routes.notifications)
  const btnRef = useRef(null)
  const linkRef = useRef(null)

  const [menuState, toggleMenu] =
    useContext(context).notificationsPopupMenuState

  const anchorProps = useClick(menuState.state, toggleMenu)

  if (is2XS) {
    text = ""
  }

  if (props.isMenu && isNotification) {
    const active =
      menuState.state === "open" ||
      menuState.state === "opening" ||
      notificationRouteMatch

    return (
      <>
        <div>
          <button
            ref={btnRef}
            type="button"
            {...anchorProps}
            className={clsx(
              styles.link,
              "mr-1 !min-w-fit rounded-lg px-1 py-0.75 !text-center text-onSurface-500 hover:bg-onSurface-100",
              { "text-primary-500 !bg-primary-50": active }
            )}
          >
            <div
              className={clsx(
                "active-icon absolute flex items-center pl-0.25",
                {
                  active: active,

                  "top-1": hasNotification,
                  "top-1.5": !hasNotification,
                }
              )}
            >
              {activeIcon}
            </div>
            <div
              className={clsx("icon pl-0.25", {
                active: active,
              })}
            >
              {icon}
            </div>
          </button>
        </div>

        <ControlledMenu
          className={clsx(styles.menuWrapper, className, {
            [styles.isNotification]: isNotification,
          })}
          {...menuState}
          anchorRef={btnRef}
          onClose={() => toggleMenu(false)}
          position="anchor"
        >
          {menuChildren}
        </ControlledMenu>
      </>
    )
  }

  if (props.isMenu) {
    if (props.arrow === undefined) {
      props.arrow = true
    }
    return (
      <div
        className={clsx(styles.menuWrapper, className, {
          [styles.isNotification]: isNotification,
        })}
      >
        <Menu
          menuButton={({ open }) => {
            const active = open || (notificationRouteMatch && isNotification)
            return (
              <MenuButton
                className={clsx(
                  styles.link,
                  "flex h-full flex-col items-center justify-between px-1.5 py-0.75 text-onSurface-500"
                )}
              >
                <div
                  className={clsx("active-icon absolute top-1", {
                    active: active,
                    "text-primary-500": active,
                  })}
                >
                  {activeIcon ?? icon}
                </div>
                <div
                  className={clsx("icon", {
                    active: active,
                  })}
                >
                  {icon}
                </div>
                {text && (
                  <p
                    className={clsx("flex text-sm font-semibold", {
                      "text-primary-500": active,
                    })}
                  >
                    <span className="me-0.25">{text}</span>
                    {props.arrow && <NavArrowDown />}
                  </p>
                )}
              </MenuButton>
            )
          }}
        >
          {menuChildren}
        </Menu>
      </div>
    )
  }

  if (!href) throw new Error("Link supplied without href")

  return (
    <NavLink to={href}>
      {({ isActive }) => (
        <motion.div
          ref={linkRef}
          className={clsx(
            styles.link,
            "relative flex items-center rounded-xl text-onSurface-500",
            {
              "text-primary-600 hover:outline hover:outline-primary-100":
                isActive,
              "justify-center pt-0.5 !outline-none": is2XS,
            },
            className
          )}
          data-active={isActive ? "true" : "false"}
        >
          {text && (
            <Typography
              variant={"button"}
              className={clsx(
                "flex items-center justify-center rounded-xl px-1 hover:py-0.5",
                {
                  "hover:py-0 gap-0.5": isActive,
                  "hover:outline hover:outline-primary-100": !isActive,
                  "!outline-none": is2XS,
                }
              )}
            >
              <motion.span
                className="mt-0.5"
                initial={{ x: -10, opacity: 0 }}
                animate={{ x: isActive ? 0 : 10, opacity: isActive ? 1 : 0 }}
                transition={{ duration: 0.3, ease: "easeOut" }}
              >
                {isActive && (activeIcon || icon)}
              </motion.span>
              {text}
            </Typography>
          )}
          {is2XS && (isActive ? activeIcon || icon : icon)}
        </motion.div>
      )}
    </NavLink>
  )
}

export default Link

import { Container, Typography } from "@suraasa/placebo-ui"
import { context } from "global/Context/context"
import React, { useContext } from "react"
import { useLocation } from "react-router-dom"

import VerifyEmailButton from "../ContactDetails/VerifyEmailButton"

const BannerContent = () => {
  return (
    <div className="flex flex-wrap items-center justify-between gap-0.5 py-1">
      <Typography variant="smallBody" className="text-highlight-900">
        Please verify your email to get the best Suraasa experience
      </Typography>
      <VerifyEmailButton />
    </div>
  )
}

const UnverifiedEmailBanner = () => {
  const { pathname } = useLocation()

  const { emails, isAuthenticated } = useContext(context)
  const primaryEmail = emails.find(email => email.isPrimary)

  const isVerified = primaryEmail && primaryEmail.isVerified

  // We don't want to show the banner while isVerified is undefined (That means its still loading)
  if (!isAuthenticated || isVerified === undefined || isVerified) return null
  if (pathname.includes("/learning/course")) return null

  return (
    <div className="bg-highlight-50">
      <Container>
        <BannerContent />
      </Container>
    </div>
  )
}

export default UnverifiedEmailBanner

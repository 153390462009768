import { Button, Container } from "@suraasa/placebo-ui-legacy"
import { useExcludeShadow } from "components/Navigation/hooks/useExcludeShadow"
import { Link } from "react-router-dom"
import routes from "routes"
import { redirectToLogin } from "utils/helpers"

type Props = {
  showSignInButton?: boolean
}

const WithoutAuth = ({ showSignInButton = true }: Props) => {
  const omitShadow = useExcludeShadow()

  return (
    <nav
      id="topNavbar"
      className="sticky top-0 z-navbar border-b border-surface-200 bg-white"
      style={{
        boxShadow: omitShadow ? "initial" : "1px 1px 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Container>
        <div className="flex items-center justify-between py-0.75">
          <Link to={routes.home}>
            <img
              src="https://assets.suraasa.com/logos/v2/mini.webp"
              alt="suraasa logo"
              width={40}
              height={40}
            />
          </Link>

          {showSignInButton && (
            <div>
              <Button onClick={redirectToLogin} variant="text">
                Sign In
              </Button>
            </div>
          )}
        </div>
      </Container>
    </nav>
  )
}

export default WithoutAuth

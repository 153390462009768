import { Typography } from "@suraasa/placebo-ui"
import { Button, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import HandHoldingPen from "assets/Fallback/hand-holding-pen.svg"
import Monitor from "assets/Fallback/monitor.svg"
import CourseFallback from "assets/Placeholder/course_placeholder.svg"
import clsx from "clsx"
import Fallback from "components/Fallback"
import ItemList from "components/ItemList"
import ItemRow from "components/ItemRow"
import CheckRestrictions, {
  getRestriction,
  getRestrictionIcon,
} from "features/AccessManagement/CheckRestrictions"
import CourseInfoAndProgress from "features/Library/CourseInfoAndProgress"
import { context } from "global/Context/context"
import { Lock } from "iconoir-react"
import groupBy from "lodash/groupBy"
import { LockIcon } from "lucide-react"
import { useContext, useMemo } from "react"
import { useTheme } from "react-jss"
import { Link, useParams } from "react-router-dom"
import routes from "routes"
import { useEnrollments } from "utils/hooks/useEnrollments"

import { useQualificationDetails } from "../../"
import { getItemTerm } from "./utils"

const Modules = ({ slug }: { slug: string }) => {
  const { learningItemType } = useParams() as {
    learningItemType: string
  }

  const {
    pgctl: { showTermBreakdown },
  } = useEnrollments()

  const { learningRestrictions } = useContext(context)
  const restrictionType = learningRestrictions?.find(
    i => i.learningItem.slug === slug
  )?.restrictionType

  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  const { qualification } = useQualificationDetails()

  const {
    isLoading,
    isError,
    data: plannerList,
  } = useQuery({
    enabled: Boolean(qualification.id),
    queryFn: () =>
      api.learningItems.getPlannerList({
        params: {
          learningItemType: learningItemType,
          learningItemId: qualification.id,
        },
      }),
    queryKey: queries.learningItems.listPlanners(qualification.id).queryKey,
  })

  const termWisePlanner = useMemo(() => {
    if (!plannerList) return {}

    return groupBy(
      plannerList.map(x => {
        x.term = getItemTerm(x.slug)
        return x
      }),
      "term"
    )
  }, [plannerList])

  if (isError || isLoading) {
    return (
      <div>
        <Typography variant="title3" className="mb-1">
          Modules
        </Typography>
        <Fallback
          data={{
            title: "Something went wrong!",
            description: "Please contact care@suraasa.com",
            image: Monitor,
          }}
          className="my-6 px-2 sm:my-20 sm:px-0 md:my-8"
          hasError={isError}
          isLoading={isLoading}
        />
      </div>
    )
  }

  return (
    <div>
      <Typography variant="title3" className="mb-1">
        Modules
      </Typography>

      {plannerList.length > 0 && showTermBreakdown && (
        <div>
          {Object.entries(termWisePlanner).map(([termName, modules]) => (
            <div key={termName} className="mb-2">
              <Typography variant="strong">{termName}</Typography>
              {modules.every(item => item.status === "locked") && (
                <Typography variant="smallBody" className="text-muted">
                  This term will unlock after you complete the previous term
                </Typography>
              )}

              <ItemList className={clsx("mt-0.5 overflow-hidden !py-0")}>
                {modules.map(item => {
                  const isLocked = item.status === "locked"
                  const isRestricted = getRestriction(item)

                  return (
                    <ItemRow
                      disabled={isRestricted}
                      key={item.uuid}
                      component={isLocked || isRestricted ? "div" : Link}
                      to={
                        isLocked || isRestricted
                          ? undefined
                          : routes.parentItemCourse
                              .replace(":learningItemType", learningItemType)
                              .replace(":slug", item.slug)
                              .replace(":parentSlug", slug)
                      }
                      infoSlot={
                        <div className="flex grow items-center">
                          <div className="relative me-2 shrink-0 overflow-hidden rounded-xl">
                            {restrictionType && (
                              <div className="absolute left-0 top-0 z-10 flex size-full items-center justify-center bg-onSurface-800/50 text-white">
                                <div className="flex size-5 items-center justify-center rounded-lg bg-onSurface-900/80 ">
                                  {getRestrictionIcon(restrictionType)}
                                </div>
                              </div>
                            )}
                            {!restrictionType && isLocked && (
                              <div className="absolute left-0 top-0 z-10 flex size-full items-center justify-center bg-onSurface-800/50 text-white">
                                <div className="flex size-5 items-center justify-center rounded-lg bg-onSurface-900/80 ">
                                  {<LockIcon />}
                                </div>
                              </div>
                            )}

                            <img
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null // prevents looping
                                currentTarget.src = CourseFallback
                              }}
                              alt="course"
                              src={item.image || CourseFallback}
                              className={clsx(
                                "h-[86px] border border-surface-100 object-cover",
                                {
                                  grayscale: !!restrictionType,
                                }
                              )}
                              width={144}
                            />
                          </div>
                          <CourseInfoAndProgress
                            showCount={item.learningItemType === "course"}
                            title={item.name}
                            courseCode={item.courseCode}
                            barColor={isRestricted ? "black" : undefined}
                            currentItemNumber={
                              item.progress
                                ? item.progress.completedUserPlannerItems
                                : 0
                            }
                            numberOfItems={
                              item.progress
                                ? item.progress.totalUserPlannerItems
                                : 0
                            }
                          />
                        </div>
                      }
                      actionSlot={() => {
                        if (isXs) return null
                        return (
                          <CheckRestrictions
                            accessStatus={item.accessStatus}
                            render={({ isRestricted }) =>
                              isLocked || isRestricted ? (
                                <Lock />
                              ) : (
                                <Button variant="text">View</Button>
                              )
                            }
                          />
                        )
                      }}
                    />
                  )
                })}
              </ItemList>
            </div>
          ))}
        </div>
      )}

      {plannerList.length > 0 && !showTermBreakdown && (
        <ItemList className={clsx("overflow-hidden !py-0")}>
          {plannerList.map(item => {
            const isLocked = item.status === "locked"
            const isRestricted = getRestriction(item)

            return (
              <ItemRow
                disabled={isRestricted}
                key={item.uuid}
                component={isLocked || isRestricted ? "div" : Link}
                to={
                  isLocked || isRestricted
                    ? undefined
                    : routes.parentItemCourse
                        .replace(":learningItemType", learningItemType)
                        .replace(":slug", item.slug)
                        .replace(":parentSlug", slug)
                }
                infoSlot={
                  <div className="flex grow items-center">
                    <div className="relative me-2 shrink-0 overflow-hidden rounded-xl">
                      {restrictionType && (
                        <div className="absolute left-0 top-0 z-10 flex size-full items-center justify-center bg-onSurface-800/50 text-white">
                          <div className="flex size-5 items-center justify-center rounded-lg bg-onSurface-900/80 ">
                            {getRestrictionIcon(restrictionType)}
                          </div>
                        </div>
                      )}

                      <img
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null // prevents looping
                          currentTarget.src = CourseFallback
                        }}
                        alt="course"
                        src={item.image || CourseFallback}
                        className={clsx(
                          "h-[86px] border border-surface-100 object-cover",
                          {
                            grayscale: !!restrictionType,
                          }
                        )}
                        width={144}
                      />
                    </div>
                    <CourseInfoAndProgress
                      showCount={item.learningItemType === "course"}
                      title={item.name}
                      courseCode={item.courseCode}
                      barColor={isRestricted ? "black" : undefined}
                      currentItemNumber={
                        item.progress
                          ? item.progress.completedUserPlannerItems
                          : 0
                      }
                      numberOfItems={
                        item.progress ? item.progress.totalUserPlannerItems : 0
                      }
                    />
                  </div>
                }
                actionSlot={() => {
                  if (isXs) return null
                  return (
                    <CheckRestrictions
                      accessStatus={item.accessStatus}
                      render={({ isRestricted }) =>
                        isLocked || isRestricted ? (
                          <Lock />
                        ) : (
                          <Button variant="text">View</Button>
                        )
                      }
                    />
                  )
                }}
              />
            )
          })}
        </ItemList>
      )}

      {plannerList.length === 0 && (
        <Fallback
          data={{
            image: HandHoldingPen,
            title: "No modules found.",
          }}
          className="mb-3 mt-9 sm:mt-20 md:mt-18"
        />
      )}
    </div>
  )
}

export default Modules

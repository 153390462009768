import {
  Sheet,
  SheetBody,
  SheetContent,
  SheetHeader,
  SheetTitle,
  Typography,
} from "@suraasa/placebo-ui"
import { Assignment } from "api/resources/assignments/types"

type Props = {
  learningOutcomes: Assignment["assignmentAssessmentCriteria"]
  open: boolean
  onClose: () => void
}

const LearningOutcomesSheet = ({ learningOutcomes, open, onClose }: Props) => {
  return (
    <Sheet open={open} onOpenChange={onClose}>
      <SheetContent
        side="right"
        height={100}
        className="w-full !max-w-xl rounded-tr-none"
      >
        <SheetHeader>
          <SheetTitle>
            <Typography variant="title3">
              Assessment Criteria (AC) and Learning Outcomes (LO)
            </Typography>
          </SheetTitle>
        </SheetHeader>
        <SheetBody>
          {learningOutcomes.map((data, index) => (
            <div
              key={index}
              style={{ boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)" }}
              className="mb-2 rounded-lg border border-surface-200 bg-common-white-500 p-2"
            >
              <div className="mb-1 flex items-start">
                <Typography variant="strong" className="me-1 shrink-0">
                  AC
                </Typography>
                <Typography>{data.name}</Typography>
              </div>
              <div className="flex items-start">
                <Typography variant="strong" className="me-1 shrink-0">
                  LO
                </Typography>
                <Typography>{data.learningOutcome.name}</Typography>
              </div>
            </div>
          ))}
        </SheetBody>
      </SheetContent>
    </Sheet>
  )
}

export default LearningOutcomesSheet

import { Typography } from "@suraasa/placebo-ui"
import { useParams } from "react-router-dom"
import { learningItemSlugs } from "utils/constants"

import { useQualificationDetails } from ".."
import AdmissionLetter from "./AdmissionLetter"
import EnrollmentNumber from "./EnrollmentNumber"
import StudentCatalog from "./StudentCatalog"

// const overviewDetails = {
//   professors: [
//     {
//       name: "Prof. David Antler",
//       qualification: "Msc. Physics, Delhi University",
//       photo: "https://picsum.photos/150",
//     },
//     {
//       name: "Prof. David Antler",
//       qualification: "Msc. Physics, Delhi University",
//       photo: "https://picsum.photos/150",
//     },
//   ],
//   description:
//     "Professional Certificate in Teaching and Learning (PgCTL) is a UK Level 6 teaching qualification designed to help teachers accelerate their professional growth and be successful in creating a high impact teaching and learning experience in their classrooms. Professional Certificate in Teaching and Learning (PgCTL) is a UK Level 6 teaching qualification designed to help teachers accelerate their professional growth and be successful in creating a high impact teaching and learning experience in their classrooms. Professional Certificate in Teaching and Learning (PgCTL) is a UK Level 6 teaching qualification designed to help teachers accelerate their professional growth and be successful in creating a high impact teaching and learning experience in their classrooms.",
// }

const QualificationAbout = () => {
  const { slug } = useParams() as { slug: string }
  const { qualification } = useQualificationDetails()

  const isMEd = qualification.slug === learningItemSlugs.med

  return (
    <div>
      <Typography variant="title3" className="mb-2">
        About
      </Typography>
      <div className="flex flex-col gap-1">
        {/* <Overview data={overviewDetails} /> */}
        {/* {isMEd && <MEdDegreeCard />} */}
        <EnrollmentNumber slug={slug} />
        {isMEd && (
          <>
            <AdmissionLetter slug={slug} />
            <StudentCatalog />
          </>
        )}
      </div>
    </div>
  )
}

export default QualificationAbout

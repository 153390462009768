import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Email, OTPResponse } from "api/resources/settings/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import OTPInput from "react-otp-input"
import { handleErrors } from "utils/helpers"

export default function VerifyEmail({
  dialogTitle,
  close,
  open,
  onVerify,
  onBack,
  email,
  ...props
}: {
  close: () => void
  onVerify: (updatedEmail: Email) => void
  onBack?: () => void
  otpData: OTPResponse
  email: string
  open: boolean
  dialogTitle: string
}) {
  const [loading, setLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [otpData, setOtpData] = useState(props.otpData)

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<{ otp: string }>()

  const onSubmit = handleSubmit(async ({ otp }) => {
    setLoading(true)
    try {
      if (otpData) {
        const verificationResponse = await api.settings.verifyOtp({
          data: {
            otp: otp,
            token: otpData.token,
          },
        })
        const res = await api.settings.emails.verify({
          data: {
            token: verificationResponse.token,
          },
        })
        setLoading(false)
        onVerify(res)
      }
    } catch (err) {
      if (err instanceof APIError) {
        handleErrors(err, { setter: setError })
        setLoading(false)
      }
    }
  })

  useEffect(() => {
    if (!open) reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  // const resendOtp = async () => {
  //   try {
  //     const res = await api.settings.sendOTP({
  //       data: {
  //         token: otpData.token,
  //       },
  //     })
  //     toast.info("We've sent you a new OTP")
  //     setOtpData(p => ({ ...p, ...res }))
  //   } catch (e) {
  //     if (e instanceof APIError) {
  //       if (e.errors.fieldErrors?.resendAt && e.errors.fieldErrors?.token) {
  //         setOtpData(p => ({
  //           ...p,
  //           resendAt: (e as APIError).errors.fieldErrors?.resendAt as string,
  //           token: (e as APIError).errors.fieldErrors?.token as string,
  //         }))
  //       }
  //     }
  //   }
  // }

  return (
    <Dialog open={open} onOpenChange={close}>
      <DialogContent className="max-w-[400px]">
        <DialogHeader>
          <DialogTitle>{dialogTitle}</DialogTitle>
        </DialogHeader>
        <form onSubmit={onSubmit}>
          <DialogBody>
            <div className="p-1">
              <Typography>
                OTP sent to <b>{email}</b>
              </Typography>
              {onBack && (
                <Button
                  variant="text"
                  className="mt-0.5 text-left"
                  onClick={onBack}
                >
                  Change Email
                </Button>
              )}

              <div className="mt-2">
                <Controller
                  control={control}
                  name="otp"
                  render={({ field }) => (
                    <OTPInput
                      numInputs={6}
                      {...field}
                      containerStyle="justify-between"
                      inputStyle={clsx(
                        "h-[50px] !w-[41px] rounded-[4px] border-2 border-onSurface-500 text-title2 outline-interactive-400 focus:outline-2 focus:outline-offset-1",
                        {
                          "!border-2 !border-critical-500 !text-critical-500":
                            Boolean(errors.otp),
                        }
                      )}
                      inputType="number"
                      renderInput={props => <input {...props} />}
                      shouldAutoFocus
                      onChange={otp => {
                        field.onChange(otp)
                        if (otp.length === 6) {
                          onSubmit()
                        }
                      }}
                    />
                  )}
                  rules={{
                    required: { value: true, message: "Required" },
                  }}
                />
              </div>
              {Boolean(errors.otp) && (
                <Typography
                  className="mt-1 text-critical-500"
                  variant="smallBody"
                >
                  {errors.otp?.message}
                </Typography>
              )}

              {/* <div className="flex flex-wrap items-center mt-3">
              <Typography className="me-1 shrink-0">
                Didn’t receive OTP?
              </Typography>
              <ResendOTPButton
                resendAt={otpData.resendAt}
                text="Send Again"
                onClick={resendOtp}
              />
            </div> */}
            </div>
          </DialogBody>

          <DialogFooter>
            <Button size="sm" type="submit" loading={loading}>
              Verify
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

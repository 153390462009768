import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  TextField,
  toast,
} from "@suraasa/placebo-ui"
import api from "api"
import { Email, OTPResponse } from "api/resources/settings/types"
import { APIError } from "api/utils"
import { useEffect, useState } from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-hook"
import { useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"
import { CaptchaType } from "utils/hooks/useGRecaptcha"
import isEmail from "validator/lib/isEmail"

import VerifyEmail from "../VerifyEmail"

type FormData = { email: string }

export default function AddSecondaryEmail({
  close,
  open,
  onAdd,
}: {
  close: () => void
  open: boolean
  onAdd: (email: Email) => void
}) {
  const [data, setData] = useState<(OTPResponse & { email: string }) | null>()

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormData>()

  const { executeGoogleReCaptcha } = useGoogleReCaptcha(
    import.meta.env.VITE_INVISIBLE_RECAPTCHA_KEY,
    {
      enterprise: true,
    }
  )

  const onSubmit = handleSubmit(async data => {
    const captcha = {
      value: await executeGoogleReCaptcha("auth"),
      type: CaptchaType.invisible,
    }

    if (!captcha) {
      toast.error("Please verify the captcha")
      return
    }
    try {
      const res = await api.settings.emails.create({
        data: { email: data.email, captcha },
      })
      setData({ ...res, email: data.email })
    } catch (err) {
      if (err instanceof APIError) {
        if (err.errors.message) {
          toast.error(err.errors.message)
        }
        if (err.errors.fieldErrors?.email) {
          handleErrors(err, { setter: setError })
        }
      }
    }
  })

  useEffect(() => {
    if (!open) reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <>
      {data && (
        <VerifyEmail
          dialogTitle="Verify Secondary Email"
          onBack={() => setData(null)}
          close={() => {
            setData(null)
            close()
          }}
          email={data.email}
          otpData={data}
          open={Boolean(data)}
          onVerify={updatedEmail => {
            onAdd(updatedEmail)
            toast.success("Email added successfully")
            setData(null)
            close()
          }}
        />
      )}

      <Dialog open={open && !data} onOpenChange={close}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add Email Address</DialogTitle>
          </DialogHeader>
          <form onSubmit={onSubmit}>
            <DialogBody className="mb-2 mt-1">
              <TextField
                autoFocus
                errors={errors.email?.message}
                helperText={errors.email?.message}
                label="Email Address"
                placeholder="Enter Email Address"
                type="email"
                {...register("email", {
                  required: { value: true, message: "Required" },
                  validate: value => isEmail(value) || "Invalid email address",
                })}
              />
            </DialogBody>
            <DialogFooter>
              <Button
                className="border-muted text-muted"
                variant="outlined"
                onClick={close}
                size="sm"
              >
                Cancel
              </Button>
              <Button type="submit" loading={isSubmitting} size="sm">
                Submit
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  )
}

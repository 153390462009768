import { Typography } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { DeliveryMode } from "api/resources/learningItems/types"
import clsx from "clsx"
import SimpleCard from "components/SimpleCard"
import CheckRestrictions from "features/AccessManagement/CheckRestrictions"
import CourseFeedbackCard from "features/CourseFeedbackCard"
import NextUp from "features/LearningItems/Qualification/QualificationOverview/NextUp"
import ResumeCourseCard from "features/Library/ResumeCourseCard"
import { Link, useParams } from "react-router-dom"
import routes from "routes"
import { getAuthInfo, getLearningItemURL, pluralize } from "utils/helpers"
import { useEnrollments } from "utils/hooks/useEnrollments"

import { useCourseDetails } from ".."
import CourseAssessments from "../Assessments"
import Classes from "../Classes"
import RecordedClassesList from "../Classes/RecordedClassesList"
import CompletedCourseCard from "../CompletedCourseCard"
import Curriculum from "../Curriculum"
import { getLearningItemDisplayName } from "./utils"

type Props = {
  className?: string
}

const CourseOverview = ({ className }: Props) => {
  const { slug, parentSlug } = useParams() as {
    slug: string
    parentSlug?: string
  }
  const learningItemType = "course"

  const userName = getAuthInfo()?.user.username
  const { data } = useQuery({
    queryKey: queries.profile.certificateExistence(
      userName,
      learningItemType,
      slug
    ).queryKey,
    queryFn: () =>
      api.profile.credentials.checkExistence({
        urlParams: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          username: userName!,
          itemType: learningItemType,
          itemSlug: slug,
        },
      }),
  })

  const { course, accessStatus } = useCourseDetails()

  const { data: overview } = useQuery({
    queryFn: () =>
      api.learningItems.overview({
        urlParams: {
          itemType: "course",
          itemId: course.id,
        },
      }),
    enabled: Boolean(course.id),
    queryKey: queries.learningItems.overview("course", course.id).queryKey,
  })

  const courseIsCompleted =
    overview?.progress &&
    overview.progress.completedUserPlannerItems ===
      overview.progress.totalUserPlannerItems

  const curriculum = useQuery({
    enabled: !!course.id,
    queryFn: () =>
      api.learningItems.listPlannerItems({
        urlParams: { plannerId: course?.id },
      }),
    queryKey: queries.learningItems.listPlannerItems(course?.id).queryKey,
  })

  const lastAccessedItem = curriculum?.data
    ?.toReversed()
    .filter(x => x.learningContentType !== "pre_recorded_class")
    .find(
      item =>
        item.status === "completed" ||
        item.status === "unlocked" ||
        item.status === "in_progress" ||
        item.status === "incomplete"
    )

  const {
    pgctl: { deliveryMode, isNewDeliveryMode },
  } = useEnrollments()

  return (
    <div className={clsx(className, "flex flex-col gap-4")}>
      {/* This is a container for banners */}
      {data?.serialNumber && (
        <CompletedCourseCard serialNumber={data.serialNumber} />
      )}

      {courseIsCompleted && (
        <CourseFeedbackCard
          itemId={course.id}
          courseName={course.name}
          onClose={() => {
            // sessionStorage.setItem("hasDismissedRateCoursePopup", "true")
          }}
        />
      )}

      {overview && lastAccessedItem && !courseIsCompleted && (
        <CheckRestrictions
          accessStatus={accessStatus}
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          render={_ => (
            // Restriction is already catered inside the component
            <ResumeCourseCard
              href={getLearningItemURL({
                route: parentSlug
                  ? routes.learningModuleWithParent
                  : routes.learningModule,
                itemSlug: slug,
                parentSlug: parentSlug,
                parentType: learningItemType,
                queryParams: { plannerId: course.id.toString() },
              })}
              item={{
                slug,
                name: getLearningItemDisplayName(lastAccessedItem),
                learningItemType,
              }}
              // disabled={isRestricted}
              progress={overview.progress}
            />
          )}
        />
      )}

      <NextUp learningItemType={course.type} learningItemId={course.id} />

      <Curriculum plannerId={course.id} query={curriculum} />

      {isNewDeliveryMode && <CourseAssessments hideIfNoData />}

      {curriculum.isSuccess &&
        deliveryMode &&
        [DeliveryMode.EXECUTIVE, DeliveryMode.PROFESSIONAL].includes(
          deliveryMode
        ) && (
          <RecordedClassesList
            plannerId={course.id}
            slug={slug}
            data={
              curriculum.data.filter(
                x => x.learningContentType === "pre_recorded_class"
              ) || []
            }
          />
        )}

      {deliveryMode && [DeliveryMode.EXECUTIVE].includes(deliveryMode) && (
        <Classes
          heading={
            <Typography variant="title3" className="mb-1">
              Live Classes
            </Typography>
          }
          hideIfNoData
        />
      )}

      {overview && (
        <div>
          <Typography variant="title3" className="mb-1">
            Skill Evidences (Assignments)
          </Typography>
          <SimpleCard className="flex flex-wrap items-center justify-between gap-1 border-interactive-200 bg-interactive-50">
            <Typography variant="title4" className="text-interactive-700">
              {overview.dueAssignments === 0
                ? "No Assignments Due"
                : `${pluralize("Assignment", overview.dueAssignments)} Due`}
            </Typography>

            <Link to="../assignments">
              <Button size="sm" color="interactive">
                View Assignments
              </Button>
            </Link>
          </SimpleCard>
        </div>
      )}
    </div>
  )
}

export default CourseOverview

import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  TextField,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Email } from "api/resources/settings/types"
import { APIError } from "api/utils"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"

const UpdatePrimaryEmail = ({
  email,
  open,
  close,
  onVerify,
}: {
  email: Email | null
  open: boolean
  onVerify: () => void
  close: () => void
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<{ password: string }>()

  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = handleSubmit(async data => {
    if (!email) return

    setLoading(true)
    try {
      await api.settings.emails.update({
        urlParams: {
          id: email.id,
        },
        data: {
          isPrimary: true,
          password: data.password,
        },
      })

      toast.success("Primary email updated.")
      onVerify()
      close()
    } catch (err) {
      if (err instanceof APIError) {
        handleErrors(err, { setter: setError })
      }
    }
    setLoading(false)
  })

  useEffect(() => {
    if (!open) reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])
  if (!email) return null
  return (
    <Dialog open={open} onOpenChange={close}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>New Primary Email</DialogTitle>
        </DialogHeader>
        <form onSubmit={onSubmit}>
          <DialogBody>
            <Typography className="mb-3" variant="smallBody">
              Please enter your password to set <b>{email.email}</b> as your
              primary email
            </Typography>

            <TextField
              errors={errors.password?.message}
              helperText={errors.password?.message}
              placeholder="Enter Password"
              type="password"
              {...register("password", {
                required: { value: true, message: "Required" },
              })}
            />
          </DialogBody>
          <DialogFooter>
            <Button
              className="border-muted text-muted"
              variant="outlined"
              size="sm"
              onClick={close}
            >
              Cancel
            </Button>
            <Button size="sm" loading={loading} onClick={onSubmit}>
              Submit
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default UpdatePrimaryEmail

import {
  Button,
  Container,
  Divider,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import queries from "api/resources/orders/queries"
import { ClientType } from "api/resources/orders/types"
import Monitor from "assets/Fallback/monitor.svg"
import BackButton from "components/BackButton"
import Fallback from "components/Fallback"
import FullPageLoading from "components/FullPageLoading"
import { format } from "date-fns"
import { Link, useParams } from "react-router-dom"
import routes from "routes"

import { getStatusTag } from "../utils/helpers"
import AppPurchase from "./AppPurchase"
import OrderBreakdown from "./OrderBreakdown"
import Payments from "./Payments"

const OrderDetails = () => {
  const { id } = useParams()

  const {
    isLoading,
    data: orderItem,
    isError,
  } = useQuery({
    queryFn: () => api.orders.getOrderDetails({ urlParams: { id: id ?? "" } }),
    queryKey: queries.orderItem(id ?? "").queryKey,
    onError: () => {
      toast.error("Unable to fetch order details.")
    },
  })

  if (isLoading) return <FullPageLoading />
  if (isError)
    return (
      <Fallback
        data={{
          image: Monitor,
          title: "Something went wrong.",
          description: "Please contact care@suraasa.com",
        }}
        className="my-6 sm:my-20 md:my-8"
      />
    )

  const isAppPurchase =
    orderItem.client === ClientType.ANDROID ||
    orderItem.client === ClientType.IOS

  const statusTag = getStatusTag(orderItem.status, orderItem.refundStatus)
  return (
    <>
      <Container className="!px-0 sm:!px-5 md:!px-[200px] xl:!px-0">
        <div className="mt-4 grid lg:grid-cols-12">
          <BackButton className="self-start lg:col-span-2" />
          <div className="mt-3 lg:col-span-8 lg:mt-0">
            <div className="flex flex-col justify-between border-y border-surface-100 bg-white p-2 sm:flex-row sm:items-center sm:rounded-2xl sm:border sm:p-3">
              <div className="grow">
                <div className="flex flex-wrap items-center justify-between gap-2">
                  <span className="flex items-center justify-between">
                    <Typography className="me-1 text-title4 lg:text-title3">
                      Order ID - #{orderItem.serialNumber}
                    </Typography>
                    {statusTag}
                  </span>

                  {orderItem.canRetry && (
                    <Link
                      to={{
                        pathname: routes.retryPayment,
                        search: `?orderPaymentPlanId=${orderItem.paymentPlans[0].id}&itemName=${orderItem.items[0].itemName}&itemId=${orderItem.items[0].id}&amount=${orderItem.finalAmount}&currencySymbol=${orderItem.currency.symbol}`,
                      }}
                    >
                      <Button>Retry Payment</Button>
                    </Link>
                  )}
                </div>
                <Typography
                  variant="smallBody"
                  className="mt-0.75 text-onSurface-500"
                >
                  Ordered on{" "}
                  {format(new Date(orderItem.createdAt), "dd MMM yyyy")}
                </Typography>
              </div>
              {/* <Button
                variant="text"
                className="sm:mt-0 mt-1"
                onClick={() =>
                  setRequestInvoice({
                    enable: true,
                    transactionId: orderItem.id,
                  })
                }
              >
                Request Invoice
              </Button> */}
            </div>
            {isAppPurchase ? (
              <AppPurchase
                status={orderItem.status}
                items={orderItem.items}
                itemsAllocated={orderItem.itemsAllocated}
                currency={orderItem.currency}
              />
            ) : (
              <>
                <OrderBreakdown
                  itemsAllocated={orderItem.itemsAllocated}
                  status={orderItem.status}
                  finalAmount={orderItem.finalAmount}
                  discountAmount={orderItem.discountAmount}
                  items={orderItem.items}
                  currency={orderItem.currency}
                />
                <Divider
                  weight="light"
                  color="onSurface.200"
                  className="my-3"
                />
                <Payments transactions={orderItem.transactions} />
              </>
            )}
          </div>
        </div>
      </Container>
      {/* TODO(annie) revert when feature available Request Invoice */}
      {/* <RequestInvoice
        reset={() => setRequestInvoice({ enable: false, transactionId: "" })}
        invoiceDetails={requestInvoice}
      /> */}
    </>
  )
}

export default OrderDetails

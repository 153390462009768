import { LearningItem } from "api/resources/learningItems/types"

export const getLearningItemDisplayName = (item: LearningItem): string => {
  switch (item.learningContentType) {
    case "video":
      return item.title
    case "assignment":
    case "assessment":
      return item.title
    case "class_schedule":
    case "assessment_schedule":
    case "discussion":
      return item.milestone.title
    case "activity":
      return item.title
    case "pre_recorded_class":
      return item.title
    case "class":
      return item.name
    case "article":
      return item.title
    case "course":
      return item.name
  }
}

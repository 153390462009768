import { Avatar, Button, cn, Divider, Typography } from "@suraasa/placebo-ui"
import { CoverStyle } from "api/resources/profile/types"
import clsx from "clsx"
import { HOME_ONBOARDING_TARGETS } from "features/Home/HomeOnboarding/constants"
import { DEFAULT_PROFILE_COVER, getCoverImageURL } from "features/Profile/utils"
import { useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import routes from "routes"
import { getPlatformURL, redirectToLogout } from "utils/helpers"
import { trackElementOnPH } from "utils/tracking"

import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/HoverCard"

const profileDefinedRoutes = [
  routes.profile.self,
  routes.certificates,
  routes.mySchedule,
  routes.orders,
  routes.settings,
]
enum MenuOptions {
  suraasaProfile = "Suraasa Profile",
  myCertificates = "My Certificates",
  mySchedule = "My Schedule",
  ordersAndPayments = "Orders and Payments",
  settings = "Settings",
  helpCenter = "Help Center",
  signOut = "Sign Out",
}

const menuLink = [
  { label: MenuOptions.suraasaProfile, link: routes.profile.self },
  { label: MenuOptions.myCertificates, link: routes.certificates },
  { label: MenuOptions.mySchedule, link: routes.mySchedule },
  { label: MenuOptions.ordersAndPayments, link: routes.orders },
  { label: MenuOptions.settings, link: routes.settings },
  {
    label: MenuOptions.helpCenter,
    link: getPlatformURL("sso", "/help"),
  },
  { label: MenuOptions.signOut, onClick: redirectToLogout },
]

const ProfileMenuButton = ({
  coverStyle,
  src,
  name,
}: {
  coverStyle?: CoverStyle
  src: string | null
  name: string
}) => {
  const location = useLocation()
  const active = profileDefinedRoutes.includes(location.pathname)
  const [open, setOpen] = useState(false)

  const navigate = useNavigate()

  const toggleHover = (state: boolean) => {
    setOpen(state)
  }

  return (
    <HoverCard
      openDelay={100}
      closeDelay={100}
      onOpenChange={toggleHover}
      open={open}
    >
      <HoverCardTrigger
        onClick={() => {
          toggleHover(true)
          navigate(routes.profile.self)
        }}
        asChild
      >
        <Button
          variant="text"
          className={clsx(
            "flex flex-col items-center py-0.75 text-onSurface-500 focus:!ring-0",
            {
              "!bg-primary-50": active,
            },
            HOME_ONBOARDING_TARGETS.profile
          )}
        >
          <Avatar
            src={src}
            name={name}
            className={clsx("size-4 !border border-onSurface-200", {
              "border-primary-600": active,
            })}
          />
        </Button>
      </HoverCardTrigger>
      <HoverCardContent className="w-[266px] rounded-lg border border-onSurface-200 bg-white">
        <Link
          className="relative mb-[101px] block w-full px-0.5 pt-0.5"
          onClick={() => {
            toggleHover(false)
          }}
          to={routes.profile.self}
          {...trackElementOnPH({
            name: "View Profile",
            id: "profile-nav-card-item",
          })}
        >
          <div
            style={{
              backgroundImage: `url(${getCoverImageURL(
                coverStyle || DEFAULT_PROFILE_COVER,
                true
              )})`,
            }}
            className="relative h-[65px] w-full rounded bg-cover text-black"
          />
          <div className="absolute left-0 top-4.5 flex w-full flex-col items-center">
            <Avatar src={src} name={name} className="mb-1 size-9" />
            <Typography variant="title4">{name}</Typography>
            <Typography variant="smallBody" className="text-onSurface-500">
              View Profile
            </Typography>
          </div>
        </Link>
        <Divider className="my-1 bg-onSurface-200" />
        <div className="flex flex-col gap-0.25 px-1">
          {menuLink.map(route => {
            const styles = cn(
              "w-full rounded p-1 text-start text-onSurface-900 hover:bg-[#F1F5F9]",
              {
                "text-critical-500 hover:bg-critical-50":
                  route.label === MenuOptions.signOut,
                "bg-primary-50 text-primary-500":
                  route.link === location.pathname,
              }
            )

            if (route.onClick) {
              return (
                <button
                  key={route.label}
                  onClick={() => {
                    route.onClick()
                    toggleHover(false)
                  }}
                  className={styles}
                >
                  <Typography variant="strongSmallBody">
                    {route.label}
                  </Typography>
                </button>
              )
            }

            return (
              <Link
                key={route.label}
                to={route.link}
                onClick={() => {
                  toggleHover(false)
                }}
                {...trackElementOnPH({
                  name: route.label,
                  id: "profile-nav-card-item",
                })}
              >
                <button className={styles}>
                  <Typography variant="strongSmallBody">
                    {route.label}
                  </Typography>
                </button>
              </Link>
            )
          })}
        </div>
        <Divider className="my-1 bg-onSurface-200" />
        <div className="flex items-center justify-start gap-0.5 pb-0.25 pl-2 text-left text-xs font-normal leading-[15.6px] text-onSurface-500">
          <Link
            className="hover:underline"
            to={getPlatformURL("suraasa", "/terms-of-use")}
          >
            Terms and Conditions
          </Link>
          <span>•</span>
          <Link
            className="hover:underline"
            to={getPlatformURL("suraasa", "/privacy-policy")}
          >
            Privacy Policy
          </Link>
        </div>
        <Typography className="pb-0.5 pl-2 !text-[0.75rem] text-onSurface-400">
          v{APP_VERSION}
        </Typography>
      </HoverCardContent>
    </HoverCard>
  )
}

export default ProfileMenuButton

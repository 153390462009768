import {
  CircularProgress,
  Sheet,
  SheetBody,
  SheetContent,
  SheetHeader,
  SheetTitle,
  Typography,
} from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import CriteriaWiseScore from "features/Assignments/CriteriaWiseScore"

type Props = {
  assignmentId: string
  open: boolean
  onClose: () => void
}

const MarkingCriteriaSheet = ({ assignmentId, open, onClose }: Props) => {
  const { data, isSuccess, isError, isLoading } = useQuery({
    enabled: open,
    queryFn: () =>
      api.assignments.getRubrics({ urlParams: { assignmentId: assignmentId } }),
    queryKey: queries.assignments.rubrics(assignmentId).queryKey,
  })

  return (
    <Sheet open={open} onOpenChange={onClose}>
      <SheetContent
        side="right"
        height={100}
        className="w-full !max-w-3xl rounded-tr-none"
      >
        <SheetHeader>
          <SheetTitle>
            <Typography variant="title3">Marking Criteria </Typography>
          </SheetTitle>
        </SheetHeader>
        <SheetBody>
          {isSuccess && (
            <div className="flex flex-col gap-3">
              {data.map((criteria, index) => (
                <CriteriaWiseScore {...criteria} key={index} />
              ))}
            </div>
          )}
          {isLoading && (
            <div className="flex justify-center">
              <CircularProgress />
            </div>
          )}
          {isError && (
            <Typography>
              Error loading rubrics. Please try again later
            </Typography>
          )}
        </SheetBody>
      </SheetContent>
    </Sheet>
  )
}

export default MarkingCriteriaSheet

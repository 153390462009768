import {
  Button,
  IconButton,
  Tag,
  Tooltip,
  Typography,
} from "@suraasa/placebo-ui"
import { Email } from "api/resources/settings/types"
import VerifyEmailButton from "features/Settings/ContactDetails/VerifyEmailButton"
import { Trash } from "iconoir-react"

const getDeleteTooltipText = (
  isPrimary: boolean | null,
  isSignupEmail: boolean | null
) => {
  if (isPrimary) return "Primary email cannot be deleted"

  if (isSignupEmail)
    return "This email was used to create your account. It cannot be deleted."

  return ""
}

const EmailCard = ({
  email,
  onDelete,
  onSetPrimaryEmail,
}: {
  email: Email
  onDelete: (emailData: Email) => void
  onSetPrimaryEmail: (emailData: Email) => void
}) => {
  const isPrimaryEmailVerified = email.isPrimary && email.isVerified

  const canDelete = !email.isPrimary && !email.signupEmail

  return (
    <div className="flex items-center justify-between p-2">
      <div className="me-1 w-[90%]">
        <div className="flex flex-col items-start break-words sm:flex-row sm:flex-wrap sm:items-center">
          <Typography className="me-2 break-all text-strongSmallBody sm:text-strong">
            {email.email}
          </Typography>
          {email.isPrimary && (
            <Tag
              color={isPrimaryEmailVerified ? "success" : "warning"}
              className="mt-0.5 sm:mt-0"
            >
              {isPrimaryEmailVerified ? "Primary" : "Unverified"}
            </Tag>
          )}
        </div>
        {email.isPrimary &&
          (email.isVerified ? (
            <Typography variant="smallBody" className="mt-1 text-onSurface-500">
              This email will be use for account-related notifications and can
              also be used for password resets.
            </Typography>
          ) : (
            <Typography
              variant="smallBody"
              className="mt-1.5 text-onSurface-500"
            >
              Unverified email address cannot receive notifications or be used
              to reset your password.
            </Typography>
          ))}

        {!email.isPrimary && (
          <Button
            variant="text"
            className="mt-1"
            onClick={() => onSetPrimaryEmail(email)}
          >
            Set as Primary Email
          </Button>
        )}
      </div>
      <div className="self-start">
        {email.isVerified ? (
          <Tooltip
            content={getDeleteTooltipText(email.isPrimary, email.signupEmail)}
            className="text-center"
            enabled={!canDelete}
          >
            <IconButton disabled={!canDelete} onClick={() => onDelete(email)}>
              <Trash
                height={22}
                className={
                  canDelete ? "text-critical-500" : "text-secondary-400"
                }
              />
            </IconButton>
          </Tooltip>
        ) : (
          <VerifyEmailButton />
        )}
      </div>
    </div>
  )
}

export default EmailCard

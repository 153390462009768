import { Typography } from "@suraasa/placebo-ui"
import { formatDate } from "utils/helpers"

type Props = {
  date: string
}

const LiveCalendarIcon = ({ date }: Props) => {
  return (
    <div className="w-6 shrink-0 overflow-hidden rounded-lg border border-highlight-600 ">
      <div className="bg-highlight-600 px-[5.5px] py-[2.75px] text-center">
        <Typography className="text-[9.5px] font-medium uppercase text-white">
          {formatDate(date, "MMM")}
        </Typography>
      </div>
      <div className="bg-white px-[5.5px] pb-[2.7px] pt-[2.4px] text-center leading-tight">
        <Typography className="text-[12.25px] font-semibold text-onSurface-900">
          {formatDate(date, "d")}
        </Typography>
        <Typography className="text-[9.5px] uppercase text-muted">
          {formatDate(date, "EEE")}
        </Typography>
      </div>
    </div>
  )
}

export default LiveCalendarIcon

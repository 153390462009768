import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Divider,
  TextField,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { APIError } from "api/utils"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"

function ChangePasswordDialog({
  open,
  close,
}: {
  open: boolean
  close: () => void
}) {
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<{
    oldPassword: string
    newPassword: string
    confirmPassword: string
  }>()

  const onSubmit = handleSubmit(async data => {
    setLoading(true)
    try {
      await api.settings.changePassword({
        data,
      })
      setLoading(false)
      toast.success("Password changed successfully")
      close()
    } catch (err) {
      if (err instanceof APIError) {
        handleErrors(err, { setter: setError })
        setLoading(false)
      }
    }
  })

  useEffect(() => {
    if (!open) reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Dialog open={open} onOpenChange={close}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Change Password</DialogTitle>
        </DialogHeader>
        <form onSubmit={onSubmit}>
          <DialogBody>
            <div>
              <TextField
                placeholder="Enter Current Password"
                errors={errors.oldPassword?.message}
                helperText={errors.oldPassword?.message}
                label="Current Password"
                type="password"
                {...register("oldPassword", {
                  required: { value: true, message: "Required" },
                })}
              />

              <Divider weight="light" className="my-4 bg-onSurface-200" />

              <TextField
                errors={errors.newPassword?.message}
                helperText={errors.newPassword?.message}
                label="Create New Password"
                placeholder="Enter New Password"
                type="password"
                {...register("newPassword", {
                  required: { value: true, message: "Required" },
                })}
              />
              <Typography
                variant="smallBody"
                className="mt-0.5 text-onSurface-500"
              >
                New password must be at least 6 characters long, and contain at
                least one special character and one number.
              </Typography>
              <TextField
                placeholder="Re-enter New Password"
                className="mt-2"
                errors={errors.confirmPassword?.message}
                helperText={errors.confirmPassword?.message}
                label="Confirm New Password"
                type="password"
                {...register("confirmPassword", {
                  required: { value: true, message: "Required" },
                })}
              />
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="outlined"
              className="border-muted text-muted"
              onClick={close}
              size="sm"
            >
              Cancel
            </Button>
            <Button size="sm" loading={loading} type="submit">
              Submit
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default ChangePasswordDialog

import { Typography } from "@suraasa/placebo-ui"
import { VideoPlayer } from "@suraasa/placebo-ui-legacy"
import { LearningItem } from "api/resources/learningItems/types"
import { getCourseVideoPoster } from "components/VideoPlayer/getPoster"
import debounce from "lodash/debounce"
import { useContext, useEffect, useRef, useState } from "react"
import { isIOS } from "react-device-detect"
import { useParams } from "react-router-dom"
import { useEnrollments } from "utils/hooks/useEnrollments"

import { LearningModuleContext } from "../context"
import { useUpdateItemStatus } from "../hooks/useUpdateItemStatus"

type Props = {
  item: LearningItem
}

const RecordedClass = ({ item }: Props) => {
  const { slug } = useParams() as { slug: string }
  const { enrollments } = useEnrollments()

  const courseName = enrollments?.find(x => x.slug === slug)?.name

  if (item.learningContentType !== "pre_recorded_class") {
    throw new Error(
      "Recorded class component can only be used for recorded class content type"
    )
  }

  const { updateItemStatus } = useUpdateItemStatus(item)

  const {
    // addVideoBookmarkInState,
    // removeVideoBookmarkFromState,
    enableVideoNextButton,
  } = useContext(LearningModuleContext)

  //   const [openNotesDialog, setOpenNotesDialog] = useState(false)

  const videoPlayerRef = useRef<HTMLVideoElement>(null)

  function handleExitFullScreen() {
    try {
      if (
        (document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement) &&
        document.exitFullscreen
      ) {
        document.exitFullscreen()
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const videoPlayerRefCurrent = videoPlayerRef.current
    if (videoPlayerRefCurrent) {
      videoPlayerRefCurrent.addEventListener("ended", handleExitFullScreen)
    }

    return () => {
      if (videoPlayerRefCurrent) {
        videoPlayerRefCurrent.removeEventListener("ended", handleExitFullScreen)
        videoPlayerRefCurrent.removeEventListener("ended", handleExitFullScreen)
      }
    }
  }, [])

  useEffect(() => {
    const onPlay = debounce(() => {
      updateItemStatus()
    }, 100)

    const onTimeUpdate = (x: Event) => {
      const video = x.target

      if (video && "currentTime" in video && "duration" in video) {
        const duration = video.duration as number
        const currentTime = video.currentTime as number

        if (currentTime >= duration * 0.9) {
          console.info("> Allow next")
          enableVideoNextButton()
        }
      }
    }

    const videoPlayerRefCurrent = videoPlayerRef.current
    if (videoPlayerRefCurrent) {
      videoPlayerRefCurrent.addEventListener("playing", onPlay)
      videoPlayerRefCurrent.addEventListener("timeupdate", onTimeUpdate)
    }

    return () => {
      if (videoPlayerRefCurrent) {
        videoPlayerRefCurrent.removeEventListener("playing", onPlay)
        videoPlayerRefCurrent.removeEventListener("timeupdate", onTimeUpdate)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateItemStatus])

  const { title, recording } = item
  if (!recording) throw new Error("Recording url is not defined")

  const [poster, setPoster] = useState("")

  useEffect(() => {
    getCourseVideoPoster(title, { courseName }).then(setPoster)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title])

  return (
    <div className="mb-5">
      <VideoPlayer
        defaultLanguage="en"
        className="rounded-2xl bg-white [&_video]:!outline-none"
        poster={poster}
        autoPlay={!isIOS}
        ref={videoPlayerRef}
        src={recording}
        languages={[]}
      />
      <Typography variant="title3" className="mt-2">
        {title}
      </Typography>
    </div>
  )
}

export default RecordedClass

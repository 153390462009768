import {
  Button,
  DialogBody,
  DialogFooter,
  Typography,
} from "@suraasa/placebo-ui"
import { AssignmentCategory } from "api/resources/assignments/types"
import { pluralize } from "utils/helpers"

const ReattemptInfo = ({
  onClick,
  reattemptCount,
  title,
  category,
}: {
  onClick: () => void
  reattemptCount: number
  title?: string
  category?: AssignmentCategory | null
}) => {
  return (
    <>
      <DialogBody className="py-2.5">
        <ReattemptInfoContent
          reattemptCount={reattemptCount}
          title={title}
          category={category}
        />
      </DialogBody>
      <DialogFooter>
        <Button onClick={onClick} size="sm">
          I understand
        </Button>
      </DialogFooter>
    </>
  )
}

const ReattemptInfoContent = ({
  category,
  reattemptCount,
  title,
}: {
  category?: AssignmentCategory | null
  reattemptCount: number
  title?: string
}) => {
  switch (category) {
    case AssignmentCategory.RESEARCH:
      return (
        <>
          <Typography variant="strong" className="mb-1">
            Since this is a research assignment, we&apos;re not counting your
            re-attempts.
          </Typography>
          <Typography variant="smallBody" className="mb-1">
            Research assignments require multiple iterations. To help you
            attempt them comfortably, they come with unlimited reattempts.
          </Typography>
        </>
      )

    default:
      return (
        <>
          <Typography variant="strong" className="mb-1">
            Since its your 1st Module, we&apos;re not counting your re-attempts.
          </Typography>
          <Typography variant="smallBody" className="mb-1">
            Once you complete your 1st module, you will get{" "}
            <Typography variant="strongSmallBody" className="inline">
              {pluralize("re-attempt", reattemptCount)}
            </Typography>{" "}
            throughout {title}.
          </Typography>
        </>
      )
  }
}

export default ReattemptInfo

import { TagColors } from "@suraasa/placebo-ui-legacy"
import { AttemptResult, AttemptStatus } from "api/resources/assessments/types"

export function getTagStyle({
  result,
  status,
}: {
  status: AttemptStatus | null | undefined
  result?: AttemptResult | null
  endTime?: string
}): {
  label: "Passed" | "Failed" | "Completed" | "Missed" | "Continue"
  color: TagColors
} | null {
  const now = new Date()

  if (status === AttemptStatus.MISSED) {
    return { label: "Missed", color: "critical" }
  }
  if (result === AttemptResult.PASS) {
    return { label: "Passed", color: "success" }
  }
  if (result === AttemptResult.FAIL) {
    return { label: "Failed", color: "critical" }
  }
  if (status === AttemptStatus.COMPLETED) {
    return { label: "Completed", color: "primary" }
  }

  if (status === AttemptStatus.IN_PROGRESS) {
    return { label: "Continue", color: "primary" }
  }
  return null
}

import { Typography } from "@suraasa/placebo-ui"
import { Button, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { AssignmentList } from "api/resources/assignments/types"
import clsx from "clsx"
import ItemList from "components/ItemList"
import ItemRow from "components/ItemRow"
import TruncatedText from "components/TruncatedText"
import CheckRestrictions from "features/AccessManagement/CheckRestrictions"
import {
  buildAssignmentTitle,
  getActionConfig,
} from "features/Assignments/utils"
import { Lock } from "iconoir-react"
import { useState } from "react"
import { useTheme } from "react-jss"
import { useParams } from "react-router-dom"

import styles from "./Assignments.module.css"
import { ViewAllSubtasksCard, ViewAllSubtasksSheet } from "./Subtasks"
import {
  getButtonAction,
  getDueDate,
  hasNotStartedAnyAssignmentYet,
} from "./utils"

const GroupedAssignmentCard = ({
  assignments,
  groupName,
  courseId,
}: {
  assignments: AssignmentList[]
  groupName: string
  courseId: number
}) => {
  const theme = useTheme<Theme>()

  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const isDebugMode = localStorage.getItem("assignmentListDebug") === "true"

  const [sheetOpen, setSheetOpen] = useState(false)

  const { slug, parentSlug } = useParams() as {
    slug: string
    parentSlug: string
  }

  const [selectedAssignmentId, setSelectedAssignmentId] = useState<
    number | null
  >(null)

  return (
    <>
      {sheetOpen && (
        <ViewAllSubtasksSheet
          handleClose={() => {
            setSelectedAssignmentId(null)
            setSheetOpen(false)
          }}
          open={sheetOpen}
          courseId={courseId}
          groupName={groupName}
          selectedAssignmentId={selectedAssignmentId}
          assignments={assignments}
        />
      )}
      <div className="mt-2">
        <Typography variant="title4" className="mb-1 text-onSurface-800">
          {groupName}
        </Typography>

        {assignments.length > 1 && (
          <ViewAllSubtasksCard
            assignmentCount={assignments.length}
            onClick={() => {
              setSheetOpen(true)
            }}
          />
        )}
        <ItemList>
          {assignments.map(assignment => {
            const config = getActionConfig(assignment)
            const isLocked = assignment.dueDate === null
            return (
              <ItemRow
                key={assignment.uuid}
                className={clsx("relative", {
                  "bg-[linear-gradient(270deg,#FBF0F0_0%,rgba(251,240,240,0)_39.36%)]":
                    config.isOverdue,
                  [styles.draft]: config.isDraft,
                })}
                infoSlot={
                  <div className="grow">
                    {isDebugMode && (
                      <pre>{JSON.stringify(assignment, null, 2)}</pre>
                    )}
                    {config.isOverdue && (
                      <div
                        className={clsx(
                          "absolute left-0 top-0 h-full w-0.5 bg-critical-500"
                        )}
                      />
                    )}
                    {config.isDraft && (
                      <Typography
                        variant="smallBody"
                        style={{ fontSize: 12 }}
                        className="mb-0.5 text-onSurface-500"
                      >
                        Continue Writing
                      </Typography>
                    )}
                    <TruncatedText
                      maxLength={60}
                      variant="strong"
                      className="mb-0.5"
                      hideActionButton
                    >
                      {buildAssignmentTitle(assignment)}
                    </TruncatedText>

                    <div className="flex items-center gap-1">
                      {config.tag}
                      {getDueDate(assignment)}
                    </div>
                  </div>
                }
                actionSlot={
                  <CheckRestrictions
                    accessStatus={assignment.accessStatus}
                    render={({ isRestricted }) => {
                      if (isLocked || isRestricted) return <Lock />

                      return (
                        <span className={clsx({ "self-end": isXs })}>
                          {assignments.length > 1 &&
                          hasNotStartedAnyAssignmentYet(assignments) ? (
                            <Button
                              label="View"
                              variant="text"
                              onClick={() => {
                                setSheetOpen(true)
                                setSelectedAssignmentId(assignment.id)
                              }}
                            />
                          ) : (
                            getButtonAction(assignment, {
                              slug,
                              parentSlug,
                              ...config,
                            })
                          )}
                        </span>
                      )
                    }}
                  />
                }
              />
            )
          })}
        </ItemList>
      </div>
    </>
  )
}

export default GroupedAssignmentCard

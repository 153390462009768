import { Button, Tag, Tooltip } from "@suraasa/placebo-ui"
import { OrderStatus, PaymentPlanStatus } from "api/resources/orders/types"
import { OrderItemType } from "api/resources/orders/types"
import { RefundStatus } from "api/resources/payment/types"
import { Link } from "react-router-dom"

export const getStatusTag = (
  status: OrderStatus,
  refundStatus: RefundStatus | null
): JSX.Element => {
  switch (refundStatus) {
    case RefundStatus.refundInitiated:
      return (
        <div>
          <Tooltip content="Amount will be refunded into your account within 10-15 business days.">
            <Tag color="primary">Refund Initiated</Tag>
          </Tooltip>
        </div>
      )

    case RefundStatus.refunded:
      return <Tag color="success">Refunded</Tag>
  }

  switch (status) {
    case OrderStatus.SUCCESSFUL:
      return <Tag color="success">Successful</Tag>

    case OrderStatus.IN_PROGRESS:
      return <Tag color="primary">In Progress</Tag>

    case OrderStatus.NOT_INITIATED:
      return <Tag color="warning">Not Initiated</Tag>

    case OrderStatus.CANCELLED:
      return <Tag color="critical">Cancelled</Tag>

    case OrderStatus.FAILED:
      return <Tag color="critical">Payment Failed</Tag>
  }
}

export const getPaymentPlanTag = (
  status: PaymentPlanStatus
): { label: string; color: string } => {
  switch (status) {
    case PaymentPlanStatus.PAID:
      return { label: "Paid", color: "success" }
    case PaymentPlanStatus.UNPAID:
      return { label: "Unpaid", color: "critical" }
    case PaymentPlanStatus.DUE:
      return { label: "Due", color: "onSurface" }
  }
}
export const getOrderItemAction = ({
  orderStatus,
  itemType,
  itemSlug,
  itemsAllocated,
}: {
  orderStatus: OrderStatus
  itemType: OrderItemType
  itemSlug: string | null
  itemsAllocated: boolean
}) => {
  if (orderStatus !== OrderStatus.SUCCESSFUL) {
    return null
  }

  const actionableItemTypes = [
    OrderItemType.QUALIFICATION,
    OrderItemType.CERTIFICATION,
    OrderItemType.COURSE,
  ]

  if (!actionableItemTypes.includes(itemType)) return

  if (itemsAllocated) {
    const itemTypeNames = {
      [OrderItemType.QUALIFICATION]: "Qualification",
      [OrderItemType.CERTIFICATION]: "Certification",
      [OrderItemType.COURSE]: "Course",
    }

    const itemTypeName = itemTypeNames[itemType as keyof typeof itemTypeNames]

    return (
      <Link to={`/${itemType}/${itemSlug}/overview/`}>
        <Button variant="text" className="w-fit">
          Start {itemTypeName}
        </Button>
      </Link>
    )
  }

  return (
    <Tooltip
      className="max-w-[200px]"
      content="It will be allocated to you within 30 minutes"
    >
      <Tag color="warning">Allocating Product</Tag>
    </Tooltip>
  )
}

import { Divider, Typography } from "@suraasa/placebo-ui"
import { OrderItemDetails } from "api/resources/orders/types"
import { InfoCircle } from "iconoir-react"

import AndroidIcon from "/src/features/Orders/assets/androidIcon.svg"
import AppleIcon from "/src/features/Orders/assets/appleIcon.svg"

import { ItemDetails } from "../OrderBreakdown"

const Alert = () => (
  <div className="my-[27px] flex items-center border-y border-interactive-200 bg-interactive-50 px-2 py-3 sm:rounded-2xl sm:border sm:px-3">
    <InfoCircle className="me-1 shrink-0" height={20} width={20} />
    <Typography variant="smallBody">
      Purchased via in-app purchase on the Suraasa app. To get the invoice
      follow these instructions.
    </Typography>
  </div>
)

const IosInstructions = () => (
  <div className="mb-2 px-2 sm:px-0">
    <img src={AppleIcon} alt="apple" />
    <Typography variant="smallBody" className="mb-2 mt-1">
      If you&apos;ve purchased through your Apple App Store account you can get
      an invoice, receipt or by viewing your{" "}
      <button
        onClick={() => window.open("https://support.apple.com/en-us/HT204088")}
        className="text-primary-500 underline underline-offset-4"
      >
        purchase history
      </button>{" "}
      on iOS device.
    </Typography>
    {[
      <>
        Go to{" "}
        <button
          onClick={() => window.open("https://reportaproblem.apple.com/")}
          className="text-primary-500"
        >
          reportaproblem.apple.com
        </button>
      </>,
      "Sign in with your Apple ID and password.",
      "A list of your recent purchases appears.",
    ].map((text, idx) => (
      <div className="flex items-start" key={idx}>
        <Typography variant="smallBody" className="me-0.5">
          {idx + 1}.
        </Typography>
        <Typography variant="smallBody">{text}</Typography>
      </div>
    ))}
    <Typography variant="strongSmallBody" className="mb-0.5 mt-2">
      On your iPhone, iPad, or iPod touch:
    </Typography>
    {[
      "Open the Settings app.",
      "Tap your name, then tap Media & Purchases.",
      "Tap View Account. You might be asked to sign in.",
      "Scroll to Purchase History and tap it. If you want to see purchases that you made more than 90 days prior, tap last 90 days, then select a date range.",
      "Find the item, then tap Total Billed.",
    ].map((text, idx) => (
      <div className="flex items-start" key={idx}>
        <Typography variant="smallBody" className="me-0.5">
          {idx + 1}.
        </Typography>
        <Typography variant="smallBody">{text}</Typography>
      </div>
    ))}
  </div>
)

const AndroidInstructions = () => (
  <div className="mb-2 px-2 sm:px-0">
    <img src={AndroidIcon} alt="apple" />
    <Typography variant="smallBody" className="mb-2 mt-1">
      You can find your recent in-app purchases on Google Play by following the
      steps provided below.
    </Typography>
    {[
      <>
        Go to{" "}
        <button
          onClick={() => window.open("https://pay.google.com/")}
          className="text-primary-500"
        >
          pay.google.com
        </button>{" "}
        and sign in with your Google Account.
      </>,
      "On the left, click Subscriptions and Services.",
      "Click View Purchases.",
      "Select an order to see your receipt.",
      "If you need your order number, you can find it on the receipt page.",
    ].map((text, idx) => (
      <div className="flex items-start" key={idx}>
        <Typography variant="smallBody" className="me-0.5">
          {idx + 1}.
        </Typography>
        <Typography variant="smallBody">{text}</Typography>
      </div>
    ))}
  </div>
)

const AppPurchase = ({
  items,
  currency,
  itemsAllocated,
  status,
}: Pick<
  OrderItemDetails,
  "items" | "currency" | "status" | "itemsAllocated"
>) => {
  return (
    <div className="mb-10">
      <Typography variant="preTitle" className="mb-2 mt-3 px-2 sm:px-0">
        Order breakdown
      </Typography>
      <div className="border-y border-surface-100 bg-surface-50 p-3 sm:rounded-2xl sm:border ">
        <ItemDetails
          status={status}
          itemsAllocated={itemsAllocated}
          items={items}
          symbol={currency.symbol}
        />
      </div>
      <Alert />
      <IosInstructions />
      <Divider weight="light" className="my-4 bg-onSurface-200 px-2 sm:px-0" />
      <AndroidInstructions />
    </div>
  )
}

export default AppPurchase

import { Typography } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import {
  AssignmentList,
  SUBMISSION_STATUS,
  SubmissionResultEnum,
} from "api/resources/assignments/types"
import { getActionConfig } from "features/Assignments/utils"
import { Link } from "react-router-dom"
import routes from "routes"
import { formatDate } from "utils/helpers"

export const hasNotStartedAnyAssignmentYet = (
  assignments: AssignmentList[]
) => {
  return assignments.every(assignment => {
    const config = getActionConfig(assignment)
    return config.canAttempt && !config.isDraft
  })
}

export const getDueDate = (item: AssignmentList) => {
  if (!item.dueDate) return null

  const date = (
    <Typography variant="smallBody" className="text-onSurface-500">
      Due by {formatDate(item.dueDate, "d MMM y, h:mm a")}
    </Typography>
  )

  // Show due date only in case of due, overdue, saved_for_later, rejected and failed
  if (item.result === SubmissionResultEnum.FAILED) return date
  switch (item.submissionStatus) {
    case null:
    case undefined:
    case SUBMISSION_STATUS.rejected:
    case SUBMISSION_STATUS.plagiarised:
    case SUBMISSION_STATUS.savedForLater:
      return date
  }

  return null
}

export const getButtonAction = (
  item: AssignmentList,
  extras: {
    slug: string
    parentSlug: string
    canAttempt: boolean
    isDraft: boolean | undefined
  }
) => {
  const state = {
    slug: extras.slug,
    parentSlug: extras.parentSlug,
  }

  if (extras.canAttempt) {
    const path = routes.assignment
      .replace(":slug", extras.slug)
      .replace(":learningItemType", "course")
      .replace(":assignmentId", item.id.toString())

    const url = new URL(path, window.location.origin)
    if (extras.isDraft && item.submissionUuid)
      url.searchParams.set("submissionId", item.submissionUuid)

    return (
      <Button
        variant="text"
        component={Link}
        to={url.pathname + url.search}
        state={state}
      >
        View
      </Button>
    )
  }

  if (item.submissionUuid)
    return (
      <Button
        variant="text"
        component={Link}
        to={routes.submission
          .replace(":slug", extras.slug)
          .replace(":learningItemType", "course")
          .replace(":assignmentId", item.id.toString())
          .replace(":submissionId", item.submissionUuid)}
        state={state}
      >
        View
      </Button>
    )

  return (
    <Button
      component={Link}
      to={routes.assignment
        .replace(":slug", extras.slug)
        .replace(":learningItemType", "course")
        .replace(":assignmentId", item.id.toString())}
      state={state}
    >
      View
    </Button>
  )
}

import { actionAlert, Button, toast, Typography } from "@suraasa/placebo-ui"
import api from "api"
import {
  Article,
  LearningItem,
  VideoBookmark,
} from "api/resources/learningItems/types"
import { APIError } from "api/utils"
import groupBy from "lodash/groupBy"
import { useEffect, useMemo, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { getAuthInfo, mapErrorsV2 } from "utils/helpers"
import { useEnrollments } from "utils/hooks/useEnrollments"

import { getLearningContentTypeDisplay } from "./utils"

export type SwitchItemOptions =
  | {
      mode: "next" | "previous"
    }
  | {
      mode: "jump"
      userPlannerItemId: number
    }

const IS_EMPTY_DEFAULT_STATE = { list: false, item: false }

export const usePlanner = () => {
  const { slug } = useParams() as { slug: string }
  const { enrollments } = useEnrollments()
  const courseId = enrollments?.find(x => x.slug === slug)?.uuid

  const [searchParams] = useSearchParams()
  const plannerId = searchParams.get("plannerId")

  const itemIdToStartFrom = searchParams.get("itemId")

  const [planner, setPlanner] = useState<LearningItem[]>()
  const [isEmpty, setIsEmpty] = useState(IS_EMPTY_DEFAULT_STATE)
  const [loading, setLoading] = useState(false)

  const [showPlannerCompletedSheet, setShowPlannerCompletedSheet] =
    useState(false)

  const [currentItem, setCurrentItem] = useState<LearningItem>()
  const [milestoneInstructions, setMilestoneInstructions] =
    useState<Article | null>(null)

  const milestones = useMemo(() => groupBy(planner, "milestone.id"), [planner])

  const totalMilestones = Object.keys(milestones).length
  const currentMilestoneId = planner?.find(x => x.id === currentItem?.id)
    ?.milestone.id

  const currentMilestoneNumber =
    Object.keys(milestones).findIndex(
      x => x === currentMilestoneId?.toString()
    ) + 1

  const isFirstItem =
    planner?.find(item => item.id === currentItem?.id)?.sequence === 1

  const lockedItem = planner?.find(item => item.status === "unlocked")

  useEffect(() => {
    const getPlanner = async (id: string) => {
      try {
        setIsEmpty(IS_EMPTY_DEFAULT_STATE)
        const items = await api.learningItems.listPlannerItems({
          urlParams: { plannerId: id },
        })
        setPlanner(items)
      } catch (error) {
        setIsEmpty(p => ({ ...p, list: true }))
        console.error(error)
      }
    }

    const getItem = async (plannerId: string) => {
      if (itemIdToStartFrom) {
        setIsEmpty(IS_EMPTY_DEFAULT_STATE)
        try {
          const item = await api.learningItems.getItemById({
            urlParams: {
              userPlannerItemId: itemIdToStartFrom,
            },
          })
          setCurrentItem(item)
          // return if we found the item
          return
        } catch (e) {
          // in case we don't find the item due to invalid ID or whatever reason, we just let the code continue and get the last unlocked item
          console.error("> Cannot get item by id, trying to get latest item")
        }
      }
      try {
        const item = await api.learningItems.getLastUnlockedItem({
          urlParams: { learningItemId: plannerId },
        })
        setCurrentItem(item)
      } catch (error) {
        setIsEmpty(p => ({ ...p, item: true }))
      }
    }

    if (plannerId) {
      getPlanner(plannerId)
      getItem(plannerId)
    }
  }, [plannerId, itemIdToStartFrom])

  const updatePlannerList = (item: LearningItem) => {
    // @ts-expect-error this is safe to do
    setPlanner(p => {
      if (!p) return p

      const newItems = p.map(x => {
        if (x.id === item.id) {
          return {
            ...x,
            ...item,
          }
        }
        return x
      })

      return newItems
    })
  }
  /**
   * Updates status in both planner items (sidebar) and the current item
   */
  const hydrateStatusUpdate = (
    userPlannerItemId: number,
    status: LearningItem["status"]
  ) => {
    if (!planner) return
    const newItems = planner.map(x => {
      if (x.id === userPlannerItemId) {
        return { ...x, status }
      }
      return x
    })

    setPlanner(newItems)

    if (!currentItem) return
    // Edge case just in case currentItem got changed until the state update was called
    if (currentItem.id !== userPlannerItemId) return

    setCurrentItem({ ...currentItem, status })
  }

  const getMilestoneInstructions = async (id: number) => {
    try {
      const res = await api.learningItems.getArticle({
        urlParams: {
          articleId: id,
        },
      })
      setMilestoneInstructions(res)
    } catch (e) {
      console.error("> Unable to fetch milestone instructions")
    }
  }

  const switchItem = async (options: SwitchItemOptions) => {
    setLoading(true)
    let item: LearningItem | undefined
    switch (options.mode) {
      case "next":
        if (!currentItem) return
        try {
          const res = await api.learningItems.getNextItem({
            urlParams: {
              userPlannerItemId: currentItem.id,
            },
          })

          setCurrentItem(p => {
            if (!p) return p

            const updated: LearningItem = { ...p, status: "completed" }

            updatePlannerList(updated)
            return updated
          })

          if ("plannerCompleted" in res) {
            setShowPlannerCompletedSheet(true)
          } else {
            item = res
            const newItemMilestoneId = planner?.find(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              ({ id }) => item!.id === id
            )?.milestone.id
            const currentItemMilestoneId = planner?.find(
              ({ id }) => currentItem.id === id
            )?.milestone.id

            if (
              currentItemMilestoneId &&
              newItemMilestoneId &&
              currentItemMilestoneId !== newItemMilestoneId
            ) {
              getMilestoneInstructions(newItemMilestoneId)
            }
          }

          // Fetch milestone instructions and show that here
          // if (item.milestone.id !== currentItem.milestone.id) {
          //   // Open milestone instruction dialog
          //   setMilestoneInstructions(currentItem)
          // }
        } catch (e) {
          console.error(e)

          const errorMessages = [
            "Cannot complete current item",
            "Cannot unlock item",
          ]

          if (!lockedItem) {
            mapErrorsV2(e)
            return
          }

          if (
            lockedItem &&
            e instanceof APIError &&
            e.errors.message &&
            errorMessages.includes(e.errors.message)
          ) {
            const itemType = getLearningContentTypeDisplay(
              lockedItem.learningContentType
            )
            actionAlert.error(({ handleClose }) => ({
              body: (
                <div>
                  <Typography className="mt-3 text-title4 sm:text-title3">
                    Please complete the pending {itemType} before going forward
                    in the course
                  </Typography>
                  <Button
                    autoFocus
                    className="mt-2 capitalize"
                    size="sm"
                    color="critical"
                    onClick={() => {
                      handleClose()
                      switchItem({
                        mode: "jump",
                        userPlannerItemId: lockedItem.id,
                      })
                    }}
                  >
                    Go to {itemType}
                  </Button>
                </div>
              ),
              className: "p-0",
            }))
          }
        }
        break
      case "previous":
        if (!currentItem) return
        try {
          item = await api.learningItems.getPreviousItem({
            urlParams: {
              userPlannerItemId: currentItem.id,
            },
          })
        } catch (e) {
          toast.error("Unable to get previous item", {
            description: "Please contact care@suraasa.com",
          })
          console.error(e)
        }
        break
      case "jump":
        try {
          item = await api.learningItems.getItemById({
            urlParams: {
              userPlannerItemId: options.userPlannerItemId,
            },
          })
        } catch (e) {
          toast.error("Item not found")
          console.error(e)
        }
        break
    }

    if (item) {
      setCurrentItem(item)
      updatePlannerList(item)
    }
    setLoading(false)
  }

  const handleArticleBookmark = async (itemId: string) => {
    if (!currentItem || !courseId) return
    if (currentItem.learningContentType !== "article") return
    if (currentItem.uuid !== itemId) return

    const alreadyBookmarked = currentItem.bookmarkArticle.length > 0

    if (alreadyBookmarked) {
      try {
        await api.learningItems.removeArticleBookmark({
          urlParams: { courseId, articleId: itemId },
        })

        const newItem = {
          ...currentItem,
          bookmarkArticle: currentItem.bookmarkArticle.filter(
            x => x.course !== courseId
          ),
        }

        setCurrentItem(newItem)
        updatePlannerList(newItem)

        return
      } catch (e) {
        console.error(e)
      }
    }

    try {
      const bookmark = await api.learningItems.createArticleBookmark({
        // Empty comment is intentional. We are removing the feature of adding a note on articles.

        data: { course: courseId, article: itemId, comment: "bookmarked" },
      })

      const formattedBookmark = {
        uuid: bookmark.uuid,
        comment: bookmark.comment,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        user: getAuthInfo()!.user.uuid,
        course: bookmark.course.uuid,
        article: bookmark.article.uuid,
      }

      const newItem = {
        ...currentItem,
        bookmarkArticle: [formattedBookmark, ...currentItem.bookmarkArticle],
      }

      setCurrentItem(newItem)
      updatePlannerList(newItem)
    } catch (e) {
      console.error(e)
    }
  }

  const addVideoBookmarkInState = (bookmark: VideoBookmark) => {
    if (!currentItem) return
    if (currentItem.learningContentType !== "video") return

    const newItem = {
      ...currentItem,
      bookmarkVideo: [bookmark, ...currentItem.bookmarkVideo],
    }

    setCurrentItem(newItem)
    updatePlannerList(newItem)
  }

  const removeVideoBookmarkFromState = (bookmarkId: string) => {
    if (!currentItem) return
    if (currentItem.learningContentType !== "video") return

    const newItem = {
      ...currentItem,
      bookmarkVideo: currentItem.bookmarkVideo.filter(
        x => x.uuid !== bookmarkId
      ),
    }

    setCurrentItem(newItem)
    updatePlannerList(newItem)
  }

  const handleDismissMilestoneInstructions = () => {
    setMilestoneInstructions(null)
  }

  return {
    planner,
    currentItem,
    switchItem,
    loading,
    isEmpty,
    removeVideoBookmarkFromState,
    hydrateStatusUpdate,
    handleArticleBookmark,
    addVideoBookmarkInState,
    milestoneInstructions,
    handleDismissMilestoneInstructions,
    totalMilestones,
    currentMilestoneNumber,
    showPlannerCompletedSheet,
    setShowPlannerCompletedSheet,
    isFirstItem,
  }
}

import { Libraries, useLoadScript } from "@react-google-maps/api"
import { CircularProgress, InputLabel, TextField } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { useEffect, useRef, useState } from "react"

export enum AddressTypeEnum {
  COUNTRY = "country",
  STATE = "administrative_area_level_1",
  DISTRICT = "administrative_area_level_3",
  CITY = "locality",
  AREA = "sublocality_level_1",
  POLITICAL = "political",
  POSTALCODE = "postal_code",
}

export type AddressComponents = {
  longText: string
  shortText: string
  types: AddressTypeEnum[]
}[]

export type GoogleLocationSelectValue = {
  fullWidth?: boolean
  className?: string
  placeHolder?: string
  onChange: (addressComponents: AddressComponents) => void
  label?: string
  required?: boolean
  errors?: string | string[]
  helperText?: string
  value?: {
    city?: string | null
    country?: string | null
    state?: string | null
  }
}

const libraries: Libraries = ["places"]

const GoogleLocationSelect = ({
  value,
  className,
  onChange,
  placeHolder,
  label,
  required,
  errors,
  helperText,
}: GoogleLocationSelectValue) => {
  const { isLoaded, loadError } = useLoadScript({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_PLACES_API_KEY!,
    libraries,
    preventGoogleFontsLoading: true,
  })

  const [isFetching, setIsFetching] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const handlePlaceChanged = async (
    address: google.maps.places.Autocomplete
  ) => {
    console.log("> Place change called", address)
    setIsFetching(false)

    if (!isLoaded) return
    const place = address.getPlace()

    if (place?.address_components) {
      const formattedComponents = place.address_components.map(component => ({
        longText: component.long_name,
        shortText: component.short_name,
        types: component.types as AddressTypeEnum[],
      }))

      onChange(formattedComponents)
      return
    }

    onChange([])
  }

  useEffect(() => {
    if (!isLoaded || loadError || !inputRef.current) return

    const autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
      fields: ["address_components"],
    })

    const listener = autocomplete.addListener("place_changed", () =>
      handlePlaceChanged(autocomplete)
    )

    return () => listener?.remove()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, loadError])

  return (
    <div className={clsx("flex flex-col px-px", className)}>
      {/* <pre>{JSON.stringify(input, null, 2)}</pre> */}
      {label && <InputLabel label={label} required={required} />}

      <TextField
        ref={inputRef}
        type="text"
        endIcon={
          isFetching ? (
            <CircularProgress className="size-2.5 text-black" />
          ) : undefined
        }
        errors={errors}
        helperText={helperText}
        placeholder={placeHolder}
        className="w-full"
        defaultValue={
          value
            ? [value.city, value.state, value.country]
                .filter(Boolean)
                .join(", ")
            : ""
        }
        onChange={e => {
          if (e.target.value) {
            setIsFetching(true)
          } else {
            setIsFetching(false)
          }
        }}
      />
    </div>
  )
}

export default GoogleLocationSelect

import api from "api"
import { AuthData, UserTokenResponse } from "api/resources/users/types"
import { USER_TYPE } from "utils/constants"

export const processAuthCode = async (
  code: string,
  platform = USER_TYPE
): Promise<AuthData | null> => {
  try {
    const response = await api.users.getUserToken({
      data: {
        code,
        platform,
      },
    })

    return calculateTokenExpiration(response)
  } catch (e) {
    console.log(e)
    return null
  }
}

export const calculateTokenExpiration = (
  authInfo: UserTokenResponse
): AuthData => {
  return {
    ...authInfo,
    generatedAt: Date.now(),
    expiresAt: Date.now() + authInfo.expiresIn * 1000,
  }
}

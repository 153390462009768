export enum NotificationActionName {
  LEGACY_REDIRECT_TO_DISCUSSION_DETAIL = "REDIRECT_TO_DISCUSSION_DETAIL",
  LEGACY_REDIRECT_TO_DISCUSSIONS_DETAIL = "REDIRECT_TO_DISCUSSIONS_DETAIL",
  LEGACY_REDIRECT_TO_ASSESSMENT_DETAIL = "REDIRECT_TO_ASSESSMENT_DETAIL",
  LEGACY_REDIRECT_TO_ASSIGNMENT_PAGE_DETAIL = "REDIRECT_TO_ASSIGNMENT_PAGE_DETAIL",
  LEGACY_REDIRECT_TO_CENTRE_SCHEDULE_DETAIL = "REDIRECT_TO_CENTRE_SCHEDULE_DETAIL",
  LEGACY_REDIRECT_TO_MY_LIBRARY = "REDIRECT_TO_MY_LIBRARY",
  LEGACY_REDIRECT_TO_COURSE_CERTIFICATE_DETAIL = "REDIRECT_TO_COURSE_CERTIFICATE_DETAIL",
  LEGACY_REDIRECT_TO_CERTIFICATION_CERTIFICATE_DETAIL = "REDIRECT_TO_CERTIFICATION_CERTIFICATE_DETAIL",
  LEGACY_REDIRECT_TO_QUALIFICATION_CERTIFICATE_DETAIL = "REDIRECT_TO_QUALIFICATION_CERTIFICATE_DETAIL",
  LEGACY_REDIRECT_TO_HOMEPAGE = "REDIRECT_TO_HOMEPAGE",
  LEGACY_REDIRECT_TO_ORDER_DETAIL = "REDIRECT_TO_ORDER_DETAIL",
  LEGACY_ITEMS_ALLOCATED = "ITEMS_ALLOCATED",
  LEGACY_TRANSACTION_SUCCESSFUL = "TRANSACTION_SUCCESSFUL",
  LEGACY_TRANSACTION_FAILED = "TRANSACTION_FAILED",

  // New names for notification actions
  OPEN_LEARNING_ITEM_OVERVIEW = "OPEN_LEARNING_ITEM_OVERVIEW",
  OPEN_JOB = "OPEN_JOB",
  OPEN_SUBMISSION = "OPEN_SUBMISSION",
  OPEN_DISCUSSION = "OPEN_DISCUSSION",
  OPEN_ASSESSMENT = "OPEN_ASSESSMENT",
  OPEN_ASSIGNMENT = "OPEN_ASSIGNMENT",
  OPEN_ASSIGNMENTS_LIST = "OPEN_ASSIGNMENTS_LIST",
  OPEN_FULL_CALENDAR = "OPEN_FULL_CALENDAR",
  OPEN_CERTIFICATES_LIST = "OPEN_CERTIFICATES_LIST",
  OPEN_ORDER_DETAILS = "OPEN_ORDER_DETAILS",
  OPEN_HOME_TAB = "OPEN_HOME_TAB",
  OPEN_LEARNING_TAB = "OPEN_LEARNING_TAB",
}
export type NotificationAction =
  | {
      name:
        | NotificationActionName.LEGACY_REDIRECT_TO_DISCUSSION_DETAIL
        | NotificationActionName.LEGACY_REDIRECT_TO_DISCUSSIONS_DETAIL
        | NotificationActionName.OPEN_DISCUSSION
      data: { userDiscussionId: string }
    }
  | {
      name: NotificationActionName.OPEN_LEARNING_ITEM_OVERVIEW
      data: {
        slug: string
        learningItemType: "course" | "qualification" | "certification"
      }
    }
  | {
      name:
        | NotificationActionName.LEGACY_REDIRECT_TO_ASSESSMENT_DETAIL
        | NotificationActionName.OPEN_ASSESSMENT

      data: { assessmentUuid: string }
    }
  | {
      name:
        | NotificationActionName.LEGACY_REDIRECT_TO_ASSIGNMENT_PAGE_DETAIL
        | NotificationActionName.OPEN_ASSIGNMENT
        | NotificationActionName.OPEN_SUBMISSION
      data: {
        // We are supporting both, because backend has support for array, but marketing might only send one.
        courseSlugs?: string[]
        courseSlug?: string

        // We are supporting both, but ideally it should be submissionUuid
        submissionUuid?: string
        submissionId?: string

        assignmentId: number
      }
    }
  | {
      name:
        | NotificationActionName.LEGACY_REDIRECT_TO_CENTRE_SCHEDULE_DETAIL
        | NotificationActionName.LEGACY_REDIRECT_TO_MY_LIBRARY
        | NotificationActionName.LEGACY_ITEMS_ALLOCATED
        | NotificationActionName.LEGACY_REDIRECT_TO_COURSE_CERTIFICATE_DETAIL
        | NotificationActionName.LEGACY_REDIRECT_TO_CERTIFICATION_CERTIFICATE_DETAIL
        | NotificationActionName.LEGACY_REDIRECT_TO_QUALIFICATION_CERTIFICATE_DETAIL
        | NotificationActionName.LEGACY_REDIRECT_TO_HOMEPAGE
        | NotificationActionName.OPEN_LEARNING_TAB
        | NotificationActionName.OPEN_HOME_TAB
        | NotificationActionName.OPEN_CERTIFICATES_LIST
        | NotificationActionName.OPEN_FULL_CALENDAR
    }
  | {
      name:
        | NotificationActionName.LEGACY_REDIRECT_TO_ORDER_DETAIL
        | NotificationActionName.LEGACY_TRANSACTION_FAILED
        | NotificationActionName.LEGACY_TRANSACTION_SUCCESSFUL
        | NotificationActionName.LEGACY_TRANSACTION_FAILED
        | NotificationActionName.OPEN_ORDER_DETAILS
      data: { orderId: string }
    }
  | {
      name: NotificationActionName.OPEN_JOB
      data: {
        city: string
        country: string
        subject: string
        jobTitle: string
        schoolName: string
        schoolSlug: string
        jobPostSlug: string
        pgctlLearner: string
        schoolBranch: string
      }
    }
  | {
      name: NotificationActionName.OPEN_ASSIGNMENTS_LIST
      data: {
        courseSlug: string
        qualificationSlug?: string
        certificationSlug?: string
      }
    }

export type Notification = {
  id: number
  title: string | null
  text: string
  // event: string | null // useless
  tags: string[]
  dateOpened: string
  dateRead: string
  dateCreated: string
  action: NotificationAction | null | Record<string, never>
}

export type NotificationResponse = {
  notifications: Notification[]
  unreadCount: number
}

export type NotificationsOverview = Record<string, number>

import { Typography } from "@suraasa/placebo-ui"
import MainImage from "assets/Onboarding/profile/main.webp"
import { useFeatureToggle } from "global/FeatureToggleProvider"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import Joyride, { Step } from "react-joyride"
import {
  BROWSER_STORAGE_KEYS,
  IS_DEV_ENVIRONMENT,
  IS_LOCALHOST,
} from "utils/constants"
import { getAuthInfo, sleep } from "utils/helpers"
import { preloadImages } from "utils/preloadImages"
import { trackingService } from "utils/tracking"
import { UserPreferenceManager } from "utils/userPreferenceManager"

import ProfileContext from "../context"
import BoostProfileDialog, {
  BoostProfileDialogEnum,
} from "./BoostProfileDialog/index.tsx"
import { PROFILE_ONBOARDING_TARGETS } from "./constants"
import GetStarted from "./GetStarted"
import CustomTooltip from "./OnboardingStep"

const { profileTourCompleted } = BROWSER_STORAGE_KEYS

const ProfileOnboarding = () => {
  const { academics, profile } = useContext(ProfileContext)

  const { qualifications } = academics

  const [boostProfileDialog, setBoostProfileDialog] = useState(false)

  const isCompleted = UserPreferenceManager.get(profileTourCompleted) === "true"

  const [step, setStep] = useState<"intro" | "joyride" | null>(null)

  const profileStrengthActions = useMemo(
    () =>
      [
        {
          title: "Add Qualifications",
          dialogKey: BoostProfileDialogEnum.QUALIFICATION,
          isDone: qualifications.data.length > 0,
        },
        {
          title: "Add Resume",
          dialogKey: BoostProfileDialogEnum.RESUME,
          isDone: Boolean(profile.resume),
        },
        {
          title: "Add Profile Picture",
          dialogKey: BoostProfileDialogEnum.PICTURE,
          isDone: Boolean(profile.picture),
        },
      ].filter(item => !item.isDone),
    [profile.picture, profile.resume, qualifications.data.length]
  )

  useEffect(() => {
    const auth = getAuthInfo()
    if (!auth || auth.shadow) return

    if (isCompleted) {
      setStep(null)
      return
    }

    ;(async () => {
      await preloadImages([MainImage])
      // Start the onboarding after a delay
      await sleep(1500)
      setStep("intro")
    })()
  }, [isCompleted])

  const onComplete = useCallback(() => {
    setStep(null)
    UserPreferenceManager.set(profileTourCompleted, "true")

    trackingService.trackEvent("profile_tour_completed")

    if (profileStrengthActions.length > 0) {
      setBoostProfileDialog(true)
    }
  }, [profileStrengthActions])

  const handleJoyrideCallback = useCallback(
    data => {
      const { status, lifecycle } = data

      const tourCompletedStatuses = ["finished"]
      if (tourCompletedStatuses.includes(status) && lifecycle === "complete") {
        onComplete()
      }
    },
    [onComplete]
  )

  const profileStrengthIsEnabled = useFeatureToggle().profileStrength.isEnabled

  const baseSteps: Step[] = [
    {
      target: `.${PROFILE_ONBOARDING_TARGETS.videoProfile}`,
      content: (
        <div>
          <Typography variant="title3" className="mb-1 text-start">
            Add Your Video Portfolio
          </Typography>
          <Typography
            variant="smallBody"
            className="mb-3 text-start text-onSurface-600"
          >
            It brings out your personality and helps people connect with you
            better. Schools are 79% more likely to shortlist teachers with video
            portfolios!
          </Typography>
        </div>
      ),
      disableBeacon: true,
      placement: "top",
    },
    {
      target: `.${PROFILE_ONBOARDING_TARGETS.resume}`,
      content: (
        <div>
          <Typography variant="title3" className="mb-1 text-start">
            Upload Your Resume
          </Typography>
          <Typography
            variant="smallBody"
            className="mb-3 text-start text-onSurface-600"
          >
            It will be visible to the school when they review your profile to
            shortlist you. We can also fill your profile for you based on your
            resume.
          </Typography>
        </div>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: `.${PROFILE_ONBOARDING_TARGETS.academics}`,
      content: (
        <div>
          <Typography variant="title3" className="mb-1 text-start">
            Add Your Educational Background
          </Typography>
          <Typography
            variant="smallBody"
            className="mb-3 text-start text-onSurface-600"
          >
            Add your qualifications and certifications to showcase your
            specialization and background.
          </Typography>
        </div>
      ),
      disableBeacon: true,
      placement: "top",
    },
  ]

  let steps = baseSteps
  if (profileStrengthIsEnabled) {
    steps = [
      ...steps,
      {
        target: `.${PROFILE_ONBOARDING_TARGETS.profileStrength}`,
        content: (
          <div>
            <Typography variant="title3" className="mb-1 text-start">
              Build Your Profile Strength
            </Typography>
            <Typography
              variant="smallBody"
              className="mb-3 text-start text-onSurface-600"
            >
              Strong Profile Strength showcases how complete, updated, and
              active your Suraasa profile is.
            </Typography>
          </div>
        ),
        disableBeacon: true,
        placement: "bottom",
      },
    ]
  }

  return (
    <>
      <div>
        <GetStarted
          open={step === "intro"}
          handleContinue={() => {
            trackingService.trackEvent("profile_tour_started")
            setStep("joyride")
          }}
          handleClose={
            IS_DEV_ENVIRONMENT || IS_LOCALHOST ? () => setStep(null) : undefined
          }
        />
        <Joyride
          steps={steps}
          run={step === "joyride"}
          tooltipComponent={CustomTooltip}
          continuous
          disableOverlayClose
          scrollOffset={200}
          disableCloseOnEsc
          hideBackButton
          hideCloseButton
          callback={handleJoyrideCallback}
        />
      </div>
      <BoostProfileDialog
        onClose={() => {
          setBoostProfileDialog(false)
        }}
        open={boostProfileDialog}
        dialogBtns={profileStrengthActions}
      />
    </>
  )
}

export default ProfileOnboarding

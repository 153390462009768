import { CircularProgress, Tag, Typography } from "@suraasa/placebo-ui"
import { Attempt, AttemptStatus } from "api/resources/assessments/types"
import { format } from "date-fns"
import {
  reloadOnAssessmentClose,
  startAssessment,
} from "features/Assessments/helpers"
import { NavArrowRight } from "iconoir-react"
import { useNavigate } from "react-router-dom"
import routes from "routes"

import { getTagStyle } from "../helpers"

const timeFormat = "d MMM yy, h:mm a"

const AttemptCard = ({
  attemptNumber,
  attempt,
}: {
  attemptNumber: number
  attempt: Attempt
}) => {
  const {
    status,
    assessmentSchedule,
    result,
    assessment,
    uuid: attemptId,
  } = attempt
  const navigate = useNavigate()

  if (!assessmentSchedule) return <CircularProgress />

  const tagStyle = getTagStyle({
    status,
    result,

    endTime: assessmentSchedule.endTime ?? undefined,
  })

  const isMissed =
    status === AttemptStatus.NOT_STARTED &&
    new Date() > new Date(assessmentSchedule.endTime ?? "")

  const canAttempt =
    !isMissed &&
    (status === AttemptStatus.IN_PROGRESS ||
      status === AttemptStatus.NOT_STARTED) &&
    result === null

  const handleClick = () => {
    if (canAttempt) {
      startAssessment({
        assessmentId: assessment.uuid,
        onClose: reloadOnAssessmentClose,
      })
      return
    }
    navigate(routes.assessmentResult.replace(":attemptId", attemptId))
  }

  return (
    <button
      className="mb-1 rounded-xl border border-surface-200 bg-surface-500 p-2"
      onClick={handleClick}
    >
      <div className="flex items-center justify-between gap-1">
        <div>
          <Typography variant="strong" className="mb-1 text-start">
            Attempt {attemptNumber}
          </Typography>
          <div className="flex flex-wrap items-center gap-1">
            {tagStyle && <Tag color={tagStyle?.color}>{tagStyle?.label}</Tag>}
            <Tag color="onSurface" className="w-fit text-start">
              {[assessmentSchedule.startTime, assessmentSchedule.endTime]
                .filter(Boolean)
                .map(time => format(new Date(time!), timeFormat))
                .join(" - ")}
            </Tag>
          </div>
        </div>
        <NavArrowRight className="shrink-0" />
      </div>
    </button>
  )
}

export default AttemptCard

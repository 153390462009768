import { Button, Typography } from "@suraasa/placebo-ui"
import { LearningItem } from "api/resources/learningItems/types"
import clsx from "clsx"
import CheckRestrictions, {
  getRestriction,
} from "features/AccessManagement/CheckRestrictions"
import { Check, Lock } from "iconoir-react"
import React from "react"
import { Link } from "react-router-dom"
import routes from "routes"
import { humaniseDuration } from "utils/helpers"

type Props = {
  data: LearningItem[]
  plannerId: number
  slug: string
}

const RecordedClassesList = (props: Props) => {
  if (props.data.length === 0) return null

  return (
    <div>
      <Typography variant="title3" className="mb-1">
        Recorded Classes
      </Typography>

      {props.data.map((item, index) => (
        <React.Fragment key={item.id}>
          <RecordedClassCard
            data={item}
            slug={props.slug}
            plannerId={props.plannerId}
          />
          {index !== props.data.length - 1 && <div className="h-2" />}
        </React.Fragment>
      ))}
    </div>
  )
}

const RecordedClassCard = ({
  data,
  plannerId,
  slug,
}: {
  plannerId: number
  slug: string
  data: LearningItem
}) => {
  if (data.learningContentType !== "pre_recorded_class")
    throw new Error("Invalid learningContentType")

  const getIcon = (
    item: LearningItem,
    className?: string
  ): JSX.Element | null => {
    if (getRestriction(item)) {
      return (
        <div
          className={clsx(
            "flex size-4 items-center justify-center rounded-full bg-secondary-300 p-0.75 text-onSurface-500",
            className
          )}
        >
          <Lock />
        </div>
      )
    }

    switch (item.status) {
      case "in_progress":
        return (
          <div
            className={clsx(
              "flex size-4 items-center justify-center rounded-full bg-primary-500",
              className
            )}
          >
            <div className={clsx("size-1.25 rounded-full bg-white")} />
          </div>
        )
      case "completed":
        return (
          <div
            className={clsx(
              "flex size-4 items-center justify-center rounded-full bg-success-500 p-0.75 text-white",
              className
            )}
          >
            <Check />
          </div>
        )

      case "unlocked":
      case "incomplete":
        return (
          <div
            className={clsx(
              "flex size-4 items-center justify-center rounded-full bg-warning-500",
              className
            )}
          >
            <div className="size-1.25 rounded-full bg-white" />
          </div>
        )
    }

    return (
      <div
        className={clsx(
          "flex size-4 items-center justify-center rounded-full bg-secondary-300 p-0.75 text-onSurface-500",
          className
        )}
      >
        <Lock />
      </div>
    )
  }

  const getAction = () => {
    return (
      <CheckRestrictions
        accessStatus={data.accessStatus}
        render={({ isRestricted }) => {
          const Component = isRestricted ? "div" : Link
          return (
            <Component
              to={`${routes.learningModule.replace(
                ":slug",
                slug as string
              )}?plannerId=${plannerId}&itemId=${data.id}`}
            >
              <Button disabled={isRestricted} variant="text">
                Watch Recording
              </Button>
            </Component>
          )
        }}
      />
    )
  }

  return (
    <div className="flex flex-col items-center rounded-2xl border border-surface-200 bg-white p-2 sm:flex-row sm:justify-between">
      <div className="flex flex-col sm:flex-row">
        {getIcon(data, "me-0 sm:me-2 mb-2 sm:mb-0 shrink-0")}

        <div>
          <Typography variant="title3" className="me-1 text-onSurface-800">
            {data.title}
          </Typography>

          <Typography variant="smallBody" className="text-muted">
            {humaniseDuration(data.duration)}
          </Typography>
        </div>
      </div>
      <div className="mt-3 sm:mt-0">{getAction()}</div>
    </div>
  )
}

export default RecordedClassesList

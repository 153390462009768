import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Email } from "api/resources/settings/types"
import { APIError } from "api/utils"
import { useState } from "react"

const DeleteEmailConfirmation = ({
  email,
  onDelete,
  open,
  onClose,
}: {
  email: Email | null
  onDelete: () => void
  open: boolean
  onClose: () => void
}) => {
  const [loading, setLoading] = useState(false)

  const handleDelete = async () => {
    setLoading(true)
    if (email) {
      try {
        await api.settings.emails.delete({ urlParams: { id: email.id } })
        toast.success("Email deleted successfully.")
        onDelete()
        onClose()
      } catch (err) {
        if (err instanceof APIError) {
          onClose()
          toast.error("Something went wrong")
        }
      }
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Remove Email</DialogTitle>
        </DialogHeader>
        <DialogBody className="p-2">
          <Typography variant="smallBody">
            Are you sure you want to remove <b>{email?.email}</b>?
          </Typography>
        </DialogBody>

        <DialogFooter>
          <Button
            variant="outlined"
            size="sm"
            className="border-muted text-muted"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            color="critical"
            onClick={handleDelete}
            loading={loading}
          >
            Remove
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
export default DeleteEmailConfirmation

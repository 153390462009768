import { Container, theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import {
  AccessStatus,
  LearningItemDetails,
} from "api/resources/learningItems/types"
import Monitor from "assets/Fallback/monitor.svg"
import Fallback from "components/Fallback"
import FullPageLoading from "components/FullPageLoading"
import { Outlet, useOutletContext, useParams } from "react-router-dom"
import { useEnrollments } from "utils/hooks/useEnrollments"

import LearningItemHeader from "../Header"
import LearningItemNav from "../Nav"
// import LearningPausedBanner from "./LearningPausedBanner"

const voidTab = { name: "", link: "" }
type ContextType = {
  course: LearningItemDetails
  showLearningPausedBanner: (state: boolean) => void
  accessStatus: AccessStatus
}

const ITEM_TYPE = "course"

const Course = () => {
  const { slug } = useParams() as {
    slug: string
    parentSlug?: string
  }

  const {
    pgctl: { isNewDeliveryMode },
  } = useEnrollments()

  const mdUp = useMediaQuery(theme.breakpoints.up("md"))

  const { data, isLoading, isError } = useQuery({
    queryFn: () =>
      api.learningItems.getLearningItemDetails({
        urlParams: {
          itemType: ITEM_TYPE,
          itemSlug: slug,
        },
      }),
    queryKey: queries.learningItems.details(ITEM_TYPE, slug).queryKey,
  })

  const planner = useQuery({
    enabled: Boolean(data),
    queryFn: () =>
      api.learningItems.plannerAccess({
        urlParams: {
          itemType: ITEM_TYPE,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          itemId: data!.id,
        },
      }),
  })

  if (isLoading || planner.isLoading) return <FullPageLoading />

  if (isError || planner.isError)
    return (
      <Fallback
        data={{
          title: "Something went wrong",
          description: "Please contact care@suraasa.com",
          image: Monitor,
        }}
        className="pt-4"
      />
    )

  const getClassesTab = () => {
    if (isNewDeliveryMode) {
      return voidTab
    }
    return { name: "Classes", link: "classes" }
  }
  const getAssessmentsTab = () => {
    if (isNewDeliveryMode) {
      return voidTab
    }
    return { name: "Assessments", link: "assessments" }
  }

  const tabs = [
    { name: "Learning", link: "overview" },
    getClassesTab(),
    { name: "Skill Evidences (Assignments)", link: "assignments" },
    getAssessmentsTab(),
    { name: "Resources", link: "resources" },
  ].filter(x => x.link)

  return (
    <>
      {data.type === "course" && (
        <LearningItemHeader
          itemName={data.name}
          image={data.image}
          itemSlug={slug}
          data={tabs}
          className="mb-3"
          showDiscussionsButton
        />
      )}
      {/* Bottom padding for each page so content doesn't get cropped */}
      <Container className="pb-10">
        <div className="grid grid-cols-12">
          {mdUp && (
            <div className="col-span-3 me-3 flex flex-col">
              <LearningItemNav data={tabs} />
            </div>
          )}
          <div className="col-span-12 md:col-span-9">
            <Outlet
              context={{
                course: data,
                accessStatus: planner.data.accessStatus,
              }}
            />
          </div>
        </div>
      </Container>
    </>
  )
}

export default Course

export function useCourseDetails() {
  return useOutletContext<ContextType>()
}

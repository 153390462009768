import { Button } from "@suraasa/placebo-ui-legacy"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { APIError } from "api/utils"
import { Link } from "react-router-dom"
import routes from "routes"
import { handleErrors, saveBlobAsFile } from "utils/helpers"
import { useEnrollments } from "utils/hooks/useEnrollments"

const CertificateActionButtons = ({
  slug,
  learningItemType,
}: {
  slug: string
  learningItemType: string
}) => {
  const { enrollments } = useEnrollments()
  const currentCourse = enrollments?.find(item => item.slug === slug)

  const { mutate: downloadCertificate, isLoading } = useMutation({
    mutationFn: () =>
      api.profile.credentials.download({
        urlParams: {
          itemType: learningItemType,
          itemSlug: slug,
        },
      }),
    onSuccess: res => {
      if (res.__data) {
        saveBlobAsFile({
          data: res.__data?.data,
          type: "application/zip",
          name: `Suraasa ${currentCourse?.name} certificates.zip`,
        })
      }
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  return (
    <div className="flex flex-wrap justify-between gap-1">
      <Button
        size="sm"
        variant="outlined"
        component={Link}
        to={routes.certificates}
      >
        View All Certificates
      </Button>
      <Button
        loading={isLoading}
        size="sm"
        onClick={() => downloadCertificate()}
      >
        Download All
      </Button>
    </div>
  )
}

export default CertificateActionButtons

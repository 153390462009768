import { useMediaQuery } from "@suraasa/placebo-ui-legacy"
import AISparkle from "assets/icons/system/AISparkle"
import Bell from "assets/icons/system/Bell"
import BellRing from "assets/icons/system/BellRing"
import BookClosed from "assets/icons/system/BookClosed"
import BookOpen from "assets/icons/system/BookOpen"
import HomeLine from "assets/icons/system/HomeLine"
import HomeSmile from "assets/icons/system/HomeSmile"
import JobsSearch from "assets/icons/system/JobsSearch"
import ITOLogoGrey from "assets/logos/ito-mini-grey.svg"
import clsx from "clsx"
import Link, { NavBackground } from "components/Navigation/Link"
import { HOME_ONBOARDING_TARGETS } from "features/Home/HomeOnboarding/constants"
import { useNotifications } from "features/Notifications/hooks/useNotifications"
import { context } from "global/Context/context"
import { Suitcase } from "iconoir-react"
import { useContext, useRef } from "react"
import routes from "routes"
import { getPlatformURL } from "utils/helpers"

import styles from "./BottomNavigation.module.css"

const BottomNavigation = () => {
  const is2XS = useMediaQuery("@media only screen and (max-width: 500px)")
  const { pageOverview } = useNotifications({
    useSearchParams: false,
    enableNotificationList: false,
  })
  const { data: notificationOverviewData } = pageOverview
  const hasNotification =
    notificationOverviewData && notificationOverviewData.all > 0

  const { itoParticipationDetails } = useContext(context)

  const navContainerRef = useRef<HTMLDivElement>(null)

  return (
    <nav
      id="bottomNavigation"
      className={clsx(
        "fixed bottom-0 z-navbar w-full bg-white py-1",
        styles.bottomNavigation,
        {
          "p-1": is2XS,
        }
      )}
    >
      <div
        ref={navContainerRef}
        className="relative flex w-full items-center justify-around"
      >
        <NavBackground containerRef={navContainerRef} />
        <Link
          href={routes.home}
          text="Home"
          icon={<HomeLine />}
          activeIcon={<HomeSmile />}
        />
        <Link
          href={routes.learning}
          text="Learning"
          icon={<BookClosed />}
          activeIcon={<BookOpen />}
        />

        <Link
          href={routes.aiTools.home}
          text="AI Tools"
          className={HOME_ONBOARDING_TARGETS.aiTools}
          icon={<AISparkle className="h-3" height="20" viewBox="0 0 20 20" />}
        />
        <Link
          href={getPlatformURL("jobs", "/")}
          text="Jobs"
          className={HOME_ONBOARDING_TARGETS.jobs}
          icon={<Suitcase />}
          activeIcon={<JobsSearch />}
        />

        {itoParticipationDetails.registrationStatus === "completed" && (
          <Link
            href={routes.ito}
            text="ITO"
            icon={
              <img
                src={ITOLogoGrey}
                height={22}
                width={22}
                alt="ito"
                className="mb-px"
              />
            }
            activeIcon={
              <img
                src="https://assets.suraasa.com/ito/logos/ito-original-mini.svg"
                height={22}
                width={22}
                alt="ito"
                className="mb-px"
              />
            }
          />
        )}
        <div className={clsx({ relative: hasNotification })}>
          <Link
            href={routes.notifications}
            text="Notifications"
            icon={<Bell />}
            activeIcon={<BellRing />}
          />
          {hasNotification && (
            <span
              className={clsx(
                "absolute -right-0.5 inline-flex  items-center justify-center rounded-full bg-critical-500 px-0.5 text-xs font-semibold text-common-white-500",
                { "-top-0.75": !is2XS, "top-0": is2XS }
              )}
            >
              {notificationOverviewData.all}
            </span>
          )}
        </div>
      </div>
    </nav>
  )
}

export default BottomNavigation

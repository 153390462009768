import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
  Divider,
  TextArea,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Reference } from "api/resources/references/types"
import { APIError } from "api/utils"
import ProfileContext from "features/Profile/context"
import { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import { trackingService } from "utils/tracking"

import { ConfirmationCard } from "./Utils"

type FormData = Pick<Reference, "message">

type Props = Pick<DialogProps, "open"> & {
  data: Reference | null
  handleClose: () => void
}

const Reminder = ({ data, open, handleClose }: Props & { data: Reference }) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<FormData>()

  const { workExperiences, references, academics } = useContext(ProfileContext)

  const [step, setStep] = useState<
    "send-reminder" | "confirmation" | undefined
  >("send-reminder")

  const onSubmit = handleSubmit(async formData => {
    clearErrors()

    const baseData = {
      message: formData.message?.trim() === "" ? null : formData.message,
    }
    try {
      await api.references.sendReminder({
        data: baseData,
        urlParams: { id: data.id },
      })
      trackingService.trackEvent("reference_request_reminder_sent")
      updateReferences()
    } catch (error) {
      if (error instanceof APIError) {
        toast.error(error.message)
        console.error(error)
      } else {
        toast.error("An unexpected error occurred")
        console.error("Unexpected error:", error)
      }
    }
  })

  const updateReferences = async () => {
    try {
      references.refetch()
      workExperiences.refetch()
      academics.qualifications.refetch()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Dialog open={open} onOpenChange={handleClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Send a Reminder</DialogTitle>
          </DialogHeader>
          <DialogBody>
            {step === "confirmation" && (
              <ConfirmationCard
                isReminder={true}
                referrerName={data.referrer.name}
              />
            )}
            {step === "send-reminder" && (
              <div className="m-1 flex flex-col">
                <Typography
                  color="onSurface.500"
                  className="mb-2.5"
                  variant="body"
                >
                  {`Prompt ${data.referrer.name} to complete your reference with a quick reminder message.`}
                </Typography>

                <div className="my-1 mb-2.5">
                  <Divider />
                </div>

                <Typography
                  color="onSurface.900"
                  className="mb-0.5"
                  variant="title4"
                >
                  {data.referrer.name}
                </Typography>

                <Typography
                  color="onSurface.500"
                  className="mb-0.25"
                  variant="body"
                >
                  {data.referrer.email}
                </Typography>

                <Typography
                  color="onSurface.500"
                  className="mb-5"
                  variant="body"
                >
                  +{data.referrer.phoneNumber.code}{" "}
                  {data.referrer.phoneNumber.number}
                </Typography>

                <form>
                  <TextArea
                    rows={4}
                    errors={errors.message?.message}
                    label="Include a personalised message (optional)"
                    placeholder="Type here..."
                    {...register("message", {
                      maxLength: {
                        value: 3000,
                        message: "Try to keep it short",
                      },
                    })}
                  />
                </form>
              </div>
            )}
          </DialogBody>
          {step === "confirmation" && (
            <DialogFooter>
              <Button onClick={handleClose}>Okay</Button>
            </DialogFooter>
          )}
          {step === "send-reminder" && (
            <DialogFooter>
              <Button
                loading={isSubmitting}
                onClick={() => {
                  onSubmit()
                  setStep("confirmation")
                }}
              >
                Send
              </Button>
            </DialogFooter>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Reminder

import { captureException } from "@sentry/react"
import api from "api"
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
import { calculateTokenExpiration } from "features/Authentication/processAuthCode"
import { AUTHORIZATION_HEADER_PREFIX } from "utils/config"
import {
  BROWSER_STORAGE_KEYS,
  DEFAULT_LANGUAGE_CODE,
  Platforms,
} from "utils/constants"
import { getAuthInfo, getUserLanguage, saveAuthInfo } from "utils/helpers"
import { getCurrentPlatform, getCurrentToken } from "utils/platform"

import { refreshToken } from "./refreshToken"
import { AuthData } from "./resources/users/types"
import { urls } from "./resources/users/urls"
import { paramsSerializer, transformRequest, transformResponse } from "./utils"

const axios = Axios.create({
  baseURL: import.meta.env.VITE_API_ENDPOINT,
  withCredentials: true,
  paramsSerializer,
  transformRequest: [
    transformRequest,
    ...(Array.isArray(Axios.defaults.transformRequest)
      ? Axios.defaults.transformRequest
      : []),
  ],
  transformResponse: [
    ...(Array.isArray(Axios.defaults.transformResponse)
      ? Axios.defaults.transformResponse
      : []),
    transformResponse,
  ],
  headers: {
    "Content-Type": "application/json",
    Language:
      localStorage.getItem(BROWSER_STORAGE_KEYS.language) ||
      DEFAULT_LANGUAGE_CODE,
  },
})

const authorizationIsEmpty = (request: AxiosRequestConfig) => {
  /**
   * If we send null in Authorization header from where the API is being called, axios transforms it into an empty object `{}`
   * That is why we are checking for object length and not null
   */
  return (
    request.headers["Authorization"] === null ||
    (typeof request.headers["Authorization"] === "object" &&
      Object.keys(request.headers["Authorization"]).length === 0)
  )
}

// axios.interceptors.request.use(async request => {
//   /**
//    * Empty "Authorization" header means that we don't want to send this Authorization in the request even if accessToken is present in the localStorage
//    * Example: in refresh token API call, we explicitly send Authorization: null from the API call.
//    */
//   if (authorizationIsEmpty(request)) {
//     delete request.headers["Authorization"]
//   } else {
//     const token = getCurrentToken()?.accessToken
//     const platform = getCurrentPlatform()

//     if (platform === Platforms.student) {
//       const auth = getAuthInfo()
//       if (!auth) return

//       const isExpired = Boolean(
//         auth.expiresAt && auth.generatedAt && auth.expiresAt < Date.now()
//       )

//       console.log({ isExpired, timeLeft: auth.expiresAt - Date.now() })

//       if (isExpired) {
//         const refreshed = await refreshToken()
//         if (refreshed) {
//           const newToken = getAuthInfo()?.accessToken
//           if (newToken) {
//             // Retry the request with new token
//             const tokenString = `${AUTHORIZATION_HEADER_PREFIX} ${newToken}`
//             request.headers["Authorization"] = tokenString
//           }
//         }
//       }
//     }

//     if (token) {
//       const tokenString = `${AUTHORIZATION_HEADER_PREFIX} ${token}`
//       request.headers["Authorization"] = tokenString
//     }
//   }

//   request.headers["Language"] = getUserLanguage()

//   return request
// })
let isRefreshing = false
let refreshSubscribers: ((token: string) => any)[] = []

const refreshAccessToken = async () => {
  console.log("Refreshing token")
  const existingAuth = getAuthInfo()

  if (!existingAuth) throw Error("Auth not present")
  try {
    const res = await api.users.refreshToken({
      data: {
        refreshToken: existingAuth.refreshToken,
      },
      headers: {
        Authorization: null,
      },
    })
    const newAuthInfo = calculateTokenExpiration({
      ...existingAuth,
      ...res,
    })

    saveAuthInfo(newAuthInfo)

    return newAuthInfo
  } catch (error) {
    console.error("Refresh token failed", error)
    throw error
  }
}

const isTokenExpired = (auth: AuthData) => {
  return Boolean(
    auth.expiresAt && auth.generatedAt && auth.expiresAt < Date.now()
  )
}

axios.interceptors.request.use(async request => {
  if (authorizationIsEmpty(request)) {
    delete request.headers["Authorization"]
    return request
  }

  let auth = getCurrentToken()

  // if (auth) console.log({ isTokenExpired: isTokenExpired(auth), isRefreshing })

  if (auth && isTokenExpired(auth)) {
    if (!isRefreshing) {
      isRefreshing = true
      try {
        auth = await refreshAccessToken()
        isRefreshing = false
        refreshSubscribers.forEach(callback => callback(auth!.accessToken))
        refreshSubscribers = []
      } catch (error) {
        isRefreshing = false
        return Promise.reject(error)
      }
    } else {
      // Wait for the refresh token process to complete
      return new Promise(resolve => {
        refreshSubscribers.push(newToken => {
          request.headers[
            "Authorization"
          ] = `${AUTHORIZATION_HEADER_PREFIX} ${newToken}`
          request.headers["Language"] = getUserLanguage()
          resolve(request)
        })
      })
    }
  }

  if (auth) {
    request.headers[
      "Authorization"
    ] = `${AUTHORIZATION_HEADER_PREFIX} ${auth.accessToken}`
  }
  request.headers["Language"] = getUserLanguage()

  return request
})

const interceptor = async (error: AxiosError) => {
  if (error.response && error.response.status === 429) {
    // console.log("Logging 429 on sentry", error?.request?.responseURL)
    try {
      captureException("API 429!", scope => {
        scope.setExtras({
          url: error?.request?.responseURL,
        })
        return scope
      })
    } catch (e) {
      // console.error("Safe to ignore")
    }
  }

  if (error.response && error.response.status === 401) {
    const refreshed = await refreshToken()
    if (refreshed)
      // Retrying the failed api call with new access token
      return axios(error.response.config)
  }

  return Promise.reject(error)
}

const successInterceptor = async (response: AxiosResponse<any>) => {
  // Verify access token API
  if (response.config.url === urls.verifyAccessToken()) {
    const platform = getCurrentPlatform()
    /**
     * We don't want token verification feature for other platforms
     */
    if (
      response.data.data.verified === false &&
      platform === Platforms.student
    ) {
      const refreshed = await refreshToken()
      if (refreshed) {
        const newToken = getAuthInfo()?.accessToken
        if (newToken) {
          // Retry the request with new token
          response.config.data = JSON.stringify({ token: newToken })
          return axios(response.config)
        }
      }
    }
  }

  return Promise.resolve(response)
}

axios.interceptors.response.use(successInterceptor, interceptor)

export default axios

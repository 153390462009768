import { Divider, Typography } from "@suraasa/placebo-ui"
import BackButton from "components/BackButton"
import { useNavigate } from "react-router-dom"
import routes from "routes"

type Props = {
  title: string
}

const MobileHeadBar = ({ title }: Props) => {
  const navigate = useNavigate()

  return (
    <div className="block sm:hidden">
      <BackButton goBack={() => navigate(routes.settings)} />
      <Typography variant="title2" className="mt-2 text-onSurface-800">
        {title}
      </Typography>
      <Divider weight="light" className="my-3 bg-onSurface-200" />
    </div>
  )
}

export default MobileHeadBar

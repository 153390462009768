import { Button, cn, Progress, Typography } from "@suraasa/placebo-ui"
import { PlannerListItem } from "api/resources/learningItems/types"
import { context } from "global/Context/context"
import { useContext } from "react"
import { Link } from "react-router-dom"
import routes from "routes"
import { useEnrollments } from "utils/hooks/useEnrollments"
import { PropsWithClassName } from "utils/types"

import { getPercentage } from "../CourseInfoAndProgress"

type Props = {
  href?: string
  item: Pick<PlannerListItem, "name" | "slug" | "learningItemType">
  progress: PlannerListItem["progress"] | null
  className?: string
  header?: JSX.Element
}

const ResumeCourseCard = ({
  item,
  className,
  header,
  href,
  progress,
}: PropsWithClassName<Props>) => {
  const { learningRestrictions } = useContext(context)

  const getLink = () => {
    if (href) return href

    const defaultLink = routes.parentItem
      .replace(":learningItemType", item.learningItemType)
      .replace(":slug", item.slug)

    if (!enrollments) return defaultLink
    const currentItem = enrollments.find(x => x.slug === item.slug)

    if (currentItem?.parents.length) {
      const parent = currentItem.parents[0]

      return routes.parentItemCourse
        .replace(":learningItemType", parent.type)
        .replace(":parentSlug", parent.slug)
        .replace(":slug", item.slug)
    }

    return defaultLink
  }

  const { enrollments } = useEnrollments()

  const parent = enrollments?.find(x => x.slug === item.slug)?.parents || []

  // If the parent was restricted or the individual course was restricted
  const isRestricted =
    learningRestrictions?.find(i => i.learningItem.slug === item.slug) ||
    learningRestrictions?.some(x => x.learningItem.slug === parent[0]?.slug)

  const completedItems = progress?.completedUserPlannerItems ?? 0
  const totalItems = progress?.totalUserPlannerItems ?? 0

  const percentage = getPercentage({
    total: totalItems,
    completed: completedItems,
  })

  if (isRestricted) return null

  return (
    <div>
      {header && header}
      <Link to={getLink()}>
        <div
          className={cn(
            "bg-white flex flex-col rounded-2xl border-2 p-3",
            className
          )}
        >
          <Typography
            className="mb-1 flex items-center gap-1 text-success-500"
            variant="preTitle"
          >
            <span className="inline-block size-[9.5px] rounded-full bg-success-500"></span>
            {completedItems === 0 ? "START LEARNING" : "CONTINUE LEARNING"}
          </Typography>
          <Typography className="mb-1 text-title3">{item.name}</Typography>
          <div className="flex items-center gap-1">
            <Progress
              value={percentage}
              indicatorClassName="bg-black rounded-e-full"
              className="max-w-19"
            />
            <Typography className="text-smallBody text-muted">
              {percentage}% Complete
            </Typography>
          </div>
          <Button
            color="black"
            className="mt-2.5 w-fit"
            size="sm"
            // disabled={disabled}
          >
            {completedItems === 0 ? "Start Learning" : "Continue Learning"}
          </Button>
        </div>
      </Link>
    </div>
  )
}

export default ResumeCourseCard

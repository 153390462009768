import { Typography } from "@suraasa/placebo-ui"
import { Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import {
  AccessStatus,
  RestrictionType,
} from "api/resources/learningItems/types"
import Fallback from "components/Fallback"
import ItemList from "components/ItemList"
import ItemRow from "components/ItemRow"
import ClassDetailsCard from "features/LearningItems/Course/Classes/LiveClassCard"
import VideoPlayerDialog from "features/LearningItems/Course/Classes/VideoPlayerDialog"
import { context } from "global/Context/context"
import { ArrowRight, Lock } from "iconoir-react"
import { useContext, useState } from "react"
import { useTheme } from "react-jss"
import { Link, useParams } from "react-router-dom"
import routes from "routes"
import { useEnrollments } from "utils/hooks/useEnrollments"

import { useQualificationDetails } from ".."

const QualificationClasses = () => {
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  const { slug, learningItemType } = useParams() as {
    slug: string
    learningItemType: string
  }
  const { learningRestrictions } = useContext(context)

  const {
    pgctl: { isNewDeliveryMode },
  } = useEnrollments()

  const showCourseClasses = !isNewDeliveryMode

  const restrictionStatus = learningRestrictions?.find(
    item => item.learningItem.slug === slug
  )?.restrictionType

  const [recordingURL, setRecordingURL] = useState<string | null>(null)

  const { qualification } = useQualificationDetails()

  const planner = useQuery({
    enabled: Boolean(qualification.id),
    queryFn: () =>
      api.learningItems.getPlannerList({
        params: {
          learningItemType: learningItemType,
          learningItemId: qualification.id,
        },
      }),
    queryKey: queries.learningItems.listPlanners(qualification.id).queryKey,
  })

  const extraClasses = useQuery({
    queryKey: queries.learningItems.listClasses(learningItemType, slug)
      .queryKey,
    queryFn: () =>
      api.learningItems.classes.list({
        urlParams: {
          learningItemType: learningItemType,
          learningItemId: qualification.id,
        },
        params: {
          sub_planner_items: false,
        },
      }),
  })

  const onViewRecording = (recordingUrl: string) => {
    setRecordingURL(recordingUrl)
  }
  const onClose = () => {
    setRecordingURL(null)
  }

  return (
    <>
      <div className="mb-4">
        <Typography variant="title3" className="mb-1">
          Extra Classes
        </Typography>

        <VideoPlayerDialog recordingURL={recordingURL} onClose={onClose} />

        {extraClasses.isSuccess && extraClasses.data.length > 0 && (
          <div className="flex flex-col gap-1.5">
            {extraClasses.data.map(item => (
              <ClassDetailsCard
                data={item}
                key={item.id}
                onViewRecording={onViewRecording}
              />
            ))}
          </div>
        )}

        <Fallback
          isEmpty={extraClasses.isSuccess && extraClasses.data.length === 0}
          emptyMessage="No extra classes available"
          hasError={extraClasses.isError}
          isLoading={extraClasses.isLoading}
        />
      </div>

      {showCourseClasses && (
        <div>
          <Typography variant="title3" className="mb-1">
            Classes
          </Typography>

          {planner.isSuccess && planner.data.length > 0 && (
            <ItemList className="!py-0">
              {planner.data.map(item => {
                const isLocked =
                  item.status === "locked" ||
                  item.accessStatus === AccessStatus.COMPLETELY_RESTRICTED ||
                  restrictionStatus === RestrictionType.REVOKED
                return (
                  <ItemRow
                    className="!p-2"
                    key={item.uuid}
                    component={isLocked ? "div" : Link}
                    to={
                      isLocked
                        ? undefined
                        : `${routes.parentItemCourse
                            .replace(":learningItemType", learningItemType)
                            .replace(":slug", item.slug)
                            .replace(":parentSlug", slug)}/classes`
                    }
                    infoSlot={<Typography>{item.name}</Typography>}
                    actionSlot={() => {
                      if (isXs) return null
                      if (isLocked) return <Lock />
                      return <ArrowRight fontSize="0.75rem" />
                    }}
                  />
                )
              })}
            </ItemList>
          )}

          <Fallback
            isEmpty={planner.isSuccess && planner.data.length === 0}
            emptyMessage="No classes available"
            hasError={planner.isError}
            isLoading={planner.isLoading}
          />
        </div>
      )}
    </>
  )
}

export default QualificationClasses
